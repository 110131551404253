import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { Lang } from "hooks/useLang";

const languagesI18NCodes = {
  en: "en",
  ar: "ar",
};

const lang = localStorage.getItem("lang") || '{ "code": "en" }';
const jsonLang: Lang = JSON.parse(lang);
const fallbackLng = languagesI18NCodes[jsonLang.code];

i18n

  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng,
    debug: true,
    returnEmptyString: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      useSuspense: false,
    },
    resources: {
      en: {
        common: require("locales/en/common.json"),
        login: require("locales/en/login.json"),
        rides: require("locales/en/rides.json"),
        captains: require("locales/en/captains.json"),
        vehicles: require("locales/en/vehicles.json"),
      },
      ar: {
        common: require("locales/ar/common.json"),
        login: require("locales/ar/login.json"),
        rides: require("locales/ar/rides.json"),
        captains: require("locales/ar/captains.json"),
        vehicles: require("locales/ar/vehicles.json"),
      },
    },
  });

export default i18n;
