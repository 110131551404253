import { useEffect } from "react";
import { useRefreshToken } from "resources/auth/useRefreshToken";

import { useAuthContext } from "contexts/authContext";

const fiveMinutes = 5 * 60 * 1000;
let refreshTokenTimeoutId: NodeJS.Timeout;

export default function useSubscribeRefreshToken() {
  const { mutate: refreshTokenAuthentication } = useRefreshToken();
  const { accessToken, refreshToken, expiresIn, logInTimeStamp } =
    useAuthContext();
  const now = Date.now();
  let millisecondsToExpiry = logInTimeStamp + expiresIn - now - fiveMinutes;
  if (millisecondsToExpiry < 0) millisecondsToExpiry = 0;

  useEffect(() => {
    clearTimeout(refreshTokenTimeoutId);
    if (accessToken) {
      refreshTokenTimeoutId = setTimeout(
        () => refreshTokenAuthentication({ refresh_token: refreshToken }),
        millisecondsToExpiry
      );
    }

    return () => clearTimeout(refreshTokenTimeoutId);
  }, [accessToken]);
}
