/** @jsxImportSource theme-ui */
import { FC } from "react";

type InfoWrapperItemProps = {
  compact?: boolean;
};

export const InfoWrapper: FC = (props) => {
  return (
    <div
      {...props}
      sx={{
        border: "1px solid",
        borderColor: "border",
        borderRadius: 6,
      }}
    />
  );
};

export const InfoWrapperItem: FC<InfoWrapperItemProps> = ({
  compact = false,
  ...props
}) => {
  return (
    <div
      sx={{
        display: [null, null, null, "flex"],
        py: 20,
        px: "spacing-m",
        borderBottom: "1px solid",
        borderColor: "border",
        "&:last-of-type": {
          border: "none",
        },
        "> p": {
          mt: 0,
          mb: ["spacing-xs", null, null, 0],
          variant: "text.p-small",

          "&:first-of-type": {
            variant: "text.p-small-medium",
            width: [null, null, null, compact ? 124 : 190],
            marginInlineEnd: [null, null, null, compact ? 56 : "spacing-xl"],
            flexShrink: 0,
          },
        },
      }}
      {...props}
    />
  );
};
