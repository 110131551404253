/** @jsxImportSource theme-ui */

import { useState } from "react";
import { useParams } from "react-router-dom";

import { Button, IconButton } from "@swvl/button";
import Drawer from "@swvl/drawer";
import FileUpload, { IFileWithMeta, StatusValue } from "@swvl/file-upload";
import { CloseIcon } from "@swvl/icons";
import { ErrorMessage } from "@swvl/input";
import theme from "@swvl/theme";
import Tooltip from "@swvl/tooltip";
import { useAuthContext } from "contexts/authContext";
import CompanyLogoIcon from "shared/icons/CompanyLogoIcon";
import SvgImageUpload from "shared/icons/ImageUploadIcon";
import InstructionsIcons from "shared/icons/InstructionsIcon";
import { useTranslation } from "react-i18next";
import CircleUserIcon from "shared/icons/CircleUserIcon";
import { useManagerInfo } from "resources/auth/useManagerInfo";
import useSelectedVendorIndex from "hooks/useSelectedVendorIndex";
import { showAlert } from "@swvl/alert";
import UploadImageIcon from "shared/icons/UploadImageIcon";
import {
  DOCUMENT_STATUSES,
  useVerifiableDocuments,
} from "resources/common/useVerifiableDocument";
import { isEmpty } from "lodash";

export type MethodValue =
  | "delete"
  | "get"
  | "head"
  | "options"
  | "patch"
  | "post"
  | "put"
  | "DELETE"
  | "GET"
  | "HEAD"
  | "OPTIONS"
  | "PATCH"
  | "POST"
  | "PUT";

export interface IUploadParams {
  url: string;
  method?: MethodValue;
  body?: string | FormData | ArrayBuffer | Blob | File | URLSearchParams;
  fields?: { [name: string]: string | Blob };
  headers?: { [name: string]: string };
  meta?: { [name: string]: unknown };
}

export const UploadCaptainPhoto = ({
  picture,
  value,
  onSubmit,
}: {
  picture?: string;
  value?: string;
  testId?: string;
  onSubmit?: () => void;
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [showFileFormatError, setShowFileFormatError] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>(picture || "");

  const { id: captainId } = useParams();
  const { accessToken } = useAuthContext();

  const { t } = useTranslation("captains");

  const { data: documents, isLoading } = useVerifiableDocuments(
    captainId,
    "captain"
  );

  const photoId = documents && documents.photo_id;
  const drivingLicense = documents && documents.driving_license;
  const isPhotoIdUploaded = photoId && !isEmpty(photoId.documents);
  const isDrivingLicenseUploaded =
    drivingLicense && !isEmpty(drivingLicense.documents);

  const verifiedPhotoIdKey = isPhotoIdUploaded
    ? Object.keys(photoId.documents).find((key) => {
        const _doc = photoId?.documents[key];
        return _doc && _doc.status === DOCUMENT_STATUSES.VERIFIED;
      })
    : null;

  const verifiedDrivingLicenseKey = isDrivingLicenseUploaded
    ? Object.keys(drivingLicense.documents).find((key) => {
        const _doc = drivingLicense?.documents[key];
        return _doc && _doc.status === DOCUMENT_STATUSES.VERIFIED;
      })
    : null;

  const isPhotoIdVerifiedAndLocked =
    !!verifiedPhotoIdKey && !photoId?.isUploadable;
  const isDrivingLicenseVerifiedAndLocked =
    !!verifiedDrivingLicenseKey && !drivingLicense?.isUploadable;

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  const { data: managerInfo } = useManagerInfo(true);
  const [selectedVendorIndex] = useSelectedVendorIndex();
  const selectedVendor = managerInfo?.vendors[selectedVendorIndex];

  const getUploadParams = ({ file }: IFileWithMeta): IUploadParams => {
    const baseURL = window.__env__.API_BASE_URL;
    const body = new FormData();
    body.append("photo", file);
    return {
      method: "PATCH",
      url: `${baseURL}captain/${captainId}/picture?vendor_id=${selectedVendor?.id}&vendor_type=${selectedVendor?.type}`,
      body,
      headers: {
        Accept: "*/*",
        Authorization: `Bearer ${accessToken}`,
      },
    };
  };

  const handleChangeStatus = ({ file }: IFileWithMeta, status: StatusValue) => {
    setShowFileFormatError(false);

    if (status === "done") {
      closeDrawer();
      onSubmit?.();

      const imageUrl = URL.createObjectURL(file);
      setImageUrl(imageUrl);

      showAlert({
        message: "Captain photo uploaded successfully",
        variant: "success",
        mode: "light",
        autoClose: 5000,
      });
    } else if (status === "rejected_file_type") {
      setShowFileFormatError(true);
    }
  };

  return (
    <>
      <div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          position: "relative",
        }}
      >
        {!imageUrl ? (
          <CompanyLogoIcon
            id={"file-uploader"}
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setIsDrawerOpen(true);
            }}
          />
        ) : (
          <>
            <img
              src={imageUrl || value + "?" + new Date().toISOString()}
              alt="Company Logo"
              width={58}
              height={58}
              sx={{
                borderRadius: "50%",
                border: "1px solid #D9DBE9",
                objectFit: "cover",
              }}
            />
            {!isPhotoIdVerifiedAndLocked &&
              !isDrivingLicenseVerifiedAndLocked &&
              !isLoading && (
                <div
                  sx={{
                    position: "absolute",
                    bottom: -3,
                    right: -3,
                  }}
                >
                  <span
                    data-for="refreshTooltip"
                    data-tip={t("replace")}
                    data-iscapture="true"
                  >
                    <UploadImageIcon
                      sx={{ cursor: "pointer", marginTop: 6 }}
                      onClick={() => {
                        setIsDrawerOpen(true);
                      }}
                      data-for="refreshTooltip"
                      data-tip={t("replace")}
                      data-iscapture="true"
                    />
                  </span>
                  <Tooltip
                    id="refreshTooltip"
                    place="top"
                    type="dark"
                    effect="solid"
                  />
                </div>
              )}
          </>
        )}
      </div>
      <Drawer
        closeDrawer={closeDrawer}
        isOpen={isDrawerOpen}
        size={"large"}
        anchor={"right"}
      >
        <div sx={{ overflow: "auto", height: "100vh" }}>
          {/* Header */}
          <div
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              py: `${theme.space["spacing-m"]}`,
              paddingInlineStart: `${theme.space["spacing-s"]}`,
              paddingInlineEnd: `${theme.space["spacing-m"]}`,
              borderBottom: `1px solid ${theme.colors["background-neutral"]}`,
            }}
          >
            <div sx={{ margin: "auto 0" }}>
              <SvgImageUpload sx={{ marginInlineEnd: "spacing-s" }} />
              <span
                sx={{
                  variant: "text.h5",
                  color: `${theme.colors["content-primary"]}`,
                }}
              >
                {t("upload")} - {t("photo")}
              </span>
            </div>
            <IconButton icon={<CloseIcon />} onClick={closeDrawer} />
          </div>
          {/* Body */}
          <div
            sx={{
              marginTop: "spacing-m",
              marginBottom: "spacing-xxxl",
              paddingInlineStart: "spacing-s",
              paddingInlineEnd: "spacing-s",
            }}
          >
            <div
              sx={{
                '&  div[class$="UploadWrapper"]': {
                  paddingTop: "50px",
                  paddingBottom: "50px",
                },
              }}
            >
              <FileUpload
                getUploadParams={getUploadParams}
                maxSize={5}
                accept=".JPG, .PNG, .jpeg"
                formatWarningText="Supports JPG, PNG, JPEG"
                inputText={t("photo-input-text")}
                maxFileSizeText={t("photo-file-size-text")}
                mbSizeUnit="MB"
                progress={{ of: "of", kbSizeUnit: "KB" }}
                onChangeStatus={handleChangeStatus}
                fileUploadIcon={<CircleUserIcon />}
              />
            </div>
            {showFileFormatError && (
              <ErrorMessage>{t("file-format-error")}</ErrorMessage>
            )}
            <div
              sx={{
                marginTop: "spacing-m",
                padding: "spacing-s",
                backgroundColor: "#F4F7FF",
                borderRadius: 16,
              }}
            >
              <div sx={{ display: "flex", alignItems: "center" }}>
                <InstructionsIcons sx={{ marginInlineEnd: "spacing-s" }} />
                <span sx={{ variant: "text.p-medium-bold" }}>
                  {t("instructions")}
                </span>
              </div>
              <ul sx={{ marginTop: "spacing-s", listStyle: "inherit" }}>
                <li
                  sx={{
                    variant: "text.p-x-small",
                    padding: "0 !important",
                    display: "list-item !important",
                    marginBottom: "spacing-xxs",
                  }}
                >
                  {t("photo-instruction-1")}
                </li>
                <li
                  sx={{
                    variant: "text.p-x-small",
                    padding: "0 !important",
                    display: "list-item !important",
                    borderTop: "0 !important",
                    marginBottom: "spacing-xxs",
                  }}
                >
                  {t("photo-instruction-2")}
                </li>
                <li
                  sx={{
                    variant: "text.p-x-small",
                    padding: "0 !important",
                    display: "list-item !important",
                    borderTop: "0 !important",
                    marginBottom: "spacing-xxs",
                  }}
                >
                  {t("photo-instruction-3")}
                </li>
                <li
                  sx={{
                    variant: "text.p-x-small",
                    padding: "0 !important",
                    display: "list-item !important",
                    borderTop: "0 !important",
                    marginBottom: "spacing-xxs",
                  }}
                >
                  {t("photo-instruction-4")}
                </li>
                <li
                  sx={{
                    variant: "text.p-x-small",
                    padding: "0 !important",
                    display: "list-item !important",
                    borderTop: "0 !important",
                    marginBottom: "spacing-xxs",
                  }}
                >
                  {t("photo-instruction-5")}
                </li>
              </ul>
            </div>
          </div>

          {/* Footer */}
          <footer
            sx={{
              display: "block",
              position: "absolute",
              bottom: 0,
              width: "100%",
              background: "#fff",
            }}
          >
            <div
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                py: "spacing-s",
                px: "spacing-m",
                borderTop: "1px solid #ddd",
              }}
            >
              <Button
                type="button"
                variant="secondary"
                onClick={() => {
                  closeDrawer();
                }}
              >
                {t("close")}
              </Button>
            </div>
          </footer>
        </div>
      </Drawer>
    </>
  );
};
