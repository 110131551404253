/** @jsxImportSource theme-ui */

import useCaptainLocation from "hooks/useCaptainLocation";
import { useEffect, useState } from "react";
import Map, { Marker } from "@swvl/map";
import BusIcon from "assets/bus.svg";
import formatDistance from "date-fns/formatDistance";
import enLocale from "date-fns/locale/en-US";
import arLocale from "date-fns/locale/ar";
import useLang from "hooks/useLang";
import useBusMarkerRef from "hooks/useBusMarkerRef";
import { Spinner } from "theme-ui";
import { useTranslation } from "react-i18next";
import NoData from "components/NoData";
import emptyBoxLogo from "assets/location-empty-box.svg";

const dateFnsLocales = {
  en: enLocale,
  ar: arLocale,
};

const CaptainLocation = ({ captainId }: { captainId: string }) => {
  const { connecting, connected, location } = useCaptainLocation(captainId);
  const [lastSeenAt, setLastSeenAt] = useState<string>();

  const [lang] = useLang();
  const busMarkerRef = useBusMarkerRef();

  const { t } = useTranslation("captains");

  useEffect(() => {
    if (location?.date) {
      const lastSeenAt = formatDistance(new Date(), new Date(location.date), {
        addSuffix: true,
        locale: dateFnsLocales[lang.code],
      });
      setLastSeenAt(lastSeenAt);
    }
  }, [location?.date]);

  useEffect(() => {
    if (location?.bearing && busMarkerRef) {
      busMarkerRef.style.transform = `rotate(${bearing}deg)`;
    }
  }, [location?.bearing, busMarkerRef]);

  const { lat, lng, bearing } = location || {};

  return (
    <div>
      {connecting && (
        <div
          sx={{
            display: "flex",
            height: "500px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner color="secondary" />
        </div>
      )}
      {!connecting && lat && lng ? (
        <div
          sx={{
            position: "relative",
            overflow: "auto",
            flexGrow: "1",
            minHeight: 550,
          }}
        >
          <div
            sx={{
              display: "flex",
              justifyContent: "space-between",
              my: "spacing-s",
            }}
          >
            <p
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                gap: "spacing-xs",
                m: 0,
              }}
            >
              <span
                sx={{
                  variant: "text.p-medium-medium",
                  color: "content-secondary",
                }}
              >
                {t("location-information.live-location")}:
              </span>
              <span
                sx={{
                  variant: "text.p-small-medium",
                  color: "content-primary",
                }}
              >
                {connected
                  ? t("location-information.connected")
                  : t("location-information.dis-connected")}
              </span>
              <span
                sx={{
                  display: "inline-block",
                  height: 7,
                  width: 7,
                  backgroundColor: connected ? "success-dark" : "negative-dark",
                  borderRadius: "50%",
                }}
              />
            </p>
            {lastSeenAt && (
              <p
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  gap: "spacing-xs",
                  m: 0,
                }}
              >
                <span
                  sx={{
                    variant: "text.p-medium-medium",
                    color: "content-secondary",
                  }}
                >
                  {t("location-information.last-seen")}:
                </span>
                <span
                  sx={{
                    variant: "text.p-small-medium",
                    color: "black",
                  }}
                >
                  {lastSeenAt}
                </span>
              </p>
            )}
          </div>
          <Map
            googleMapsApiKey={window.__env__.GOOGLE_MAPS_API_KEY}
            routes={{}}
            mapContainerStyle={{
              flex: 1,
              height: "500px",
              borderRadius: 8,
            }}
            center={{ lat, lng }}
          >
            <Marker
              position={{ lat, lng }}
              icon={{
                url: BusIcon,
              }}
              animation={
                window.google ? window.google.maps.Animation.DROP : undefined
              }
            />
          </Map>
          {/* <div
                  sx={{
                    position: "absolute",
                    display: "flex",
                    gap: "spacing-s",
                    bottom: 48,
                    ...(lang.code === "ar" ? { left: 40 } : { right: 40 }),
                    px: "spacing-s",
                    py: "spacing-xs",
                    m: 0,
                    background: "white",
                    border: "1px solid",
                    borderColor: "gray-200",
                    boxShadow:
                      "0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05)",
                    borderRadius: 8,
                    zIndex: 2,
                  }}
                >
                  <p
                    sx={{
                      variant: "text.p-small",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span
                      sx={{
                        display: "inline-block",
                        height: "4px",
                        width: "16px",
                        backgroundColor: "#A6F4C5",
                        marginInlineEnd: 8,
                      }}
                    ></span>
                    <span>Before 5 minutes</span>
                  </p>
                  <p
                    sx={{
                      variant: "text.p-small",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span
                      sx={{
                        display: "inline-block",
                        height: "4px",
                        width: "16px",
                        backgroundColor: "#FEF0C7",
                        marginInlineEnd: 8,
                      }}
                    ></span>
                    <span>Before 30 minutes</span>
                  </p>
                  <p
                    sx={{
                      variant: "text.p-small",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span
                      sx={{
                        display: "inline-block",
                        height: "4px",
                        width: "16px",
                        backgroundColor: "#FECDCA",
                        marginInlineEnd: 8,
                      }}
                    ></span>
                    <span>Before 6 hours</span>
                  </p>
                </div> */}
        </div>
      ) : (
        !connecting && (
          <NoData
            title={t("location-information.no-data-title")}
            description={t("location-information.no-data-description")}
            image={emptyBoxLogo}
          />
        )
      )}
    </div>
  );
};

export default CaptainLocation;
