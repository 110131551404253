import { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { showAlert } from "@swvl/alert";

import { useAuthContext } from "contexts/authContext";

import apiInstance from "api";
import { LoginResponseTransform, LoginResponse } from "utils/types";

// Types
export interface RefreshTokenRequest {
  refresh_token: string;
}

// Transforms
const transformResponse = ({
  access_token: accessToken,
  refresh_token: refreshToken,
  token_type: tokenType,
  expires_in,
  message,
}: LoginResponse): LoginResponseTransform => {
  const expiresInMS = expires_in * 1000;

  return {
    accessToken,
    refreshToken,
    tokenType,
    expiresIn: expiresInMS,
    message,
    logInTimeStamp: Date.now(),
  };
};

// Resources
const refreshToken = async (data: RefreshTokenRequest) => {
  const response = await apiInstance.post<RefreshTokenRequest, LoginResponse>(
    "refresh_tokens",
    data
  );
  return transformResponse(response);
};

const useRefreshToken = () => {
  const { setAuthData, removeAuthData } = useAuthContext();
  const { t } = useTranslation("common");

  const mutation = useMutation(refreshToken, {
    mutationKey: "refresh-token",
    onSuccess: ({
      accessToken,
      refreshToken,
      tokenType,
      expiresIn,
      logInTimeStamp,
    }) => {
      setAuthData({
        accessToken,
        refreshToken,
        tokenType,
        expiresIn,
        logInTimeStamp,
      });
    },
    onError: (response: AxiosResponse) => {
      if (!response) return;

      removeAuthData();
      showAlert({
        message: t("refresh-token-failure-msg"),
        id: "refresh-token-failure-alert",
        variant: "error",
        mode: "light",
      });
    },
  });

  return mutation;
};

export { useRefreshToken };
