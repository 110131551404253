export const getStatusesData = (
  statusOptions: { label: string; value: string }[],
  status: string[]
) =>
  status?.reduce(
    (
      allStatuses: {
        selectedStatuses: Array<{ label: string; value: string }>;
        availableStatuses: Array<{ label: string; value: string }>;
      },
      status: string
    ) => {
      const statusIndex = allStatuses.availableStatuses.findIndex(
        (captainStatus) => captainStatus.value.includes(status)
      );
      if (statusIndex > -1) {
        const selectedStatus = allStatuses.availableStatuses.splice(
          statusIndex,
          1
        );
        return {
          selectedStatuses: allStatuses.selectedStatuses.concat(selectedStatus),
          availableStatuses: allStatuses.availableStatuses,
        };
      }
      return allStatuses;
    },
    {
      selectedStatuses: [],
      availableStatuses: [...statusOptions],
    }
  ).selectedStatuses;

export function maskString(inputString: string, substringLength: number) {
  let maskedLength = inputString.length - substringLength;

  maskedLength = maskedLength < 0 ? 1 : maskedLength;
  const maskedPart = "x".repeat(maskedLength);
  const visiblePart = inputString.slice(-substringLength);
  return maskedPart + visiblePart;
}
