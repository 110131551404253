/** @jsxImportSource theme-ui */
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import once from "lodash/once";
import GA from "react-ga";

import {
  useListVehicles,
  ListVehicleData,
} from "resources/vehicles-list/useListVehicle";
import { useQueryCaptain } from "resources/common/useQueryCaptain";
import { useQueryBus } from "resources/rides-list/useQueryBus";
import useFilters from "hooks/useFilters";
import { Bus, Captain, Option } from "utils/types";

import VehicleListing from "./VehicleListing";
import NotFound from "components/NotFound";
import Autocomplete from "components/AutocompleteWrapper";

import trackEvents from "constants/trackEvents";
import InputGroup from "components/InputGroup";
import { Select } from "@swvl/select";
import ToolbarButton from "shared/components/ToolbarButton";
import FilterIcon from "shared/icons/FilterIcon";
import Drawer from "@swvl/drawer";
import VehiclesListMoreFilters from "./VehiclesListMoreFilters";
import AppliedFilters from "components/AppliedFilters";

const events = trackEvents.getVehicleEvents();

const trackScreenVehiclesList = once((number_of_vehicles: number) => {
  GA.event({
    ...events.screenVehicleList,
    label: JSON.stringify({ number_of_vehicles }),
  });
});

const Vehicles = () => {
  const { t } = useTranslation("vehicles");
  const [isFiltersDrawerOpen, setFiltersDrawerOpen] = useState(false);
  const [vehicleDetailErrorStatus, setVehicleDetailErrorStatus] =
    useState<boolean>(false);
  const [initialCaptainValue, setInitialCaptainValue] = useState("");
  const [initialBusValue, setInitialBusValue] = useState("");

  const searchFiltersValue = [
    {
      label: t("captain"),
      value: "captain",
    },
    {
      label: t("vehicle"),
      value: "bus",
    },
  ];

  const busStatusOptions = [
    { value: undefined, label: t("bus-statuses.all") },
    { value: "active", label: t("bus-statuses.active") },
    {
      value: "inactive",
      label: t("bus-statuses.inactive"),
    },
  ];

  const [selectedSearchType, setSelectedSearchType] = useState<Option>(
    searchFiltersValue[0]
  );
  const navigate = useNavigate();
  const {
    mutate: queryCaptain,
    data: captainSuggestions = [],
    isLoading: isCaptainQueryLoading,
  } = useQueryCaptain();
  const {
    mutate: queryBus,
    data: busSuggestions,
    isLoading: isBusQueryLoading,
  } = useQueryBus();

  const [filters, dispatchFilters, filtersString] = useFilters<ListVehicleData>(
    {
      limit: 10,
      page: 1,
    }
  );
  const sanitizedFilters = { ...filters };

  if (sanitizedFilters.captain_id) {
    const [_id] = sanitizedFilters.captain_id.split(":");
    sanitizedFilters.captain_id = _id;
  }
  if (sanitizedFilters.bus_id) {
    const [_id] = sanitizedFilters.bus_id.split(":");
    sanitizedFilters.bus_id = _id;
  }

  const { data: vehicles, isLoading: isVehiclesListLoading } =
    useListVehicles(sanitizedFilters);

  useEffect(() => {
    if (filters.captain_id) {
      const [, captainName] = filters.captain_id.split(":");
      setInitialCaptainValue(captainName);
    }
  }, []);

  useEffect(() => {
    if (!isVehiclesListLoading && vehicles) {
      trackScreenVehiclesList(vehicles.hits.length);

      if (vehicles.hits.length === 0 && filters.page > 1) {
        dispatchFilters({ type: "UPDATE", filters: { page: 1 } });
      }
    }
  }, [isVehiclesListLoading, vehicles]);

  const getAppliedFilters = () => {
    return {
      ...(filters?.captain_id && {
        captain_id: {
          title: t("captain"),
          value: filters?.captain_id.split(":")[1],
        },
      }),
      ...(filters?.bus_id && {
        bus_id: {
          title: t("vehicle"),
          value: filters?.bus_id.split(":")[1],
        },
      }),
      ...(filters?.status && {
        status: {
          title: t("status"),
          value: filters?.status.split(":")[0],
        },
      }),
      ...(filters?.document_verification_status?.length && {
        document_verification_status: {
          title: t("document-status"),
          value: filters?.document_verification_status.map(
            (status) => status.split(":")[0]
          ),
        },
      }),
    };
  };

  const clearAppliedFilters = () => {
    dispatchFilters({
      type: "UPDATE",
      filters: {
        page: 1,
        limit: 100,
        status: undefined,
        captain_id: undefined,
        bus_id: undefined,
        document_verification_status: undefined,
      },
    });

    setInitialCaptainValue("");
    setInitialBusValue("");
  };

  const removeFilter = ({
    key,
    value,
  }: {
    key: string;
    value: string | string[];
  }) => {
    const _key = key as keyof ListVehicleData;
    if (["document_verification_status"].includes(_key)) {
      let filterValue = filters[_key]
        ? [...(filters[_key] as string[])]
        : undefined;
      if (!filterValue) return;
      filterValue = filterValue?.filter(
        (item) => !item.endsWith(value as string)
      );
      dispatchFilters({
        type: "UPDATE",
        filters: {
          ...filters,
          [key]: [...filterValue],
        },
      });
    } else if (
      _key === "status" ||
      _key === "captain_id" ||
      _key === "bus_id"
    ) {
      dispatchFilters({
        type: "UPDATE",
        filters: {
          ...filters,
          [key]: undefined,
        },
      });
    }
  };

  return (
    <div
      sx={{
        px: "spacing-m",
        pt: "spacing-m",
      }}
    >
      <Helmet>
        <title>
          {t("title")} | {t("dashboard-title", { ns: "common" })}
        </title>
      </Helmet>
      {vehicleDetailErrorStatus ? (
        <NotFound
          title={t("not-found-title", { ns: "common" })}
          description={t("not-found-description", { ns: "common" })}
          goBackCallback={() => {
            setVehicleDetailErrorStatus(false);
            navigate(`/fleet/vehicles?${filtersString}`, { replace: true });
          }}
        />
      ) : (
        <>
          <h4 sx={{ variant: "text.h4", m: 0 }}>{t("title")}</h4>
          <div
            sx={{
              display: "flex",
              justifyContent: "space-between",
              my: "spacing-m",
            }}
          >
            <div
              sx={{
                display: "flex",
                gap: "spacing-s",
                alignItems: "end",
              }}
            >
              <InputGroup sx={{ flex: 2, p: 0 }} label={t("search")}>
                <Select
                  id="search-control"
                  name="search-control"
                  options={searchFiltersValue}
                  variant="plain"
                  height="compact"
                  value={searchFiltersValue.find(
                    ({ value }) => selectedSearchType.value === value
                  )}
                  onChange={(value) => {
                    setSelectedSearchType(value as Option);
                    setInitialCaptainValue("");
                    setInitialBusValue("");
                  }}
                  isClearable={false}
                  defaultValue={selectedSearchType}
                />
                {selectedSearchType.value === "captain" && (
                  <Autocomplete
                    onFocus={() => GA.event(events.actionEnterSearch)}
                    onChangeQuery={(query: string) => {
                      GA.event({
                        ...events.actionSubmitSearch,
                        label: JSON.stringify({
                          search_key: query,
                          type: "vehicles",
                        }),
                      });
                      queryCaptain(query);
                    }}
                    suggestions={captainSuggestions}
                    isLoading={isCaptainQueryLoading}
                    initialValue={initialCaptainValue}
                    onOptionSelect={(captain) => {
                      const { name, _id } = captain as Captain;
                      dispatchFilters({
                        type: "UPDATE",
                        filters: {
                          captain_id: `${_id}:${name}`,
                          page: 1,
                        },
                      });

                      GA.event(events.actionFilterSearch);
                    }}
                    onClear={() => {
                      setInitialCaptainValue("");
                      GA.event(events.actionFilterSearch);
                    }}
                    placeholder={t("search-captain")}
                    variant="plain"
                    height="compact"
                    noResultsMessage={t("no-result-found", { ns: "common" })}
                  />
                )}
                {selectedSearchType.value === "bus" && (
                  <Autocomplete
                    onFocus={() => GA.event(events.actionEnterSearch)}
                    onChangeQuery={(query: string) => {
                      GA.event({
                        ...events.actionSubmitSearch,
                        label: JSON.stringify({
                          search_key: query,
                          type: "vehicles",
                        }),
                      });
                      queryBus({ q: query });
                    }}
                    suggestions={busSuggestions || []}
                    isLoading={isBusQueryLoading}
                    initialValue={initialBusValue}
                    onOptionSelect={(bus) => {
                      const { plates, _id } = bus as Bus;
                      dispatchFilters({
                        type: "UPDATE",
                        filters: {
                          bus_id: `${_id}:${plates}`,
                          page: 1,
                        },
                      });

                      GA.event(events.actionFilterSearch);
                    }}
                    onClear={() => {
                      setInitialBusValue("");

                      GA.event(events.actionFilterSearch);
                    }}
                    placeholder={t("search-vehicle")}
                    variant="plain"
                    height="compact"
                    noResultsMessage={t("no-result-found", { ns: "common" })}
                  />
                )}
              </InputGroup>
              <div sx={{ flex: 1 }}>
                <Select
                  required // to remove the (optional word in label)
                  label={t("bus-status-label")}
                  id="filters-bus-status"
                  name="filters-bus-status"
                  height="compact"
                  options={busStatusOptions}
                  value={busStatusOptions.find(
                    ({ value }) => filters.status === value
                  )}
                  isClearable={false}
                  onChange={(value) => {
                    const option = value as Option;
                    dispatchFilters({
                      type: "UPDATE",
                      filters: {
                        status: option.value as string,
                        page: 1,
                      },
                    });
                  }}
                  placeholder={t("captain-statuses.placeholder")}
                  sx={{ flex: 1, minWidth: "180px" }}
                />
              </div>
              <ToolbarButton
                title={t("more-filters")}
                icon={<FilterIcon />}
                onClick={() => {
                  setFiltersDrawerOpen(true);
                }}
              />
            </div>
          </div>

          {Object.keys(getAppliedFilters()).length ? (
            <AppliedFilters
              title={t("applied-filters")}
              filters={getAppliedFilters()}
              clearAll={clearAppliedFilters}
              removeFilter={removeFilter}
            />
          ) : null}

          <VehicleListing
            isLoading={isVehiclesListLoading}
            vehicles={vehicles}
            filters={filters}
            dispatchFilters={dispatchFilters}
          />
        </>
      )}

      <Drawer
        isOpen={isFiltersDrawerOpen}
        closeDrawer={() => {
          setFiltersDrawerOpen(false);
        }}
        size={"medium"}
      >
        <VehiclesListMoreFilters
          cancel={() => {
            setFiltersDrawerOpen(false);
          }}
          filters={filters}
          dispatchFilters={dispatchFilters}
          clearAllFilters={clearAppliedFilters}
        />
      </Drawer>
    </div>
  );
};

export default Vehicles;
