/** @jsxImportSource theme-ui */
import { useEffect } from "react";
import { AxiosResponse } from "axios";
import { useFormik, FormikProvider, Field } from "formik";
import { useTranslation } from "react-i18next";
import { string, object } from "yup";
import { Button } from "@swvl/button";
import Avatar from "@swvl/avatar";
import { WarningFilledIcon, CloseIcon } from "@swvl/icons";

import GA from "react-ga";

import FieldInputWrapper from "components/FieldInputWrapper";
import { useInviteCaptain } from "resources/rides-list/useInviteCaptain";
import useLang from "hooks/useLang";

import trackEvents from "constants/trackEvents";
import { noSpecialCharacter, numberString } from "constants/regexp";
import AvatarDefault from "assets/avatar-default.png";
import { ManagerVendorInfo } from "utils/types";
import { getCountryCallingCodeString } from "utils";

type InviteCaptainProps = {
  setInviteCaptainDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedVendor: ManagerVendorInfo;
};

const InviteCaptain = ({
  setInviteCaptainDrawerOpen,
  selectedVendor,
}: InviteCaptainProps) => {
  const { t } = useTranslation("captains");
  const {
    mutateAsync: inviteCaptain,
    isLoading: isInviteCaptainLoading,
    error,
  } = useInviteCaptain();
  const events = trackEvents.getCaptainEvents();

  const [lang] = useLang();
  const { path, message: errorMessage } = (error as AxiosResponse)?.data || {};
  const [errorField] = path || [];

  const inviteCaptainSchema = object({
    name: string()
      .matches(noSpecialCharacter, t("name-validation"))
      .required(t("name-required")),
    phone: string()
      .matches(numberString, t("phone-validation"))
      .required(t("phone-required")),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
    },
    validationSchema: inviteCaptainSchema,
    onSubmit: (values) => {
      inviteCaptain({
        ...values,
        language: lang.code,
      })
        .then(() => {
          setInviteCaptainDrawerOpen(false);
          GA.event(events.statusInviteSuccessfully);
        })
        .catch((response: AxiosResponse) => {
          if (!response) return;
          GA.event({
            ...events.statusInviteFailed,
            label: response.data.message,
          });
        });
    },
  });

  useEffect(() => {
    GA.event(events.screenInviteCaptain);
  }, []);

  useEffect(() => {
    if (errorField) {
      formik.setErrors({ [errorField]: errorMessage });
    }
  }, [errorField, errorMessage, formik]);

  return (
    <div sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <div
        sx={{
          bg: "secondary-light-95",
          p: "spacing-m",
        }}
      >
        <div
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "spacing-s",
          }}
        >
          <Avatar
            name="Captain Picture"
            src={AvatarDefault}
            sx={{ width: "96px", height: "96px" }}
          />
          <CloseIcon
            sx={{ cursor: "pointer" }}
            onClick={() => setInviteCaptainDrawerOpen(false)}
          />
        </div>
        <h5 sx={{ variant: "text.h5", margin: 0 }}>{t("invite-captain")}</h5>
      </div>
      <div sx={{ flexGrow: 1, overflow: "auto" }}>
        <FormikProvider value={formik}>
          <form
            noValidate
            onSubmit={formik.handleSubmit}
            sx={{
              mx: "spacing-m",
              mt: "spacing-m-l",
            }}
          >
            <div sx={{ mb: "spacing-m" }}>
              <Field
                name="name"
                label={t("captain-name")}
                component={FieldInputWrapper}
                autoFocus
                required
                onBlur={() => GA.event(events.actionEnterInviteeName)}
              />
            </div>

            <div sx={{ mb: "spacing-m" }}>
              <Field
                name="phone"
                label={t("captain-phone")}
                component={FieldInputWrapper}
                startEnhancer={`+${getCountryCallingCodeString(
                  selectedVendor.country_code
                )}`}
                onBlur={() => GA.event(events.actionEnterInviteeNumber)}
                required
              />
            </div>
          </form>
        </FormikProvider>
      </div>

      {!errorField && errorMessage && (
        <div
          sx={{
            mx: "spacing-m",
            mb: "spacing-m",
            p: "spacing-s",
            bg: "negative-light",
            display: "flex",
            alignItems: "center",
            borderRadius: "6px",
          }}
        >
          <WarningFilledIcon />
          <span
            sx={{
              marginInlineStart: "spacing-s",
              variant: "text.p-small",
              color: "content-primary",
            }}
          >
            {errorMessage}
          </span>
        </div>
      )}

      <div
        sx={{
          px: "spacing-m",
          py: "spacing-s",
          borderTop: "1px solid",
          borderColor: "border",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          disabled={isInviteCaptainLoading}
          sx={{
            variant: "text.p-medium-bold",
          }}
          onClick={() => {
            formik.submitForm();
            GA.event(events.actionInviteCaptain);
          }}
        >
          {t("invite-captain-button")}
        </Button>
      </div>
    </div>
  );
};

export default InviteCaptain;
