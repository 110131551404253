import { useMutation } from "react-query";
import apiInstance from "api";

import { AutoCompleteOption } from "utils/types";
import { Captain } from "utils/types";

export type Option = {
  label: string;
  value: unknown;
};

interface CaptainQueryResponse {
  hits: Captain[];
}

const transformResponse = ({
  hits,
}: CaptainQueryResponse): AutoCompleteOption[] => {
  return hits.map((option) => ({ label: option.name, value: option }));
};

export const queryCaptain = async (q: string) => {
  const response = await apiInstance.get<unknown, CaptainQueryResponse>(
    "captain/autocomplete",
    { params: { q } }
  );

  return transformResponse(response);
};

const useQueryCaptain = () => {
  return useMutation(["captain-autocomplete"], queryCaptain);
};

export { useQueryCaptain };
