/** @jsxImportSource theme-ui */
import StarFilledIcon from "shared/icons/StarFilledIcon";
import StarHalfIcon from "shared/icons/StarHalfIcon";
import StarIcon from "shared/icons/StarIcon";

const StarRating = ({ rating }: { rating: number }) => {
  const roundedRating = Math.floor(rating * 2) / 2;
  const fullStars = Math.floor(roundedRating);
  const halfStar = roundedRating % 1 !== 0;
  const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

  return (
    <div
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "2px",
      }}
    >
      {new Array(fullStars).fill("").map((_, index) => (
        <StarFilledIcon key={index + "filled"} width={16} height={16} />
      ))}
      {halfStar && <StarHalfIcon width={16} height={16} />}
      {new Array(emptyStars).fill("").map((_, index) => (
        <StarIcon
          fill={rating === 0 ? "#ccc" : "#2356F9"}
          key={index + "empty"}
          width={16}
          height={16}
        />
      ))}
    </div>
  );
};

export default StarRating;
