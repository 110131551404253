/** @jsxImportSource theme-ui */
import { useEffect } from "react";
import { add, sub } from "date-fns";
import { AxiosResponse } from "axios";
import { useTimer } from "react-timer-hook";
import { Spinner } from "theme-ui";
import { useTranslation } from "react-i18next";
import { Button } from "@swvl/button";
import GA from "react-ga";
import Popup, { PopupContent, PopupFooter, PopupHeader } from "@swvl/popup";

import { useResendVerificationEmail } from "resources/auth/useResendVerificationEmail";
import useSelectedVendor from "hooks/useSelectedVendor";

import { ManagerInfoResponse } from "utils/types";
import { ReactComponent as EnvelopeLogo } from "assets/envelope.svg";
import trackEvents from "constants/trackEvents";

const events = trackEvents.getCommonEvents();
const restartTimer = (
  verifyEmailAPIStatus: string,
  managerInfo: ManagerInfoResponse,
  restart: (newExpiryTimestamp: Date, autoStart?: boolean | undefined) => void
) => {
  if (verifyEmailAPIStatus === "error") {
    restart(
      sub(new Date(), {
        seconds: (managerInfo.email_verification_threshold || 5) * 60,
      })
    );
  } else {
    restart(
      add(new Date(managerInfo.email_verification_sent_at), {
        seconds: (managerInfo.email_verification_threshold || 5) * 60,
      })
    );
  }
};

const VerificationPopup = ({
  managerInfo,
  isVerifyingToken,
  verifyEmailAPIStatus,
  showResendButton,
  verifyEmailError,
}: {
  managerInfo: ManagerInfoResponse;
  verifyEmailAPIStatus: string;
  isVerifyingToken: boolean;
  showResendButton: boolean;
  verifyEmailError: AxiosResponse;
}) => {
  const { t } = useTranslation("common");
  const { mutateAsync: resendVerificationEmail } = useResendVerificationEmail();
  const selectedVendor = useSelectedVendor();

  const { seconds, minutes, restart, isRunning } = useTimer({
    expiryTimestamp: add(new Date(managerInfo.email_verification_sent_at), {
      seconds: (managerInfo.email_verification_threshold || 5) * 60,
    }),
  });

  useEffect(() => {
    //check if we are viewing the verification popup
    if (
      verifyEmailAPIStatus === "idle" &&
      !managerInfo.user.has_verified_email
    ) {
      GA.event({
        ...events.screenVerificationPopup,
        label: JSON.stringify({
          id: selectedVendor.id,
          name: selectedVendor.name,
          country: selectedVendor.country_name,
          city: selectedVendor.city_name,
          timestamp: new Date(),
        }),
      });
    }
  }, []);

  useEffect(() => {
    restartTimer(verifyEmailAPIStatus, managerInfo, restart);
  }, [verifyEmailAPIStatus]);

  return (
    <Popup
      isOpen={!managerInfo.user.has_verified_email}
      closePopup={() => null} // @TODO: This is required by popup package. Need to make it optional in swvl UI.
    >
      <PopupHeader withClose={false} />
      <PopupContent
        sx={{
          py: 0,
          textAlign: "center",
        }}
      >
        {isVerifyingToken && (
          <div
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Spinner color="secondary" />
            <h5 sx={{ variant: "text.h5" }}>{t("verifying-token")}</h5>
          </div>
        )}
        {!isVerifyingToken && (
          <>
            {verifyEmailAPIStatus === "success" ? (
              <h5 sx={{ variant: "text.h5" }}>
                {t("verified-and-redirection")}
              </h5>
            ) : (
              <>
                <EnvelopeLogo />
                <h5
                  sx={{
                    variant: "text.h5",
                    pt: "spacing-m",
                    pb: "spacing-xs",
                    my: 0,
                  }}
                >
                  {t("verify-your-email-heading")}
                </h5>
                <p
                  sx={{
                    mt: 0,
                    variant: "text.p-medium",
                    mb: isRunning ? 0 : "spacing-m-l",
                  }}
                >
                  {verifyEmailAPIStatus === "error"
                    ? verifyEmailError.data?.message
                    : t("verify-your-email-description", {
                        email: managerInfo.user.email,
                      })}
                </p>
                {isRunning && (
                  <span
                    sx={{
                      my: "spacing-xs",
                      color: "info-dark",
                      variant: "text.p-x-small-bold",
                    }}
                  >
                    {String(minutes).padStart(2, "0")}
                    {":"}
                    {String(seconds).padStart(2, "0")}
                  </span>
                )}
              </>
            )}
          </>
        )}
      </PopupContent>
      <PopupFooter sx={{ mt: 0, justifyContent: "center" }}>
        {showResendButton && (
          <Button
            variant="secondary"
            size={"medium"}
            onClick={() => {
              resendVerificationEmail({
                email: managerInfo.user.email || "",
              });
              restart(
                add(new Date(), {
                  seconds: (managerInfo.email_verification_threshold || 5) * 60,
                })
              );
              GA.event({
                ...events.actionVerificationResent,
                label: JSON.stringify({
                  id: selectedVendor.id,
                  name: selectedVendor.name,
                  country: selectedVendor.country_name,
                  city: selectedVendor.city_name,
                  timestamp: new Date(),
                }),
              });
            }}
            disabled={isRunning}
          >
            {t("resend-link")}
          </Button>
        )}
      </PopupFooter>
    </Popup>
  );
};

export default VerificationPopup;
