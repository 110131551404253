/** @jsxImportSource theme-ui */

import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { Spinner } from "theme-ui";
import GA from "react-ga";
import keys from "lodash/keys";
import { MultiSelect } from "@swvl/select";
import Pagination from "@swvl/pagination";
import OverallRating from "./OverallRating";
import {
  CaptainRatingParams,
  CaptainRatingResponse,
  useCaptainRatings,
} from "resources/common/useCaptainRating";
import trackEvents from "constants/trackEvents";
import AppliedFilters from "components/AppliedFilters";
import ReviewItem from "./ReviewItem";
import { getStatusesData } from "./utils";
import { Option } from "utils/types";
import { tablePageSizeOptions } from "utils";
import useLang from "hooks/useLang";
import NoData from "components/NoData";
import RatingEmptyBox from "assets/rating-empty-box.svg";

const CaptainRatingAndReviews = ({
  captainRating,
}: {
  captainRating: number;
}) => {
  const { t } = useTranslation("captains");
  const { id: captainId } = useParams();
  const events = trackEvents.getCaptainEvents();
  const [ratingFilters, setRatingFilters] = useState<CaptainRatingParams>({
    page: 1,
    limit: 10,
    score: undefined,
    feedback_category: undefined,
  });

  const { data: ratings, isLoading: isRatingLoading } = useCaptainRatings(
    captainId || "",
    ratingFilters
  );

  const [overallRating, setOverallRating] = useState<CaptainRatingResponse>();
  const [clearFilters, setClearFilters] = useState<boolean>(false);
  const [lang] = useLang();

  useEffect(() => {
    if (!isRatingLoading) {
      setClearFilters(false);
    }
  }, [setClearFilters, isRatingLoading]);

  const updateCurrentFilters = (filters: CaptainRatingParams) => {
    const updatedFilters = {
      ...ratingFilters,
      ...filters,
    };
    setRatingFilters(updatedFilters);
  };

  useEffect(() => {
    if (ratings?.summary) {
      setOverallRating(ratings);
    }
  }, [ratings, ratings?.summary]);

  const captainRatingOptions = [
    { value: "1", label: t("captain-rating-options.one-star") },
    { value: "2", label: t("captain-rating-options.two-stars") },
    {
      value: "3",
      label: t("captain-rating-options.three-stars"),
    },
    {
      value: "4",
      label: t("captain-rating-options.four-stars"),
    },
    {
      value: "5",
      label: t("captain-rating-options.five-stars"),
    },
  ];

  const feedbackOptions = [
    {
      value: "vehicle-quality",
      label: t("rating-reviews.feedback-options.vehicle-quality"),
    },
    {
      value: "trip-quality",
      label: t("rating-reviews.feedback-options.trip-quality"),
    },
    {
      value: "safety-measures",
      label: t("rating-reviews.feedback-options.safety-measures"),
    },
    {
      value: "captain-experience",
      label: t("rating-reviews.feedback-options.captain-experience"),
    },
    {
      value: "payment-experience",
      label: t("rating-reviews.feedback-options.payment-experience"),
    },
  ];

  const appliedScoreFilters = () => {
    return getStatusesData(
      captainRatingOptions,
      ratingFilters.score || []
    )?.map((score) => score.label);
  };

  const appliedFeedbackFilters = () => {
    return getStatusesData(
      feedbackOptions,
      ratingFilters.feedback_category || []
    ).map((feedback) => feedback.label);
  };

  const getAppliedFilters = () => {
    return {
      ...(ratingFilters?.score?.length && {
        score: {
          title: t("captain-rating"),
          value: appliedScoreFilters(),
        },
      }),
      ...(ratingFilters?.feedback_category?.length && {
        feedback_category: {
          title: t("rating-reviews.feedback"),
          value: appliedFeedbackFilters(),
        },
      }),
    };
  };

  const clearAppliedFilters = () => {
    setClearFilters(true);
    setRatingFilters({
      page: 1,
      limit: 10,
      score: undefined,
      feedback_category: undefined,
    });
  };

  const getSelectedFilterValue = (
    arr: { label: string; value: string }[],
    label: string
  ) => arr.find((status) => status.label === label)?.value;

  const removeFilter = ({
    key: filterName,
    value: toBeDeletedValue,
  }: {
    key: string;
    value: string;
  }) => {
    const getSelectedValue = getSelectedFilterValue(
      filterName === "score" ? captainRatingOptions : feedbackOptions,
      toBeDeletedValue
    );
    const rating = ratingFilters.score?.filter(
      (score) => score !== getSelectedValue
    );
    const feedback = ratingFilters.feedback_category?.filter(
      (feedback) => feedback !== getSelectedValue
    );

    setRatingFilters({
      ...ratingFilters,
      score: rating,
      feedback_category: feedback,
    });
  };

  if (
    !ratingFilters.feedback_category &&
    !ratingFilters.score &&
    ratingFilters.page === 1 &&
    ratingFilters.limit === 10 &&
    !clearFilters &&
    isRatingLoading
  ) {
    return (
      <div
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          paddingTop: 100,
        }}
      >
        <Spinner color="secondary" />
      </div>
    );
  }

  if (
    !ratingFilters.feedback_category &&
    ratingFilters.page === 1 &&
    ratingFilters.limit === 10 &&
    !ratingFilters.score &&
    !ratings?.hits?.length
  ) {
    return (
      <NoData
        title={t("rating-reviews.no-rating-available")}
        description={t("rating-reviews.no-rating-description")}
        image={RatingEmptyBox}
      />
    );
  }

  return (
    <div
      sx={{
        display: "flex",
        py: 24,
        gap: "28px",
      }}
    >
      <OverallRating
        captainRating={captainRating}
        ratingData={overallRating as CaptainRatingResponse}
      />
      <div
        sx={{
          width: "78%",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <div
          sx={{
            borderBottom: "1px solid rgba(217, 219, 233, 1)",
          }}
        >
          <div
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingBottom: 24,
              paddingRight: 3,
              paddingLeft: 3,
            }}
          >
            <h2
              sx={{
                fontSize: 24,
                fontWeight: 500,
                textTransform: "capitalize",
              }}
            >
              {ratings?.count} {t("rating-reviews.ratings")}
            </h2>
            <div sx={{ display: "flex", gap: 12 }}>
              <MultiSelect
                required
                isClearable={false}
                id="captain-rating"
                name="captainRating"
                height="compact"
                options={captainRatingOptions}
                placeholder={t("rating-reviews.rating")}
                value={getStatusesData(
                  captainRatingOptions,
                  ratingFilters?.score || []
                )}
                onChange={(values) => {
                  const score = (values as Option[]).reduce(
                    (allScores: string[], { value: score }) => {
                      return [...allScores, ...(score as string).split(",")];
                    },
                    []
                  );
                  updateCurrentFilters({ score, page: 1 });
                  GA.event({
                    ...events.actionFilterCaptainRating,
                    label: JSON.stringify({ score: score }),
                  });
                }}
                sx={{ minWidth: 150, width: 210 }}
                controlShouldRenderValue={false}
              />
              <MultiSelect
                required
                isClearable={false}
                id="captain-feedback"
                name="captainFeedback"
                height="compact"
                options={feedbackOptions}
                placeholder={t("rating-reviews.feedback")}
                value={getStatusesData(
                  feedbackOptions,
                  ratingFilters?.feedback_category || []
                )}
                onChange={(values) => {
                  const feedback = (values as Option[]).reduce(
                    (allScores: string[], { value: feedback }) => {
                      return [...allScores, ...(feedback as string).split(",")];
                    },
                    []
                  );
                  updateCurrentFilters({
                    feedback_category: feedback,
                    page: 1,
                  });
                  GA.event({
                    ...events.actionFilterCaptainFeedback,
                    label: JSON.stringify({ feedback_category: feedback }),
                  });
                }}
                sx={{
                  minWidth: 150,
                  width: 210,
                  "text-wrap": "nowrap",
                }}
                controlShouldRenderValue={false}
              />
            </div>
          </div>
          <div sx={{ width: "100%" }}>
            {keys(getAppliedFilters()).length ? (
              <AppliedFilters
                title={t("applied-filters")}
                filters={getAppliedFilters()}
                clearAll={clearAppliedFilters}
                removeFilter={removeFilter}
              />
            ) : null}
          </div>
        </div>
        {(ratingFilters.feedback_category ||
          ratingFilters.score ||
          ratingFilters.page ||
          clearFilters) &&
          isRatingLoading && (
            <div
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Spinner color="secondary" />
            </div>
          )}
        {ratings?.hits?.length ? (
          <>
            {ratings?.hits?.map((rev, i) => (
              <ReviewItem key={i} review={rev} />
            ))}
            <Pagination
              mode="totalCount"
              totalCount={ratings?.count || 0}
              pageSize={ratingFilters?.limit}
              pageSizeOptions={tablePageSizeOptions}
              direction={lang.direction}
              locale={t("table-locales", { ns: "common", returnObjects: true })}
              pageIndex={ratingFilters?.page && ratingFilters?.page - 1}
              onPageChange={(page) => {
                updateCurrentFilters({ page: page + 1 });
                GA.event({
                  ...events.actionChangePage,
                  label: JSON.stringify({ page_number: page + 1 }),
                });
              }}
              onPageSizeChange={(limit) => {
                if (limit !== ratingFilters.limit) {
                  updateCurrentFilters({ limit, page: 1 });

                  GA.event({
                    ...events.actionChangeRowsPerPage,
                    label: JSON.stringify({ page_limit: limit }),
                  });
                }
              }}
            />
          </>
        ) : (
          <NoData
            title={t("rating-reviews.no-rating-available")}
            description={t("rating-reviews.no-rating-description")}
            image={RatingEmptyBox}
          />
        )}
      </div>
    </div>
  );
};

export default CaptainRatingAndReviews;
