export const statusColor: {
  [key: string]:
    | "background-neutral"
    | "warning-light"
    | "negative-light"
    | "success-light";
} = {
  scheduled: "background-neutral",
  started: "warning-light",
  stopped: "warning-light",
  cancelled: "negative-light",
  corporate_revised: "success-light",
  completed: "success-light",
  default: "background-neutral",
};

export const rideStatusLabels: { [key: string]: string } = {
  scheduled: "captain-status.scheduled",
  started: "captain-status.in-progress",
  stopped: "captain-status.in-progress",
  completed: "captain-status.completed",
  corporate_revised: "captain-status.corporate-revised",
  cancelled: "captain-status.cancelled",
};

export const RidesUserSources: { [key: string]: string } = {
  FLEET_MANAGER: "fms",
  CAPTAIN: "captain",
  DASHBOARD: "dashboard",
};

export const popoverContainerStyles = {
  p: "8px",
  background: "#FFFFFF",
  boxShadow: "0px 3px 16px #DADADA, 0px 0px 1px #C7C7C7",
  borderRadius: "6px",
  minWidth: "170px",
  fontFamily: "body",

  "& > div": {
    display: "flex",
    padding: "8px",
    alignItems: "center",
    fontSize: "0.875rem",
    borderRadius: "6px",

    "&:hover": {
      background: "#f4f7ff",
    },
  },
};
