/** @jsxImportSource theme-ui */
import {
  DocumentTypesType,
  useCountryConfig,
} from "resources/common/useCountryConfig";
import { InfoWrapperItem } from "utils/styled";
import { UploadVerifiableDocument } from "./UploadVerifiableDocument";
import { VERIFIABLE_TYPES } from "./constants";
import { Spinner } from "theme-ui";
import { useVerifiableDocuments } from "resources/common/useVerifiableDocument";
import { useParams } from "react-router";
import React from "react";

const VerifiableDocuments = ({
  cityId,
  noDataComponent = <></>,
  verifiableName,
  verifiableType,
}: {
  verifiableType: keyof typeof VERIFIABLE_TYPES;
  verifiableName: string;
  cityId: string;
  noDataComponent?: React.ReactElement;
}) => {
  const { data: countryConfig, isLoading: isCountryConfigLoading } =
    useCountryConfig(cityId);
  const documentTypes = Object.keys(
    countryConfig?.[verifiableType as "captain" | "bus"] || {}
  );
  const { id: verifiableId } = useParams();
  const {
    data: documents,
    isLoading: isLoadingVerifiableDocuments,
    refetch: refetchDocumentsData,
  } = useVerifiableDocuments(verifiableId, verifiableType);

  if (isCountryConfigLoading || isLoadingVerifiableDocuments) {
    return (
      <div
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "350px",
        }}
      >
        <Spinner color="secondary" />
      </div>
    );
  }

  if (!documentTypes?.length) {
    return noDataComponent;
  }

  return (
    <>
      {documentTypes.map((documentType) => {
        return (
          <InfoWrapperItem key={documentType}>
            <UploadVerifiableDocument
              key={documentType + verifiableType}
              verifiableName={verifiableName}
              verifiableType={verifiableType}
              verifiableDocument={documentType as DocumentTypesType}
              cityId={cityId}
              documentCategory={
                countryConfig?.[verifiableType as "captain" | "bus"][
                  documentType as DocumentTypesType
                ]?.document_category
              }
              documents={documents}
              refetchDocumentsData={refetchDocumentsData}
            />
          </InfoWrapperItem>
        );
      })}
    </>
  );
};

export default VerifiableDocuments;
