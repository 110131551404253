import { DOCUMENT_STATUSES } from "resources/common/useVerifiableDocument";

export const STATUS_COLOR: {
  [key: string]:
    | "background-neutral"
    | "warning-light"
    | "negative-light"
    | "success-light";
} = {
  active: "success-light",
  inactive: "background-neutral",
};

export const STATUS_LABEL: { [key: string]: string } = {
  active: "captain-statuses.active",
  inactive: "captain-statuses.inactive",
};

export const busIconPath = "/fms/static/media/bus";

export const DOCUMENT_TYPES = {
  photo_id: "photo_id",
  driving_license: "driving_license",
  vehicle_license: "vehicle_license",
  drug_report: "drug_report",
  police_report: "police_report",
  liability_letter: "liability_letter",
  inspection_report: "inspection_report",
  vehicle_insurance: "vehicle_insurance",
};

export const DOCUMENT_CATEGORIES = {
  personal: "personal",
  vehicle: "vehicle",
  financial: "financial",
  other: "other",
};

export const VERIFIABLE_TYPES = {
  captain: "captain",
  vehicle: "vehicle",
  user: "user",
  corporate: "corporate",
  employee: "employee",
  bus: "bus",
};

export const DOCUMENT_STATUS_COLOR: {
  [x: string]: "warning-light" | "negative-light" | "success-light";
} = {
  [DOCUMENT_STATUSES.PENDING]: "warning-light",
  [DOCUMENT_STATUSES.EXPIRING_SOON]: "warning-light",
  [DOCUMENT_STATUSES.REJECTED]: "negative-light",
  [DOCUMENT_STATUSES.EXPIRED]: "negative-light",
  [DOCUMENT_STATUSES.VERIFIED]: "success-light",
};

export const CAPTAINS_DETAILS_TABS: string[] = [
  "documents",
  "ratings",
  "location",
  "vehicles",
];
