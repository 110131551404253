/** @jsxImportSource theme-ui */
import { useEffect, useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { AxiosResponse } from "axios";
import { useFormik, FormikProvider, Field } from "formik";
import { string, object } from "yup";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { Button } from "@swvl/button";
import { EyeOffIcon, EyeIcon, GlobeIcon, WarningFilledIcon } from "@swvl/icons";
import { Menu, MenuItem } from "@swvl/menu";

import GA from "react-ga";

import { useAuthContext } from "contexts/authContext";
import useSelectedVendorIndex from "hooks/useSelectedVendorIndex";
import useFreshDeskWidget from "hooks/useFreshDeskWidget";

import FieldInputWrapper from "components/FieldInputWrapper";
import trackEvents from "constants/trackEvents";

import LoginImg from "assets/login.jpg";
import SwvlLogo from "assets/logo-primary.svg";

import { useLogin } from "resources/auth/useLogin";
import useLang from "hooks/useLang";

const loginSchema = object({
  email: string()
    .required("Email is required")
    .email("Please enter a valid email"),
  password: string().required("Password is required"),
});

const Login = (): JSX.Element => {
  const navigate = useNavigate();
  const { accessToken } = useAuthContext();
  const [lang, setLang] = useLang();
  const { t, i18n } = useTranslation("login");
  const { search } = useLocation();
  const { hide: hideHelpWidget } = useFreshDeskWidget();
  const { mutateAsync: loginUser, isLoading, error: loginError } = useLogin();
  const [, setSelectedVendorIndex] = useSelectedVendorIndex();

  const [passwordType, setPasswordType] = useState("password");
  const isPassword = passwordType === "password";
  const events = trackEvents.getLoginEvents();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      const email = values.email.trim();
      const password = values.password.trim();
      loginUser({ email, password });
    },
  });

  useEffect(() => {
    if (accessToken) {
      const searchString: string | null = queryString.parse(search).back as
        | string
        | null;
      const back = searchString ? decodeURIComponent(searchString) : "";
      navigate(back, { replace: true });
    }
  }, [accessToken]);

  useEffect(() => {
    hideHelpWidget();
    setSelectedVendorIndex(0);
    GA.event(events.screenLogin);
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>
          {t("title")} | {t("dashboard-title", { ns: "common" })}
        </title>
      </Helmet>
      <div
        sx={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          sx={{
            flex: "1 0 50%",
            display: "flex",
            flexDirection: "column",
            pt: 40,
            pb: "spacing-l",
            px: ["spacing-l", null, null, "spacing-xxl"],
          }}
        >
          <div sx={{ flexGrow: 1 }}>
            <img src={SwvlLogo} alt="Swvl Logo" sx={{ width: 60, mb: 56 }} />
            <h5 sx={{ variant: "text.h5", mt: 0, mb: "spacing-xxs" }}>
              {t("welcome")}
            </h5>
            <h4 sx={{ variant: "text.h4", my: 0 }}>{t("sign-in")}</h4>

            <FormikProvider value={formik}>
              <form
                noValidate
                onSubmit={formik.handleSubmit}
                sx={{
                  display: "grid",
                  rowGap: 32,
                  width: "100%",
                  maxWidth: ["initial", null, null, "400px"],
                  mt: "spacing-xl",
                }}
              >
                {loginError ? (
                  <div
                    sx={{
                      p: "spacing-s",
                      borderRadius: 6,
                      backgroundColor: "negative-light",
                      display: "flex",
                    }}
                  >
                    <WarningFilledIcon />
                    <p
                      sx={{
                        variant: "text.p-small",
                        my: 0,
                        marginInlineStart: "spacing-s",
                      }}
                    >
                      {(loginError as AxiosResponse).data?.message}
                    </p>
                  </div>
                ) : null}
                <div>
                  <Field
                    name="email"
                    label={t("email")}
                    component={FieldInputWrapper}
                    type="email"
                    placeholder="name@email.com"
                    autoFocus={true}
                    required
                    onBlur={() => {
                      GA.event(events.actionEnterName);
                    }}
                  />
                </div>

                <div>
                  <Field
                    name="password"
                    type={passwordType}
                    label={t("password")}
                    component={FieldInputWrapper}
                    required
                    endEnhancer={
                      isPassword ? (
                        <EyeOffIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            setPasswordType("text");
                            GA.event({
                              ...events.actionViewPassword,
                              label: `${events.actionViewPassword.label}:view_on`,
                            });
                          }}
                        />
                      ) : (
                        <EyeIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            setPasswordType("password");
                            GA.event({
                              ...events.actionViewPassword,
                              label: `${events.actionViewPassword.label}:view_off`,
                            });
                          }}
                        />
                      )
                    }
                    onBlur={() => {
                      GA.event(events.actionEnterPassword);
                    }}
                  />
                </div>

                <Button
                  disabled={isLoading}
                  size="large"
                  onClick={() => {
                    GA.event(events.actionSignIn);
                  }}
                >
                  {t("submit")}
                </Button>
              </form>
            </FormikProvider>
          </div>

          <div
            sx={{
              width: "100%",
              maxWidth: ["initial", null, null, "400px"],
              borderTop: "1px solid",
              borderTopColor: "border",
              pt: "spacing-m",
            }}
          >
            <Menu
              menuButton={
                <div
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginInlineEnd: "spacing-xs",
                  }}
                >
                  <GlobeIcon sx={{ marginInlineEnd: "spacing-xxs" }} />
                  {lang.displayName}
                </div>
              }
            >
              <MenuItem
                active={lang.code === "en"}
                onClick={() => {
                  setLang({
                    code: "en",
                    direction: "ltr",
                    displayName: "En",
                    name: "English",
                  });
                  i18n.changeLanguage("en");
                }}
              >
                English
              </MenuItem>
              <MenuItem
                active={lang.code === "ar"}
                onClick={() => {
                  setLang({
                    code: "ar",
                    direction: "rtl",
                    displayName: "Ar",
                    name: "العربية",
                  });
                  i18n.changeLanguage("ar");
                }}
              >
                العربية
              </MenuItem>
            </Menu>
          </div>
        </div>
        <div sx={{ flexBasis: "50%", display: ["none", null, null, "block"] }}>
          <img
            src={LoginImg}
            alt="Login"
            sx={{ objectFit: "cover", width: "100%", height: "100%" }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default Login;
