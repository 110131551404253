import { useMutation } from "react-query";
import { showAlert } from "@swvl/alert";

import apiInstance from "api";

// Types
interface InviteCaptainRequest {
  name: string;
  phone: string;
  language: string;
}

interface InviteCaptainResponse {
  message: string;
}

export interface InviteCaptainError {
  data: {
    error: string;
    message: string;
    path: [string];
    stack: string;
    statusCode: number;
  };
}

// Resources
const inviteCaptain = async (data: InviteCaptainRequest) => {
  return await apiInstance.post<InviteCaptainRequest, InviteCaptainResponse>(
    "/captain/invite",
    data
  );
};

const useInviteCaptain = () => {
  const mutation = useMutation(inviteCaptain, {
    mutationKey: "/captain/invite",
    onSuccess: ({ message }) => {
      showAlert({
        message,
        variant: "success",
        mode: "light",
      });
    },
  });

  return mutation;
};

export { useInviteCaptain };
