import { useQuery } from "react-query";
import GA from "react-ga";

import apiInstance from "api";
import trackEvents from "constants/trackEvents";
import { useAuthContext } from "contexts/authContext";

export type CaptainRatingParams = {
  page?: number;
  limit?: number;
  score?: string[];
  feedback_category?: string[];
};

export interface CaptainRating {
  user: {
    name: string;
    rating: number;
    createdAt: string;
  };
  feedback: {
    category: string;
    topic: string;
  }[];
  comments: string;
  ride: string;
}

export interface CaptainRatingSummary {
  average: number;
  count: number;
  score: string;
  breakdown: {
    [key: string]: {
      percentage: number;
      count: number;
    };
  };
}

export type CaptainRatingResponse = {
  count: number;
  page: number;
  limit: number;
  hits: CaptainRating[];
  summary: CaptainRatingSummary;
};

const transformResponse = ({
  count,
  page,
  limit,
  hits,
  summary,
}: CaptainRatingResponse) => {
  return { count, page, limit, hits, summary };
};

const getCaptainRating = async (id: string, params: CaptainRatingParams) => {
  const response = apiInstance.get<unknown, CaptainRatingResponse>(
    `captain/${id}/ratings`,
    {
      params,
    }
  );
  return transformResponse(await response);
};

const useCaptainRatings = (id: string, params: CaptainRatingParams) => {
  const events = trackEvents.getCaptainEvents();
  const { dynamicKeys } = useAuthContext();

  return useQuery(
    ["captain-ratings", id, params, dynamicKeys],
    () => getCaptainRating(id, params),
    {
      onSuccess: () => GA.event(events.statusSearchSuccessfully),
    }
  );
};

export { useCaptainRatings };
