import { busIconPath } from "pages/fleet/constants";
import { useEffect, useState } from "react";
import { querySelectorAsync } from "utils/dom";

const useBusMarkerRef = () => {
  const [busMarkerRef, setBusMarkerRef] = useState<HTMLImageElement | null>(
    null
  );

  useEffect(() => {
    querySelectorAsync<HTMLImageElement>({
      query: `img[src^="${busIconPath}"]`,
    }).then((busIcon) => {
      if (busIcon.parentElement)
        busIcon.parentElement.style.overflow = "visible";
      setBusMarkerRef(busIcon);
    });
  }, []);

  return busMarkerRef;
};

export default useBusMarkerRef;
