/** @jsxImportSource theme-ui */
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { showAlert } from "@swvl/alert";
import { Station } from "@swvl/map/dist/src/types";
import Map, { Marker } from "@swvl/map";
import GA from "react-ga";

import useRideLocation from "hooks/useRideLocation";
import trackEvents from "constants/trackEvents";
import { RideDetails } from "resources/rides-list/useRideDetails";
import useMenuOpen from "hooks/useMenuOpen";
import BusIcon from "assets/bus.svg";
import useBusMarkerRef from "hooks/useBusMarkerRef";

const RideDetailsMap = ({ ride }: { ride: RideDetails }) => {
  const { t } = useTranslation("rides");
  const route = ride.route;
  const stationsData = ride.stations_data;
  const isStationsEmpty = stationsData?.length === 0;
  const [isMenuOpen, setMenuOpen] = useMenuOpen();
  const events = trackEvents.getRideEvents();
  const { location } = useRideLocation(ride._id);
  const busMarkerRef = useBusMarkerRef();
  const routes = useMemo(
    () => ({
      route_key: {
        encodedPolyline: route?.polyline,
        strokeOpacity: 1,
        startColor: "#FFAD93",
        endColor: "#FF8A65",
        stations: stationsData.map(({ station }, index): Station => {
          return {
            id: station._id,
            name: station.name,
            label: `${index + 1}`,
            lng: station.loc.coordinates[0],
            lat: station.loc.coordinates[1],
            style: {
              scale: 11,
              strokeColor: "#FFA184",
              fillColor: "#FFA184",
              fillOpacity: 1,
              strokeWeight: 2,
            },
          };
        }),
      },
    }),
    [route?.polyline, stationsData]
  );

  useEffect(() => {
    if (isStationsEmpty) {
      showAlert({
        message: t("ride-map-no-stations"),
        mode: "light",
        variant: "info",
      });
    }
  }, [isStationsEmpty, t]);

  useEffect(() => {
    GA.event({
      ...events.screenMapView,
      label: JSON.stringify({ ride_id: ride?._id }),
    });

    if (isMenuOpen === undefined) {
      setMenuOpen(false);
    }
    return () => {
      if (isMenuOpen === undefined) {
        setMenuOpen(undefined);
      }
    };
  }, []);

  useEffect(() => {
    if (location?.bearing && busMarkerRef) {
      busMarkerRef.style.transform = `rotate(${location.bearing}deg)`;
    }
  }, [location?.bearing, busMarkerRef]);

  const centerIndex = Math.floor(stationsData.length / 2);
  const { coordinates } = stationsData[centerIndex].station.loc;
  const [lat, lng] = coordinates || [];
  const center = useMemo(() => ({ lat, lng }), [lat, lng]);

  if (isStationsEmpty) return null;

  return (
    <div
      sx={{
        height: "100%",
        background: "white",
      }}
    >
      <Map
        showStationPopover
        googleMapsApiKey={window.__env__.GOOGLE_MAPS_API_KEY}
        routes={routes}
        mapContainerStyle={{
          flex: 1,
          height: "100%",
        }}
        center={center}
      >
        {/* <MapInfo>
          <MapInfo.Header>{route.name}</MapInfo.Header>
          <MapInfo.SubHeader>
            {stationsData.length} {t("stations")}
          </MapInfo.SubHeader>
          <MapInfo.Body>
            {stationsData.map(({ station }, index) => (
              <div
                key={station._id}
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  paddingRight: "8px",
                  paddingLeft: "8px",
                  paddingTop: 12,
                  paddingBottom: 12,
                  borderRadius: 6,
                }}
              >
                <p
                  style={{
                    margin: 0,
                    width: 24,
                    height: 24,
                    textAlign: "center",
                    lineHeight: "24px",
                    backgroundColor: "#FF8A65",
                    borderRadius: "50%",
                    flexShrink: 0,
                  }}
                >
                  {index + 1}
                </p>
                <p style={{ margin: 0, marginInlineStart: 12 }}>
                  {station.name}
                </p>
              </div>
            ))}
          </MapInfo.Body>
        </MapInfo> */}
        {location && (
          <Marker
            position={{ lat: location.lat, lng: location.lng }}
            icon={{
              url: BusIcon,
            }}
            animation={
              window.google ? window.google.maps.Animation.DROP : undefined
            }
          />
        )}
      </Map>
    </div>
  );
};

export default RideDetailsMap;
