import { FC } from "react";
import theme, { arabicTheme, ThemeProvider } from "@swvl/theme";

import useLang from "hooks/useLang";

const ThemeControlWrapper: FC = ({ children }) => {
  const [lang] = useLang();

  return (
    <ThemeProvider theme={lang.direction === "rtl" ? arabicTheme : theme}>
      {children}
    </ThemeProvider>
  );
};

export default ThemeControlWrapper;
