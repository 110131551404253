/** @jsxImportSource theme-ui */

const FilterTag = ({
  value,
  removeFn,
}: {
  value: string;
  removeFn: () => void;
}) => {
  return (
    <div
      sx={{
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        gap: "spacing-xs",
        borderRadius: "6px",
        backgroundColor: "secondary-light-90",
        padding: "spacing-xs",
        paddingRight: "spacing-xs",
        width: "auto",
        height: "24px",
        variant: "text.p-x-small",
      }}
    >
      <span
        sx={{
          width: "max-content",
        }}
      >
        {value}
      </span>
      <span
        onClick={removeFn}
        sx={{
          cursor: "pointer",
          variant: "text.p-x-small",
          width: "12px",
          height: "12px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "content-tertiary",
        }}
      >
        &#x2715;
      </span>
    </div>
  );
};

export default FilterTag;
