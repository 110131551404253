import { RideIcon, FlashIcon } from "@swvl/icons";
import { SideBar } from "utils/types";

const sideBarTabs: SideBar = [
  {
    text: "rides-management",
    icon: <RideIcon />,
    link: "/rides-management/rides",
  },
  {
    text: "fleet",
    icon: <FlashIcon viewBox="0 0 21 21" />,
    subItems: [
      {
        text: "vehicles",
        link: "/fleet/vehicles",
      },
      {
        text: "captains",
        link: "/fleet/captains",
      },
    ],
  },
];

export default sideBarTabs;
