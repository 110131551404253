/** @jsxImportSource theme-ui */
import { Fragment, useState } from "react";
import { ErrorMessage as FormikErrorMessage, FieldProps } from "formik";
import { AutocompleteProps } from "@swvl/autocomplete";
import { ErrorMessage } from "@swvl/input";

import Autocomplete from "components/AutocompleteWrapper";

interface FieldAutoCompleteWrapperProps {
  getSelectedOptionValue?: (value: unknown) => void;
}

const FieldAutoCompleteWrapper = ({
  field: { name, onBlur },
  form: { errors, touched, setFieldValue, setFieldTouched },
  onClear,
  onOptionSelect,
  ...props
}: FieldProps & AutocompleteProps & FieldAutoCompleteWrapperProps) => {
  const [isFieldChanged, setFieldChanged] = useState(false);

  return (
    <Fragment>
      <Autocomplete
        {...props}
        error={Boolean(errors[name] && touched[name])}
        success={touched[name] && !errors[name]}
        name={name}
        onBlur={(e) => isFieldChanged && onBlur(e)}
        onOptionSelect={(value) => {
          let selectedOptionValue = value;
          if (!touched[name]) {
            setFieldTouched(name);
          }
          if (props.getSelectedOptionValue) {
            selectedOptionValue = props.getSelectedOptionValue(value);
          }
          setFieldValue(name, selectedOptionValue);
          onOptionSelect?.(selectedOptionValue);
        }}
        onClear={() => {
          if (!isFieldChanged) {
            setFieldChanged(true);
          }
          setFieldValue(name, "");
          onClear?.();
        }}
      />
      <FormikErrorMessage name={name}>
        {(msg) => <ErrorMessage>{msg}</ErrorMessage>}
      </FormikErrorMessage>
    </Fragment>
  );
};

export default FieldAutoCompleteWrapper;
