import * as React from "react";

const UploadIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_162_2551"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_162_2551)">
        <path
          d="M4.99998 16.6667C4.54165 16.6667 4.14929 16.5035 3.8229 16.1771C3.49651 15.8507 3.33331 15.4583 3.33331 15V13.3333C3.33331 13.0972 3.41317 12.8993 3.5729 12.7396C3.73262 12.5799 3.93054 12.5 4.16665 12.5C4.40276 12.5 4.60067 12.5799 4.7604 12.7396C4.92012 12.8993 4.99998 13.0972 4.99998 13.3333V15H15V13.3333C15 13.0972 15.0798 12.8993 15.2396 12.7396C15.3993 12.5799 15.5972 12.5 15.8333 12.5C16.0694 12.5 16.2673 12.5799 16.4271 12.7396C16.5868 12.8993 16.6666 13.0972 16.6666 13.3333V15C16.6666 15.4583 16.5035 15.8507 16.1771 16.1771C15.8507 16.5035 15.4583 16.6667 15 16.6667H4.99998ZM9.16665 6.54167L7.60415 8.10417C7.43748 8.27083 7.23956 8.35069 7.0104 8.34375C6.78123 8.33681 6.58331 8.25 6.41665 8.08333C6.26387 7.91667 6.18401 7.72222 6.17706 7.5C6.17012 7.27778 6.24998 7.08333 6.41665 6.91667L9.41665 3.91667C9.49998 3.83333 9.59026 3.77431 9.68748 3.73958C9.7847 3.70486 9.88887 3.6875 9.99998 3.6875C10.1111 3.6875 10.2153 3.70486 10.3125 3.73958C10.4097 3.77431 10.5 3.83333 10.5833 3.91667L13.5833 6.91667C13.75 7.08333 13.8298 7.27778 13.8229 7.5C13.816 7.72222 13.7361 7.91667 13.5833 8.08333C13.4166 8.25 13.2187 8.33681 12.9896 8.34375C12.7604 8.35069 12.5625 8.27083 12.3958 8.10417L10.8333 6.54167V12.5C10.8333 12.7361 10.7535 12.934 10.5937 13.0938C10.434 13.2535 10.2361 13.3333 9.99998 13.3333C9.76387 13.3333 9.56595 13.2535 9.40623 13.0938C9.24651 12.934 9.16665 12.7361 9.16665 12.5V6.54167Z"
          fill="#2356F9"
        />
      </g>
    </svg>
  );
};

export default UploadIcon;
