/** @jsxImportSource theme-ui */

import Avatar from "@swvl/avatar";
import {
  differenceInDays,
  differenceInMonths,
  differenceInYears,
} from "date-fns";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { UserReview } from "resources/rides-list/useRideDetails";
import DotIcon from "shared/icons/DotIcon";
import ReviewIcon from "shared/icons/ReviewIcon";
import StarBlueIcon from "shared/icons/StarBlueIcon";
import StarEmptyIcon from "shared/icons/StarEmptyIcon";
import { groupBy } from "utils";

const TODAY = new Date();
const getTimeKey = (createdAt?: string): [string, number] => {
  if (!createdAt) return ["", 0];

  const days = differenceInDays(TODAY, new Date(createdAt));
  const months = days > 30 ? differenceInMonths(TODAY, new Date(createdAt)) : 0;
  const years = days > 365 ? differenceInYears(TODAY, new Date(createdAt)) : 0;

  if (years === 1) return ["one_year_ago", years];
  if (years > 1) return ["years_ago", years];
  if (months === 1) return ["one_month_ago", months];
  if (months > 1) return ["months_ago", months];
  if (days > 1) return ["days_ago", days];
  return ["today", days];
};

const UserReviewItem = ({ review }: { review?: UserReview }) => {
  const { t } = useTranslation("rides");
  const [timeKey, timeValue] = useMemo(
    () => getTimeKey(review?.user?.createdAt),
    [review?.user?.createdAt]
  );

  if (!review) return null;
  const feedbackByCategory = groupBy(review.feedback, "category");
  const categories = Object.keys(feedbackByCategory);
  return (
    <div
      sx={{
        borderBottom: "1px solid",
        borderColor: "background-quinary",
        paddingBottom: "spacing-m-l",
        marginBottom: "spacing-m-l",
      }}
    >
      {/* User Avatar */}
      <div
        sx={{
          display: "flex",
          gap: "12px",
          alignItems: "center",
        }}
      >
        <Avatar
          sx={{
            height: "40px",
            width: "40px",
          }}
          src={review.user?.picture}
          name={review.user?.name}
          bg={"primary"}
          color={"white"}
        />
        <div sx={{ display: "flex", flexDirection: "column" }}>
          <span sx={{ variant: "text.p-small", mt: "-2px" }}>
            {review.user?.name}
          </span>
          <div
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "2px",
            }}
          >
            {new Array(review.user?.rating || 0).fill("").map((_, index) => (
              <StarBlueIcon key={index + "filled"} width={16} height={16} />
            ))}
            {new Array(5 - review.user?.rating || 0)
              .fill("")
              .map((_, index) => (
                <StarEmptyIcon key={index + "empty"} width={16} height={16} />
              ))}
            <span
              sx={{
                color: "content-tertiary",
                variant: "text.p-x-small",
                display: "inline-block",
                marginInlineStart: "spacing-xxs",
              }}
            >
              {(t(timeKey) || "").replace("{value}", timeValue.toString())}
            </span>
          </div>
        </div>
      </div>
      {/* Trip feedback */}
      <div sx={{ paddingInlineStart: "46px" }}>
        {categories.map((category) => {
          const feedback = feedbackByCategory[category];
          return (
            <div
              key={category}
              sx={{
                display: "grid",
                gridTemplateColumns: "17% auto",
                mt: "spacing-m",
              }}
            >
              <div
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "spacing-xs",
                  alignSelf: "flex-start",
                  paddingInlineEnd: "spacing-xs",
                }}
              >
                <ReviewIcon sx={{ minWidth: "16px" }} />
                <span
                  sx={{
                    variant: "text.p-x-small-medium",
                    fontWeight: 600,
                    display: "inline-block",
                    whiteSpace: "nowrap",
                    maxWidth: "150px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textTransform: "capitalize",
                  }}
                >
                  {category}
                </span>
              </div>
              <ul
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  color: "content-secondary",
                  m: 0,
                  p: 0,
                  listStyle: "none",
                  rowGap: "spacing-s",
                }}
              >
                {feedback.map((item, index) => (
                  <li
                    key={item.topic + index}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <span>{item.topic}</span>
                    {index !== feedback.length - 1 && (
                      <DotIcon sx={{ marginX: "13px" }} />
                    )}
                  </li>
                ))}
              </ul>
            </div>
          );
        })}
      </div>
      {/* User comments */}
      {review.comments && (
        <p
          sx={{
            variant: "text.p-small",
            m: 0,
            marginTop: "spacing-m",
            color: "content-secondary",
            paddingInlineStart: "46px",
          }}
        >
          {review.comments}
        </p>
      )}
    </div>
  );
};

export default UserReviewItem;
