/** @jsxImportSource theme-ui */
import { useState, useEffect, Fragment, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import startCase from "lodash/startCase";
import format from "date-fns/format";
import { Spinner, Flex } from "theme-ui";
import GA from "react-ga";
import { AxiosResponse } from "axios";

import { Button, IconButton } from "@swvl/button";
import Tag from "@swvl/tag";
import {
  CaptainDeleteIcon,
  RideUpdateIcon,
  ArrowRightIcon,
  ArrowLeftIcon,
  ArrowSDownIcon,
  ArrowSUpIcon,
} from "@swvl/icons";
import Drawer from "@swvl/drawer";
import { useTheme } from "@swvl/theme";
import TabsWrapper, { TabWrapper } from "@swvl/tabs";

import { useRideDetails } from "resources/rides-list/useRideDetails";
import { useUnassignCaptain } from "resources/rides-list/useUnassignCaptain";
import { useUnassignRide } from "resources/rides-list/useUnassignRide";
import {
  useAssignCaptain,
  AssignCaptainRequest,
} from "resources/rides-list/useAssignCaptain";
import { UnassignRequestPayload } from "resources/rides-list/useUnassignCaptain";
import trackEvents from "constants/trackEvents";

import UnassignRideCaptain from "./UnassignRideCaptain";
import UpdateRideDrawer from "./UpdateRideDrawer";
import RideHistory from "./RideHistory";

import { dateTimeFormatCapital, timeFormat24 } from "constants/index";
import { InfoWrapper, InfoWrapperItem } from "utils/styled";
import { Bus } from "utils/types";
import useLang from "hooks/useLang";

import NotFound from "components/NotFound";
import NoData from "components/NoData";
import { NOT_FOUND } from "constants/httpCode";
import StarBlueIcon from "shared/icons/StarBlueIcon";
import StarEmptyIcon from "shared/icons/StarEmptyIcon";
import DotIcon from "shared/icons/DotIcon";
import CalendarRoundedIcon from "shared/icons/CalendarRoundedIcon";
import RatingEmptyBox from "assets/rating-empty-box.svg";
import StarHalfIcon from "shared/icons/StarHalfIcon";

import RideDetailsMap from "./RideDetailsMap";
import RatingBreakDown from "./RatingBreakDown";
import UserReviewItem from "./UserReviewItem";
import { rideStatusLabels, statusColor } from "./constants";
import { RideData, UpdateRideProps } from "./types";

const RideDetails = () => {
  const { t } = useTranslation("rides");
  const { id } = useParams();
  const rideId = id || "";
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { theme } = useTheme();
  const [lang] = useLang();

  const {
    data: rideDetails,
    isLoading,
    error: rideDetailError,
  } = useRideDetails(rideId);
  const ratingData = rideDetails?.rating;

  const { mutateAsync: unassignCaptain } = useUnassignCaptain();
  const { mutateAsync: unassignRide } = useUnassignRide();
  const { mutateAsync: assignCaptain, isLoading: isAssignCaptainLoading } =
    useAssignCaptain();

  const [unassignCaptainRideData, setUnassignCaptainRideData] =
    useState<RideData>();
  const [showUnassignRideCaptainPopup, setShowUnassignRideCaptainPopup] =
    useState(false);
  const [isAssignCaptainDrawerOpen, setAssignCaptainDrawerOpen] =
    useState(false);
  const [assignReassignData, setAssignReassignData] =
    useState<UpdateRideProps>();

  const [expandMapView, setExpandMapView] = useState(false);
  const isRideScheduled = rideDetails?.status === "scheduled";
  const events = trackEvents.getRideEvents();

  const mapContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    GA.event({
      ...events.screenRideInfo,
      label: JSON.stringify({ ride_id: rideId }),
    });
  }, []);

  if (rideDetailError) {
    const { status } = rideDetailError as AxiosResponse;
    if (status === NOT_FOUND) {
      return (
        <NotFound
          title={t("not-found-title", { ns: "common" })}
          description={t("not-found-description", { ns: "common" })}
          goBackCallback={() =>
            navigate("/rides-management/rides", { replace: true })
          }
        />
      );
    }
  }

  const handleUnAssign = (rideData: RideData) => {
    setUnassignCaptainRideData(rideData);
    setShowUnassignRideCaptainPopup(true);
  };

  const handleAssignReassignCaptain = (params: UpdateRideProps) => {
    setAssignCaptainDrawerOpen(true);
    setAssignReassignData(params);
  };

  const handleAssignCaptainSubmit = (params: AssignCaptainRequest) => {
    assignCaptain(params).then(() => {
      setAssignCaptainDrawerOpen(false);
      queryClient.invalidateQueries(["ride-details", rideId]);
    });
  };

  const handleUnassignSubmit = (params: UnassignRequestPayload) => {
    const requestClient = unassignCaptainRideData?.captainId
      ? unassignCaptain
      : unassignRide;
    requestClient(params).then(() =>
      queryClient.invalidateQueries(["ride-details", rideId])
    );
  };

  const goBack = () => {
    navigate(-1);
  };

  let rideTitle = "Ride";
  if (rideDetails?.origin_district && rideDetails?.destination_district) {
    rideTitle = `${rideDetails?.origin_district?.name} - ${rideDetails?.destination_district?.name}`;
  } else if (rideDetails?.route?.name) {
    rideTitle = rideDetails?.route?.name;
  }

  const allow_bus_type_update = rideDetails?.ride_category.features.find(
    (item) => item.name === "allow_bus_type_update"
  );

  return (
    <div>
      <Helmet>
        <title>
          {t("ride-details")} | {t("dashboard-title", { ns: "common" })}
        </title>
      </Helmet>

      {isLoading ? (
        <div sx={{ textAlign: "center" }}>
          <Spinner color="secondary" />{" "}
        </div>
      ) : null}

      {rideDetails ? (
        <Fragment>
          {/* Ride header */}
          <div
            sx={{
              display: "flex",
              justifyContent: "space-between",
              p: "spacing-m",
              background: "linear-gradient(0deg, #FFF 0%, #F4F7FF 100%)",
            }}
          >
            <div
              sx={{
                display: "flex",
                justifyContent: "start",
                flexDirection: "column",
              }}
            >
              <div
                sx={{
                  display: "flex",
                  justifyContent: "start",
                }}
              >
                <IconButton
                  sx={{
                    marginInlineEnd: "spacing-m",
                    "& svg": { fill: theme.colors["gray-700"] },
                  }}
                  icon={
                    lang.code === "ar" ? <ArrowRightIcon /> : <ArrowLeftIcon />
                  }
                  onClick={goBack}
                />
                <div>
                  <h4 sx={{ variant: "text.h4", m: 0 }}>{rideTitle}</h4>
                  <div
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mt: "spacing-xs",
                    }}
                  >
                    {/* TODO: uncomment when ratings data is available */}
                    {ratingData && ratingData.summary?.count !== 0 && (
                      <>
                        <div sx={{ display: "flex", alignItems: "center" }}>
                          {new Array(Math.floor(ratingData.summary?.average))
                            .fill("")
                            .map((_, index) => (
                              <StarBlueIcon
                                key={index + "filled"}
                                width={16}
                                height={16}
                              />
                            ))}
                          {ratingData.summary?.average -
                            Math.floor(ratingData.summary?.average) >
                            0 && <StarHalfIcon width={16} height={16} />}
                          {new Array(5 - Math.ceil(ratingData.summary?.average))
                            .fill("")
                            .map((_, index) => (
                              <StarEmptyIcon
                                key={index + "empty"}
                                width={16}
                                height={16}
                              />
                            ))}

                          <p
                            sx={{
                              variant: "text.p-small",
                              m: 0,
                              marginInlineStart: "spacing-xxs",
                            }}
                          >
                            <span>{ratingData.summary?.average}</span>{" "}
                            <span sx={{ color: "content-tertiary" }}>
                              ({ratingData.summary.count}{" "}
                              {ratingData.summary.count == 1
                                ? t("rating")
                                : t("ratings")}
                              )
                            </span>
                          </p>
                        </div>
                        <DotIcon sx={{ marginX: "spacing-s" }} />
                      </>
                    )}
                    <div sx={{ display: "flex", alignItems: "center" }}>
                      <CalendarRoundedIcon
                        height={16}
                        width={16}
                        sx={{ marginInlineEnd: "spacing-xs" }}
                      />
                      <p
                        sx={{
                          variant: "text.p-small",
                          m: 0,
                          direction: "ltr",
                        }}
                      >
                        {format(
                          rideDetails.date as Date,
                          dateTimeFormatCapital
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Flex>
                {isRideScheduled && (
                  <>
                    {rideDetails.captain ? (
                      <Flex>
                        <Button
                          variant="tertiary"
                          icon={
                            <RideUpdateIcon
                              height={20}
                              width={20}
                              fill="#505050"
                              sx={{
                                marginInlineEnd: "spacing-xs",
                              }}
                            />
                          }
                          sx={{
                            borderColor: "border",
                            color: "#000",
                            paddingX: "12px",
                            marginInlineEnd: "spacing-xs",
                            "& > span > span": {
                              variant: "text.p-small",
                            },
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            handleAssignReassignCaptain({
                              rideId,
                              routeName: rideDetails.route.name,
                              busType: rideDetails.bus_type,
                              bus: rideDetails.bus as Bus,
                              captain: rideDetails.captain,
                              origin_district:
                                rideDetails.origin_district?.name,
                              destination_district:
                                rideDetails.destination_district?.name,
                              allow_bus_type_update:
                                (allow_bus_type_update?.value as boolean) ||
                                false,
                            });
                          }}
                        >
                          <span>{t("update-ride.title")}</span>
                        </Button>
                        <Button
                          variant="tertiary"
                          icon={
                            <CaptainDeleteIcon
                              height={20}
                              width={20}
                              fill="#505050"
                              sx={{
                                marginInlineEnd: "spacing-xs",
                              }}
                            />
                          }
                          sx={{
                            borderColor: "border",
                            color: "#000",
                            paddingX: "12px",
                            marginInlineEnd: "spacing-xs",
                            "& > span > span": {
                              variant: "text.p-small",
                            },
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            handleUnAssign({
                              captainName: rideDetails.captain?.name,
                              captainId: rideDetails.captain?._id,
                              rideId: rideDetails?._id,
                            });
                          }}
                        >
                          <span>{t("unassign-captain.title")}</span>
                        </Button>
                      </Flex>
                    ) : (
                      <Flex>
                        <Button
                          variant="tertiary"
                          icon={
                            <RideUpdateIcon
                              height={20}
                              width={20}
                              fill="#505050"
                              sx={{
                                marginInlineEnd: "spacing-xs",
                              }}
                            />
                          }
                          sx={{
                            borderColor: "border",
                            color: "#000",
                            paddingX: "12px",
                            marginInlineEnd: "spacing-xs",
                            "& > span > span": {
                              variant: "text.p-small",
                            },
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            handleAssignReassignCaptain({
                              rideId,
                              routeName: rideDetails.route?.name,
                              busType: rideDetails.bus_type,
                              bus: rideDetails.bus as Bus,
                              captain: rideDetails.captain,
                              origin_district:
                                rideDetails.origin_district?.name,
                              destination_district:
                                rideDetails.destination_district?.name,
                              allow_bus_type_update:
                                (allow_bus_type_update?.value as boolean) ||
                                false,
                            });
                          }}
                        >
                          <span>{t("update-ride.title")}</span>
                        </Button>
                        <Button
                          variant="tertiary"
                          icon={
                            <CaptainDeleteIcon
                              height={20}
                              width={20}
                              fill="#505050"
                              sx={{
                                marginInlineEnd: "12px",
                              }}
                            />
                          }
                          sx={{
                            borderColor: "border",
                            color: "#000",
                            paddingX: "12px",
                            marginInlineEnd: "spacing-xs",
                            "& > span > span": {
                              variant: "text.p-small",
                            },
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            handleUnAssign({
                              rideId: rideDetails._id,
                            });
                          }}
                        >
                          <span>{t("unassign-ride.title")}</span>
                        </Button>
                      </Flex>
                    )}
                  </>
                )}
              </Flex>
            </div>
          </div>
          {/* Ride detail */}
          <div
            sx={{
              px: "spacing-m",
              pt: "spacing-m",
            }}
          >
            <TabsWrapper tabSize="medium">
              <TabWrapper tab={t("info")} key={"1"}>
                <div sx={{ mt: "spacing-m" }}>
                  <InfoWrapper>
                    <InfoWrapperItem>
                      <p>{t("ride-status")}</p>
                      <Tag
                        variant={
                          statusColor[rideDetails.status] ||
                          statusColor["default"]
                        }
                      >
                        {startCase(t(rideStatusLabels[rideDetails.status]))}
                      </Tag>
                    </InfoWrapperItem>
                    <InfoWrapperItem>
                      <p>{t("route-city")}</p>
                      <p>{rideDetails.city?.name}</p>
                    </InfoWrapperItem>
                    <InfoWrapperItem>
                      <p>{t("origin")}</p>
                      <p>{rideDetails.origin_district?.name || "-"}</p>
                    </InfoWrapperItem>
                    <InfoWrapperItem>
                      <p>{t("destination")}</p>
                      <p>{rideDetails.destination_district?.name || "-"}</p>
                    </InfoWrapperItem>
                    <InfoWrapperItem>
                      <p>{t("predicted-end-time")}</p>
                      <p>
                        {rideDetails.predicted_end_date
                          ? format(
                              new Date(rideDetails.predicted_end_date),
                              timeFormat24
                            )
                          : "-"}
                      </p>
                    </InfoWrapperItem>
                    <InfoWrapperItem>
                      <p>{t("corporate")}</p>
                      <p>
                        {rideDetails.corporates?.length > 0
                          ? rideDetails.corporates[0]?.name || "-"
                          : "-"}
                      </p>
                    </InfoWrapperItem>
                    <InfoWrapperItem>
                      <p>{t("ride-category")}</p>
                      <p>
                        {startCase(
                          `${rideDetails.ride_category?.ride_type} ${rideDetails.ride_category?.service_type}`
                        )}
                      </p>
                    </InfoWrapperItem>
                    <InfoWrapperItem>
                      <p>{t("ride-distance")}</p>
                      <p>
                        {rideDetails?.route_distance > 0 ? (
                          <span>
                            {Math.round(rideDetails.route_distance)}{" "}
                            {t("ride-distance-unit")}
                          </span>
                        ) : (
                          "-"
                        )}
                      </p>
                    </InfoWrapperItem>
                    <InfoWrapperItem>
                      <p>{t("number-of-bookings")}</p>
                      <p>{rideDetails.bookings?.count}</p>
                    </InfoWrapperItem>
                    <InfoWrapperItem>
                      <p>{t("capacity")}</p>
                      <p>{rideDetails.bookings?.maximum}</p>
                    </InfoWrapperItem>
                  </InfoWrapper>
                </div>
              </TabWrapper>
              <TabWrapper tab={t("captain-info")} key={"2"}>
                <div sx={{ mt: "spacing-m" }}>
                  <InfoWrapper>
                    <InfoWrapperItem>
                      <p>{t("captain-name")}</p>
                      <div sx={{ display: "flex", flexGrow: 1 }}>
                        <p
                          sx={{
                            m: 0,
                            variant: "text.p-small",
                            flexGrow: 1,
                          }}
                        >
                          {rideDetails.captain?.name || "-"}
                        </p>
                      </div>
                    </InfoWrapperItem>
                    <InfoWrapperItem>
                      <p>{t("captain-phone")}</p>
                      <p>{rideDetails.captain?.phone || "-"}</p>
                    </InfoWrapperItem>
                    <InfoWrapperItem>
                      <p>{t("captain-price")}</p>
                      <p>
                        {rideDetails.captain_price} {rideDetails.currency}
                      </p>
                    </InfoWrapperItem>
                    <InfoWrapperItem>
                      <p>{t("vehicle-type")}</p>
                      <p>{rideDetails.bus_type?.name || "-"}</p>
                    </InfoWrapperItem>
                    <InfoWrapperItem>
                      <p>{t("bus-plate")}</p>
                      <p>{rideDetails.bus?.plates || "-"}</p>
                    </InfoWrapperItem>
                  </InfoWrapper>
                </div>
              </TabWrapper>
              <TabWrapper tab={t("ratings-review")} key={"3"}>
                <div sx={{ mt: "spacing-m-l" }}>
                  {!ratingData || ratingData.summary?.count === 0 ? (
                    <NoData
                      title={t("no_rating_available")}
                      description={t("no_rating_description")}
                      image={RatingEmptyBox}
                      style={{
                        margin: 0,
                      }}
                    />
                  ) : (
                    <div
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "25% 75%",
                        gap: "spacing-xl",
                      }}
                    >
                      <RatingBreakDown ratingData={ratingData} />
                      <div
                        sx={{
                          mt: "24px",
                          "& > div:last-child": { borderBottom: "none" },
                        }}
                      >
                        {ratingData.listing?.map((review, index) => (
                          <UserReviewItem
                            key={review.user?.name + index}
                            review={review}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </TabWrapper>
              <TabWrapper tab={t("ride-history")} key={"4"}>
                <div sx={{ mt: "spacing-m" }}>
                  <RideHistory rideId={rideDetails._id} />
                </div>
              </TabWrapper>
              <TabWrapper tab={t("map")} key={"5"}>
                <div
                  ref={mapContainerRef}
                  sx={{
                    position: expandMapView ? "static" : "relative",
                    height: "75vh",
                    mt: "spacing-m",
                    me: "spacing-m",
                    transition: "height 0.3s ease-out, width 0.3s ease-out",
                  }}
                >
                  <div
                    sx={{
                      position: "absolute",
                      height: "100%",
                      width: "100%",
                      top: "0",
                      left: "0",
                      padding: expandMapView ? "spacing-xs" : 0,
                      "& div[class$='RideDetailsMap']": {
                        backgroundColor: "transparent",
                      },
                      "& div[class$='RideDetailsMap'] > div": {
                        borderRadius: "12px",
                      },
                    }}
                  >
                    <div
                      sx={{
                        position: "relative",
                        height: "100%",
                      }}
                    >
                      <IconButton
                        icon={
                          expandMapView ? (
                            <ArrowSDownIcon height={22} width={22} />
                          ) : (
                            <ArrowSUpIcon height={22} width={22} />
                          )
                        }
                        onClick={() => {
                          setExpandMapView(!expandMapView);

                          setTimeout(() => {
                            if (!mapContainerRef.current) return;

                            if (!expandMapView)
                              mapContainerRef.current.style.height = "1vh";
                            else mapContainerRef.current.style.height = "75vh";
                          }, 0);
                        }}
                        sx={{
                          position: "absolute",
                          right: "48px",
                          top: "48px",
                          height: "32px",
                          width: "32px",
                          padding: 0,
                          borderRadius: "12px",
                          backgroundColor: "white",
                          boxShadow: "0px 0px 1px #C7C7C7,0px 3px 16px #DADADA",
                          zIndex: 1,
                        }}
                      />
                      <RideDetailsMap ride={rideDetails} />
                    </div>
                  </div>
                </div>
              </TabWrapper>
            </TabsWrapper>

            <UnassignRideCaptain
              showUnassignRideCaptainPopup={showUnassignRideCaptainPopup}
              setShowUnassignRideCaptainPopup={setShowUnassignRideCaptainPopup}
              onUnassignSubmit={handleUnassignSubmit}
              rideData={unassignCaptainRideData}
            />

            <Drawer
              isOpen={isAssignCaptainDrawerOpen}
              closeDrawer={() => setAssignCaptainDrawerOpen(false)}
              size="large"
            >
              <UpdateRideDrawer
                setUpdateRideDrawerOpen={setAssignCaptainDrawerOpen}
                updateRideData={assignReassignData}
                onUpdateRideSubmit={handleAssignCaptainSubmit}
                isUpdateRideLoading={isAssignCaptainLoading}
              />
            </Drawer>
          </div>
        </Fragment>
      ) : null}
    </div>
  );
};

export default RideDetails;
