import * as React from "react";

const InstructionsIcons = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.0001 8.66667V6.66667C13.0001 4.46678 13.0001 3.36683 12.3167 2.68342C11.6332 2 10.5333 2 8.33341 2H6.33341C4.13353 2 3.03358 2 2.35017 2.68342C1.66675 3.36683 1.66675 4.46678 1.66675 6.66667V10C1.66675 12.1999 1.66675 13.2998 2.35017 13.9833C3.03358 14.6667 4.13353 14.6667 6.33341 14.6667H7.33341"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6667 1.33331V2.66665M7.33333 1.33331V2.66665M4 1.33331V2.66665"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 13.3334C9 13.3334 9.66667 13.3334 10.3333 14.6667C10.3333 14.6667 12.451 11.3334 14.3333 10.6667"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.66675 10H7.33341M4.66675 6.66669H10.0001"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default InstructionsIcons;
