import * as React from "react";

const CaptainIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.03995 4.38C2.99459 4.58414 2.96451 4.79138 2.94995 5C2.94995 6.06087 3.37138 7.07828 4.12152 7.82843C4.87167 8.57857 5.88909 9 6.94995 9C8.01082 9 9.02823 8.57857 9.77838 7.82843C10.5285 7.07828 10.95 6.06087 10.95 5C10.9652 4.79361 10.9652 4.58639 10.95 4.38C12.22 3.92 13.01 3.25 13.01 2.5C13.01 1.12 10.31 0 7.00995 0C3.70995 0 0.949951 1.12 0.949951 2.5C0.949951 3.25 1.77995 3.92 3.03995 4.38ZM7.03995 7C6.50952 7 6.00081 6.78929 5.62574 6.41421C5.25066 6.03914 5.03995 5.53043 5.03995 5C5.03458 4.95349 5.03458 4.90651 5.03995 4.86C5.70238 4.95471 6.37078 5.0015 7.03995 5C7.7091 5.00099 8.37747 4.9542 9.03995 4.86C9.04532 4.90651 9.04532 4.95349 9.03995 5C9.04 5.26604 8.98698 5.52942 8.88398 5.77472C8.78097 6.02002 8.63007 6.2423 8.44009 6.42855C8.25012 6.6148 8.02489 6.76127 7.77761 6.8594C7.53032 6.95752 7.26594 7.00532 6.99995 7H7.03995ZM7.03995 2C8.08251 1.98585 9.12113 2.13085 10.12 2.43L10.2 2.54C9.16619 2.86979 8.08475 3.02525 6.99995 3C5.92863 3.01921 4.86133 2.86384 3.83995 2.54L3.94995 2.43C4.93831 2.13001 5.96718 1.98496 6.99995 2H7.03995ZM7 11C5.14348 11 3.36301 11.7375 2.05025 13.0503C0.737498 14.363 0 16.1435 0 18H1.95C1.95 16.6739 2.47678 15.4021 3.41447 14.4645C4.35215 13.5268 5.62392 13 6.95 13C8.27608 13 9.54785 13.5268 10.4855 14.4645C11.4232 15.4021 11.95 16.6739 11.95 18H13.95C13.95 16.1521 13.2194 14.3792 11.9175 13.0678C10.6155 11.7565 8.84784 11.0132 7 11ZM6.15997 16H7.82997C8.09519 16 8.34954 16.1054 8.53708 16.2929C8.72462 16.4804 8.82997 16.7348 8.82997 17C8.82997 17.2652 8.72462 17.5196 8.53708 17.7071C8.34954 17.8946 8.09519 18 7.82997 18H6.15997C5.89476 18 5.6404 17.8946 5.45287 17.7071C5.26533 17.5196 5.15997 17.2652 5.15997 17C5.15997 16.7348 5.26533 16.4804 5.45287 16.2929C5.6404 16.1054 5.89476 16 6.15997 16Z"
        fill="black"
      />
    </svg>
  );
};

export default CaptainIcon;
