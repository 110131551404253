import { createLocalStorageStateHook } from "use-local-storage-state";

type EnglishLang = {
  code: "en";
  direction: "ltr";
  displayName: "En";
  name: "English";
};

type ArabicLang = {
  code: "ar";
  direction: "rtl";
  displayName: "Ar";
  name: "العربية";
};

export type Lang = EnglishLang | ArabicLang;

type ExtendedLang = {
  updatedBeforeReload?: boolean;
};

const useLang = createLocalStorageStateHook<Lang & ExtendedLang>("lang", {
  code: "en",
  direction: "ltr",
  displayName: "En",
  name: "English",
  updatedBeforeReload: false,
});

export const localizationLanguages: Lang[] = [
  {
    code: "en",
    direction: "ltr",
    displayName: "En",
    name: "English",
  },
  {
    code: "ar",
    direction: "rtl",
    displayName: "Ar",
    name: "العربية",
  },
];

export default useLang;
