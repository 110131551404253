/** @jsxImportSource theme-ui */
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import startCase from "lodash/startCase";
import { Column, TableState, ActionType } from "react-table";
import GA from "react-ga";
import Table from "@swvl/table";
import Tag from "@swvl/tag";

import useLang from "hooks/useLang";

import {
  tablePageSizeOptions as pageSizeOptions,
  truncateDecimalPoints,
} from "utils";
import { Captain } from "utils/types";
import {
  ListCaptainParams,
  ListCaptainResponse,
} from "resources/captain-list/useListCaptain";
import { FilterAction } from "hooks/useFilters";
import trackEvents from "constants/trackEvents";
import { STATUS_LABEL, STATUS_COLOR, DOCUMENT_STATUS_COLOR } from "./constants";
import NoData from "components/NoData";
import Avatar from "@swvl/avatar";
import AvatarDefault from "assets/avatar-default.png";
import StarRating from "./StarRating";

const events = trackEvents.getCaptainEvents();

type ListCaptainProps = {
  captains?: ListCaptainResponse | undefined;
  isLoading?: boolean;
  filters: ListCaptainParams;
  dispatchFilters: React.Dispatch<FilterAction<ListCaptainParams>>;
  onRowClick: (captain: Captain | undefined) => void;
};

let lastTableActionType: string;

const CaptainListing = ({
  captains,
  isLoading,
  filters,
  dispatchFilters,
  onRowClick,
}: ListCaptainProps) => {
  const [lang] = useLang();
  const { t } = useTranslation("captains");

  const getRowProps = useCallback((props, original: Captain | undefined) => {
    return {
      ...props,
      onClick: (e: MouseEvent) => {
        e.preventDefault();
        onRowClick(original);
      },
      sx: {
        cursor: "pointer",
      },
    };
  }, []);

  const tableStateReducer = (
    state: TableState<ListCaptainResponse>,
    { type }: ActionType
  ) => {
    let newState;

    switch (type) {
      case "resetHiddenColumns":
        if (
          lastTableActionType !== "gotoPage" &&
          lastTableActionType !== "setPageSize"
        ) {
          newState = {
            ...state,
            pageIndex: filters.page - 1,
            pageSize: filters.limit,
          };
        }
        break;
    }
    if (!newState) newState = state;

    lastTableActionType = type;
    return newState;
  };

  const columns: Column<Captain>[] = [
    {
      Header: `${t("captain")}`,
      accessor: "name",
      id: "captain-name",
      Cell: ({ cell }) => (
        <div
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 12,
            objectFit: "cover",
          }}
        >
          <Avatar
            src={cell.row.original.picture || AvatarDefault}
            size="32px"
            sx={{
              img: {
                objectFit: "cover",
              },
            }}
          />
          {cell.value}
        </div>
      ),
    },
    {
      Header: `${t("captain-phone")}`,
      accessor: "phone",
      id: "captain-phone",
      Cell: ({ value }) => value,
    },
    {
      Header: `${t("captain-rating")}`,
      accessor: "score",
      id: "captain-rating",
      Cell: ({ value }) => (
        <div sx={{ display: "flex", gap: 12 }}>
          <StarRating rating={value} />
          {value === 0 ? (
            <span>{t("captain-unrated")}</span>
          ) : (
            truncateDecimalPoints(value)
          )}
        </div>
      ),
    },
    {
      Header: `${t("captain-documents-status")}`,
      accessor: "document_verification_status",
      id: "verification-status",
      Cell: ({ value }) => (
        <Tag variant={DOCUMENT_STATUS_COLOR[value]}>
          {t(`document-verification-statuses.${value}`)}
        </Tag>
      ),
    },
    {
      Header: `${t("captain-status")}`,
      accessor: "active",
      id: "captain-status",
      Cell: ({ value }) => (
        <Tag
          variant={value ? STATUS_COLOR["active"] : STATUS_COLOR["inactive"]}
        >
          {startCase(
            t(value ? STATUS_LABEL["active"] : STATUS_LABEL["inactive"])
          )}
        </Tag>
      ),
    },
  ];

  return (
    <Table
      data={captains?.hits || []}
      columns={columns}
      noDataComponent={<NoData title={t("no-data-title", { ns: "common" })} />}
      fullWidth={true}
      sameSizeCells={false}
      locale={t("table-locales", { ns: "common", returnObjects: true })}
      direction={lang.direction}
      enablePagination={true}
      isLoading={isLoading}
      getRowProps={getRowProps}
      stateReducer={tableStateReducer}
      paginationConfig={{
        onPageChange: (page) => {
          dispatchFilters({ type: "UPDATE", filters: { page: page + 1 } });
          GA.event({
            ...events.actionChangePage,
            label: JSON.stringify({ page_number: page + 1 }),
          });
        },
        onPageSizeChange: (limit) => {
          if (limit !== filters.limit) {
            dispatchFilters({ type: "UPDATE", filters: { limit, page: 1 } });
            GA.event({
              ...events.actionChangeRowsPerPage,
              label: JSON.stringify({ page_limit: limit }),
            });
          }
        },
        initialPageSize: filters.limit,
        initialPageIndex: filters.page - 1,
        manualPagination: true,
        totalCount: captains?.total || 0,
        pageSizeOptions,
      }}
    />
  );
};

export default CaptainListing;
