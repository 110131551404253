import * as React from "react";

const ExternalLinkIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.40033 2.00146C4.96774 2.00584 3.6939 2.06556 2.87982 2.87962C2.0013 3.75812 2.0013 5.17205 2.0013 7.99988C2.0013 10.8277 2.0013 12.2417 2.87982 13.1201C3.75835 13.9987 5.17232 13.9987 8.00026 13.9987C10.8282 13.9987 12.2421 13.9987 13.1207 13.1201C13.9347 12.3061 13.9945 11.0323 13.9989 8.59981"
        stroke="#505050"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6539 2.34493L9.9544 6.03425M13.6539 2.34493C13.3246 2.01525 11.1062 2.04598 10.6372 2.05265M13.6539 2.34493C13.9832 2.6746 13.9525 4.89544 13.9459 5.36495"
        stroke="#505050"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ExternalLinkIcon;
