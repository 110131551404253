/** @jsxImportSource theme-ui */
import React, { useCallback, Fragment, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Column, TableState, ActionType, SortingRule } from "react-table";
import { useTranslation } from "react-i18next";
import { Flex } from "theme-ui";
import GA from "react-ga";
import { format } from "date-fns";
import startCase from "lodash/startCase";
import noop from "lodash/noop";

import Tag from "@swvl/tag";
import Table from "@swvl/table";
import { IconButton } from "@swvl/button";
import { Popover } from "@swvl/popover";
import {
  CaptainDeleteIcon,
  RideUpdateIcon,
  MoreVerticalIcon,
  EyeIcon,
} from "@swvl/icons";

import {
  Ride,
  ListRideResponse,
  ListRideData,
} from "resources/rides-list/useListRides";
import useLang from "hooks/useLang";

import { tablePageSizeOptions as pageSizeOptions } from "utils";
import { Captain } from "utils/types";
import { timeFormat24, dateFormat } from "constants/index";
import trackEvents from "constants/trackEvents";

import { UpdateRideProps } from "./types";
import { FilterAction } from "hooks/useFilters";
import NoData from "components/NoData";
import {
  statusColor,
  rideStatusLabels,
  popoverContainerStyles,
} from "./constants";
import ClickableCell from "shared/components/ClickableCell";

const events = trackEvents.getRideEvents();

type ListRidesProps = {
  rides?: ListRideResponse;
  isLoading: boolean;
  filters: ListRideData;
  dispatchFilters: React.Dispatch<FilterAction<ListRideData>>;
  onReassignCaptain: (params: UpdateRideProps) => void;
  onAssignCaptain: (params: UpdateRideProps) => void;
  onUnassignCaptain: (captain: Captain, rideId: string) => void;
  onUnassignRide: (rideId: string) => void;
  onRegisterDownloadData: (callback: () => void) => void;
};

let lastTableActionType: string;

const RidesListing = ({
  rides,
  isLoading,
  filters,
  dispatchFilters,
  onRegisterDownloadData = noop,
  onReassignCaptain,
  onAssignCaptain,
  onUnassignCaptain,
  onUnassignRide,
}: ListRidesProps) => {
  const [lang] = useLang();
  const { t } = useTranslation("rides");
  const navigate = useNavigate();

  const renderCaptainActions = ({
    captain,
    _id: rideId,
    bus_type,
    bus,
    route: { name: routeName },
    origin_district,
    destination_district,
    ride_category,
    status,
  }: Ride) => {
    if (status !== "scheduled") return null;

    const allow_bus_type_update = ride_category.features.find(
      (item) => item.name === "allow_bus_type_update"
    );
    if (captain) {
      return (
        <Fragment>
          <Flex
            sx={{
              cursor: "pointer",
            }}
            onClick={() => {
              onReassignCaptain({
                rideId,
                routeName,
                bus,
                busType: bus_type,
                captain,
                origin_district: origin_district.name,
                destination_district: destination_district.name,
                allow_bus_type_update:
                  (allow_bus_type_update?.value as boolean) || false,
              });
            }}
          >
            <IconButton
              sx={{
                marginInlineEnd: "spacing-xxs",
              }}
              icon={<RideUpdateIcon />}
            />
            <span>{t("update-ride.title")}</span>
          </Flex>
          <Flex
            sx={{
              cursor: "pointer",
            }}
            onClick={() => {
              onUnassignCaptain(captain, rideId);
            }}
          >
            <IconButton
              sx={{
                marginInlineEnd: "spacing-xxs",
              }}
              icon={<CaptainDeleteIcon />}
            />
            <span>{t("unassign-captain.title")}</span>
          </Flex>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Flex
            sx={{
              cursor: "pointer",
            }}
            onClick={() => {
              onAssignCaptain({
                rideId,
                routeName,
                busType: bus_type,
                bus,
                captain,
                origin_district: origin_district.name,
                destination_district: destination_district.name,
                allow_bus_type_update:
                  (allow_bus_type_update?.value as boolean) || false,
              });
            }}
          >
            <IconButton
              sx={{
                marginInlineEnd: "spacing-xxs",
              }}
              icon={<RideUpdateIcon />}
            />
            <span>{t("update-ride.title")}</span>
          </Flex>
          <Flex
            sx={{
              cursor: "pointer",
            }}
            onClick={() => {
              onUnassignRide(rideId);
            }}
          >
            <IconButton
              sx={{
                marginInlineEnd: "spacing-xxs",
              }}
              icon={<CaptainDeleteIcon />}
            />
            <span>{t("unassign-ride.title")}</span>
          </Flex>
        </Fragment>
      );
    }
  };

  const columns: Column<Ride>[] = [
    {
      Header: `${t("date")}`,
      accessor: "date",
      id: "date",
      Cell: ({
        value,
        row: {
          original: { _id },
        },
      }) => ClickableCell(_id, () => format(new Date(value), dateFormat)),
    },
    {
      Header: `${t("time")}`,
      accessor: "date",
      id: "route-time",
      Cell: ({
        value,
        row: {
          original: { _id },
        },
      }) => ClickableCell(_id, () => format(new Date(value), timeFormat24)),
    },
    {
      Header: `${t("origin")}`,
      accessor: "origin_district",
      id: "origin-district",
      minWidth: 180,
      Cell: ({
        value,
        row: {
          original: { _id },
        },
      }) => ClickableCell(_id, () => (value ? value.name : "-")),
    },
    {
      Header: `${t("destination")}`,
      accessor: "destination_district",
      id: "destination-district",
      minWidth: 180,
      Cell: ({
        value,
        row: {
          original: { _id },
        },
      }) => ClickableCell(_id, () => (value ? value.name : "-")),
    },
    {
      Header: `${t("predicted-end-time")}`,
      accessor: "predicted_end_date",
      id: "predicted-end-date",
      Cell: ({
        value,
        row: {
          original: { _id },
        },
      }) => ClickableCell(_id, () => format(new Date(value), timeFormat24)),
    },
    {
      Header: `${t("corporate")}`,
      accessor: "corporates",
      id: "corporates",
      Cell: ({
        value,
        row: {
          original: { _id },
        },
      }) => ClickableCell(_id, () => (value ? value[0]?.name || "-" : "-")),
    },
    {
      Header: `${t("route-distance")}`,
      accessor: "route_distance",
      Cell: ({
        row: {
          original: { _id, route_distance },
        },
      }: {
        row: { original: Ride };
      }) => {
        return route_distance
          ? ClickableCell(
              _id,
              () =>
                `${Math.round(route_distance / 1000)} ${t(
                  "ride-distance-unit"
                )}`
            )
          : ClickableCell(_id, () => "-");
      },
    },
    {
      Header: `${t("vehicle-type")}`,
      accessor: "bus_type",
      id: "bus_type",
      Cell: ({
        value,
        row: {
          original: { _id },
        },
      }) => ClickableCell(_id, () => value?.name || "-"),
    },
    {
      Header: `${t("bus-plate")}`,
      accessor: "bus",
      id: "bus-plate",
      Cell: ({
        value,
        row: {
          original: { _id },
        },
      }) => ClickableCell(_id, () => value?.plates || "-"),
    },
    {
      Header: `${t("status")}`,
      accessor: "status",
      id: "status",
      Cell: ({
        value,
        row: {
          original: { _id },
        },
      }) => {
        return ClickableCell(_id, () => (
          <Tag
            variant={statusColor[value] || statusColor["default"]}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {startCase(t(rideStatusLabels[value]))}
          </Tag>
        ));
      },
    },
    {
      Header: `${t("captain")}`,
      accessor: "captain",
      id: "captain",
      Cell: ({
        value,
        row: {
          original: { _id },
        },
      }) => ClickableCell(_id, () => value?.name || "-"),
    },
    {
      Header: `${t("captain-response")}`,
      accessor: "confirmed",
      Cell: ({
        row: {
          original: { captain, confirmed, _id },
        },
      }: {
        row: { original: Ride };
      }) => {
        return captain
          ? ClickableCell(_id, () => (
              <Tag
                variant={confirmed ? "success-light" : "negative-light"}
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {String(confirmed) === "true"
                  ? t("accepted")
                  : t("not-accepted")}
              </Tag>
            ))
          : ClickableCell(_id, () => "-");
      },
    },
    {
      Header: `${t("ride-category")}`,
      accessor: "ride_category",
      Cell: ({
        row: {
          original: { _id, ride_category },
        },
      }: {
        row: { original: Ride };
      }) => {
        return ClickableCell(_id, () => (
          <p
            sx={{
              margin: 0,
              minWidth: 155,
            }}
          >
            {startCase(
              `${ride_category?.ride_type} ${ride_category?.service_type}`
            )}
          </p>
        ));
      },
    },
    {
      Header: `${t("actions")}`,
      id: "actions",
      Cell: ({ row: { original } }: { row: { original: Ride } }) => {
        const { _id: rideId } = original;
        return (
          <Flex sx={{ width: "65px" }}>
            <Popover
              placement={"bottom-start"}
              containerStyles={popoverContainerStyles}
              style={{ zIndex: 3 }}
            >
              <Popover.Trigger>
                <span>
                  <IconButton
                    type="button"
                    data-test-name="popover-trigger"
                    icon={<MoreVerticalIcon />}
                  />
                </span>
              </Popover.Trigger>
              <Popover.Content>
                <Flex
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(`${location.pathname}/${rideId}`);
                  }}
                >
                  <IconButton type="button" icon={<EyeIcon />} />
                  <span>{t("view")}</span>
                </Flex>
                {renderCaptainActions(original)}
              </Popover.Content>
            </Popover>
          </Flex>
        );
      },
    },
  ];

  const getRowProps = useCallback((props) => {
    return {
      ...props,
      sx: {
        cursor: "pointer",
      },
    };
  }, []);

  const mapRidesToDownloadData = useCallback((rides?: Ride[]) => {
    if (!rides) return [];

    return rides
      .map(
        ({
          _id,
          date,
          status,
          bus_type,
          bus,
          captain,
          captain_price,
          confirmed,
          origin_district,
          destination_district,
          corporates,
          ride_category,
          route_distance,
          predicted_end_date,
          bookings,
          currency,
        }) => {
          const captain_response =
            String(confirmed) === "true" ? t("accepted") : t("not-accepted");

          const endDate = predicted_end_date && new Date(predicted_end_date);
          const routeDate = date && new Date(date);
          const _ride = {
            ride_id: _id,
            ride_date: routeDate ? routeDate.toUTCString() : "",
            route_time: routeDate ? format(routeDate, timeFormat24) : "",
            origin_district: origin_district?.name || "",
            destination_district: destination_district?.name || "",
            predicted_end_time: endDate ? format(endDate, timeFormat24) : "",
            corporate_name: corporates ? corporates[0]?.name || "" : "",
            route_distance: `${Math.round(route_distance / 1000)} ${t(
              "ride-distance-unit"
            )}`,
            bus_type: bus_type?.name || "",
            bus_plate: bus?.plates || "",
            status: t(rideStatusLabels[status]),
            captain_name: captain?.name || "",
            captain_response: captain_response || "",
            ride_category: ride_category
              ? ride_category.ride_type + " " + ride_category.service_type
              : "",
            captain_phone_number: captain?.phone || "",
            captain_price: `${Math.round((captain_price || 0) / 100)} ${
              currency || ""
            }`,
            capacity: bookings?.maximum || "",
            bookings: bookings?.count || "",
          };

          return _ride;
        }
      )
      .flat();
  }, []);

  const downloadData = useMemo(
    () => mapRidesToDownloadData(rides?.hits),
    [rides?.hits]
  );

  const sortWithRouteTime = (
    routeTimeRule: SortingRule<Record<string, unknown>>
  ) => {
    dispatchFilters({
      type: "UPDATE",
      filters: {
        sort: `${routeTimeRule.desc ? "-" : ""}date`,
      },
    });

    sendGASortEvent(`${routeTimeRule.desc ? "-" : ""}date`);
  };

  const sortWithPredictedEndDate = (
    sortRule: SortingRule<Record<string, unknown>>
  ) => {
    dispatchFilters({
      type: "UPDATE",
      filters: {
        sort: `${sortRule.desc ? "-" : ""}predicted_end_date`,
      },
    });

    sendGASortEvent(`${sortRule.desc ? "-" : ""}predicted_end_date`);
  };

  const removeSortFilter = () => {
    dispatchFilters({
      type: "UPDATE",
      filters: {
        sort: undefined,
      },
    });

    sendGASortEvent(); // no value is undefined
  };

  const sendGASortEvent = (sortValue?: string) => {
    GA.event({
      ...events.actionFilterSort,
      label: JSON.stringify({ sort: sortValue }),
    });
  };

  const getCurrentSortingRule = (
    sortingRules: SortingRule<Record<string, unknown>>[],
    ruleId: string
  ) => {
    return sortingRules?.find((rule) => rule.id === ruleId);
  };

  const sortWithColumn = (
    sortingRules: SortingRule<Record<string, unknown>>[]
  ) => {
    const routeTimeRule = getCurrentSortingRule(sortingRules, "route-time");
    if (routeTimeRule) {
      sortWithRouteTime(routeTimeRule);
      return;
    }

    const predictedEndTimeRule = getCurrentSortingRule(
      sortingRules,
      "predicted-end-date"
    );
    if (predictedEndTimeRule) {
      sortWithPredictedEndDate(predictedEndTimeRule);
      return;
    }

    removeSortFilter();
  };

  const tableStateReducer = (
    state: TableState<ListRideResponse>,
    { type }: ActionType
  ) => {
    let newState;

    switch (type) {
      case "resetHiddenColumns":
        if (
          lastTableActionType !== "gotoPage" &&
          lastTableActionType !== "setPageSize"
        ) {
          newState = {
            ...state,
            pageIndex: filters.page - 1,
            pageSize: filters.limit,
          };
        }
        break;
    }
    if (!newState) newState = state;

    lastTableActionType = type;
    return newState;
  };

  return (
    <Table
      data={rides?.hits || []}
      columns={columns.map((column) => {
        if (column.id === "route-time" || column.id === "predicted-end-date")
          return column;
        else if (column.id === "actions") {
          return {
            ...column,
            width: "65px",
            fixed: "end",
          };
        } else
          return {
            ...column,
            disableSortBy: true,
          };
      })}
      noDataComponent={<NoData title={t("no-data-title", { ns: "common" })} />}
      fullWidth={true}
      sameSizeCells={false}
      locale={t("table-locales", { ns: "common", returnObjects: true })}
      direction={lang.direction}
      enablePagination={true}
      enableSorting={true}
      sortingConfig={{
        initialSortBy: [
          {
            id: "route-time",
            desc: false,
          },
        ],
        onSortingChange: sortWithColumn,
        manualSorting: true,
      }}
      isLoading={isLoading}
      getRowProps={getRowProps}
      stateReducer={tableStateReducer}
      paginationConfig={{
        onPageChange: (page) => {
          dispatchFilters({ type: "UPDATE", filters: { page: page + 1 } });

          GA.event({
            ...events.actionChangePage,
            label: JSON.stringify({ page_number: page + 1 }),
          });
        },
        onPageSizeChange: (limit) => {
          if (limit !== filters.limit) {
            dispatchFilters({ type: "UPDATE", filters: { limit, page: 1 } });

            GA.event({
              ...events.actionChangeRowsPerPage,
              label: JSON.stringify({ page_limit: limit }),
            });
          }
        },
        initialPageSize: filters.limit,
        initialPageIndex: filters.page - 1,
        manualPagination: true,
        totalCount: rides?.total || 0,
        pageSizeOptions,
      }}
      downloadConfig={{
        popupHeader: t("download-data.popup-header"),
        popupContent: t("download-data.popup-content"),
        confirmText: t("download-data.confirm-text"),
        cancelText: t("download-data.cancel-text"),
        data: downloadData,
        registerCallback: onRegisterDownloadData,
      }}
    />
  );
};

export default RidesListing;
