/** @jsxImportSource theme-ui */
import { Button } from "@swvl/button";

const ToolbarButton = ({
  title,
  icon,
  onClick,
}: {
  title: string;
  icon: JSX.Element;
  onClick: React.MouseEventHandler;
}) => {
  return (
    <Button
      iconPosition="start"
      variant="tertiary"
      icon={icon}
      onClick={onClick}
      sx={{
        height: "40px",
        paddingInlineStart: "12px",
        paddingInlineEnd: "spacing-s",
        border: "none",
        outlineWidth: "1px",
        outlineStyle: "solid",
        outlineColor: "border",
        "& > span": {
          variant: "text.p-small-medium",
        },
        "&:hover": {
          outlineWidth: "2px",
          outlineStyle: "solid",
          outlineColor: "secondary",
        },
      }}
    >
      {title}
    </Button>
  );
};

export default ToolbarButton;
