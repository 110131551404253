import { useQuery } from "react-query";
import GA from "react-ga";

import apiInstance from "api";
import trackEvents from "constants/trackEvents";
import { useAuthContext } from "contexts/authContext";
import { Captain } from "utils/types";

export type ListCaptainParams = {
  page: number;
  limit?: number;
  status?: string;
  document_verification_status?: string[];
  score?: string[];
  captain?: string[];
};

export type ListCaptainResponse = {
  total: number;
  page: number;
  limit: number;
  hits: Captain[];
};

export interface ListCaptainFilters {
  document_verification_status?: string[];
  status?: string;
  page?: number;
  limit?: number;
  score?: string[];
  captain?: string[];
}
// Transforms
const transformResponse = ({
  total,
  page,
  limit,
  hits,
}: ListCaptainResponse) => {
  return { total, page, limit, hits };
};

// Resources
const getListCaptains = async (params: ListCaptainParams) => {
  const { status, ...rest } = params;
  const response = apiInstance.get<unknown, ListCaptainResponse>("/captain", {
    params: {
      ...rest,
      ...(!status || status === "all" ? {} : { active: status === "active" }),
    },
  });
  return transformResponse(await response);
};

const useListCaptains = (params: ListCaptainParams) => {
  const events = trackEvents.getCaptainEvents();
  const { dynamicKeys } = useAuthContext();

  return useQuery(
    ["captain-list", params, dynamicKeys],
    () => getListCaptains(params),
    {
      onSuccess: () => GA.event(events.statusSearchSuccessfully),
    }
  );
};

export { useListCaptains };
