import { useQuery } from "react-query";

import apiInstance from "api";

import { useAuthContext } from "contexts/authContext";
import { BusType, Captain } from "utils/types";

// Types
export type ListVehicleData = {
  captain_id?: string;
  bus_id?: string;
  status?: string;
  document_verification_status?: string[];
  page: number;
  limit?: number;
};

export type ListVehicleResponse = {
  total: number;
  hits: Vehicle[];
};

export type Vehicle = {
  _id: string;
  plates: string;
  type: BusType;
  captain: Captain;
  owner_type: string;
  company: {
    _id: string;
    name: string;
    name_ar?: string;
    picture?: string;
  };
  document_verification_status: string;
  active: boolean;
};

// Resources
const getListVehicles = async (params: ListVehicleData) => {
  const { status, ...rest } = params;
  return await apiInstance.get<ListVehicleData, ListVehicleResponse>("/bus", {
    params: {
      ...rest,
      ...(status ? { active: status === "active" ? true : false } : {}),
    },
  });
};

const useListVehicles = (params: ListVehicleData) => {
  const { dynamicKeys } = useAuthContext();
  return useQuery(["vehicle-list", params, dynamicKeys], () =>
    getListVehicles(params)
  );
};

export { useListVehicles };
