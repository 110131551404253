import * as React from "react";

const ReviewIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_135_5071"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <rect width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_135_5071)">
        <path
          d="M8.00008 8.21413L9.18975 8.93579C9.29919 9.00502 9.40864 9.00118 9.51808 8.92429C9.62742 8.8474 9.66758 8.74313 9.63858 8.61146L9.32442 7.25763L10.3809 6.34479C10.4835 6.25335 10.5146 6.14568 10.4744 6.02179C10.4343 5.89779 10.345 5.83024 10.2064 5.81913L8.81925 5.70246L8.27442 4.42563C8.22319 4.30507 8.13175 4.24479 8.00008 4.24479C7.86841 4.24479 7.77697 4.30507 7.72575 4.42563L7.18092 5.70246L5.79375 5.81913C5.65519 5.83024 5.56586 5.89779 5.52575 6.02179C5.48553 6.14568 5.51669 6.25335 5.61925 6.34479L6.67575 7.25763L6.36158 8.61146C6.33258 8.74313 6.37275 8.8474 6.48208 8.92429C6.59153 9.00118 6.70097 9.00502 6.81041 8.93579L8.00008 8.21413ZM4.02575 11.6666L2.69108 13.0011C2.50141 13.1909 2.28325 13.2339 2.03658 13.1301C1.79003 13.0262 1.66675 12.8392 1.66675 12.5691V2.87179C1.66675 2.53501 1.78341 2.24996 2.01675 2.01663C2.25008 1.78329 2.53514 1.66663 2.87191 1.66663H13.1282C13.465 1.66663 13.7501 1.78329 13.9834 2.01663C14.2167 2.24996 14.3334 2.53501 14.3334 2.87179V10.4615C14.3334 10.7982 14.2167 11.0833 13.9834 11.3166C13.7501 11.55 13.465 11.6666 13.1282 11.6666H4.02575ZM3.60008 10.6666H13.1282C13.1796 10.6666 13.2266 10.6452 13.2692 10.6025C13.312 10.5598 13.3334 10.5128 13.3334 10.4615V2.87179C13.3334 2.82046 13.312 2.77346 13.2692 2.73079C13.2266 2.68801 13.1796 2.66663 13.1282 2.66663H2.87191C2.82058 2.66663 2.77358 2.68801 2.73091 2.73079C2.68814 2.77346 2.66675 2.82046 2.66675 2.87179V11.5898L3.60008 10.6666Z"
          fill="#1C1B1F"
        />
      </g>
    </svg>
  );
};

export default ReviewIcon;
