import apiInstance from "api";
import { AxiosResponse } from "axios";
import { showAlert } from "@swvl/alert";
import { useMutation } from "react-query";
import GA from "react-ga";

import trackEvents from "constants/trackEvents";

export type AssignCaptainRequest = {
  ride_id: string;
  captain_id: string;
  bus_id: string;
};

interface AssignCaptainResponse {
  message: string;
}

const events = trackEvents.getRideEvents();
const assignCaptain = async ({
  ride_id,
  captain_id,
  bus_id,
}: AssignCaptainRequest) => {
  return await apiInstance
    .post<AssignCaptainRequest, AssignCaptainResponse>(
      `/ride/${ride_id}/assign_captain`,
      { captain_id, bus_id }
    )
    .then((response: AssignCaptainResponse) => {
      GA.event({
        ...events.statusAssignSuccessfully,
        label: JSON.stringify({ ride_id, captain_id, bus_id }),
      });
      return response;
    });
};

const useAssignCaptain = () => {
  const mutation = useMutation(assignCaptain, {
    mutationKey: `/ride/assign_captain`,
    onSuccess: ({ message }) => {
      showAlert({
        message,
        variant: "success",
        mode: "light",
      });
    },
    onError: (response: AxiosResponse) => {
      if (!response) return;

      GA.event({
        ...events.statusAssignFailed,
        label: response.data.message,
      });
      showAlert({
        message: response.data.message,
        variant: "error",
        mode: "light",
      });
    },
  });

  return mutation;
};

export { useAssignCaptain };
