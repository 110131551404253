import * as React from "react";

const RideIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.4787 7.14332C12.3502 7.26519 12.1785 7.33325 11.9997 7.33325C11.821 7.33325 11.6493 7.26519 11.5208 7.14332C10.3441 6.02105 8.76734 4.76733 9.53634 2.94715C9.95208 1.963 10.9501 1.33325 11.9997 1.33325C13.0493 1.33325 14.0473 1.963 14.4631 2.94715C15.2311 4.76503 13.6582 6.02491 12.4787 7.14332Z"
        stroke="#141B34"
        strokeWidth="1.25"
      />
      <path
        d="M12 4H12.006"
        stroke="#141B34"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.33301 14.6667C4.43758 14.6667 5.33301 13.7713 5.33301 12.6667C5.33301 11.5622 4.43758 10.6667 3.33301 10.6667C2.22844 10.6667 1.33301 11.5622 1.33301 12.6667C1.33301 13.7713 2.22844 14.6667 3.33301 14.6667Z"
        stroke="#141B34"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.33333 4.66675H6.33333C5.04467 4.66675 4 5.56218 4 6.66675C4 7.77135 5.04467 8.66675 6.33333 8.66675H8.33333C9.622 8.66675 10.6667 9.56215 10.6667 10.6667C10.6667 11.7713 9.622 12.6667 8.33333 12.6667H7.33333"
        stroke="#141B34"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RideIcon;
