import { useQuery } from "react-query";
import { AxiosResponse } from "axios";

import apiInstance from "api";

import { useAuthContext } from "contexts/authContext";
import { BusType, Captain } from "utils/types";

// Types
export interface ListVehicleData {
  id: string;
}

export type VehicleDetailResponse = {
  _id: string;
  plates: string;
  year: string;
  color: string;
  type: BusType;
  captain: Captain;
  city: string;
  active: boolean;
  company: {
    _id: string;
    name: string;
    name_ar?: string;
    picture?: string;
  };
  document_verification_status: string;
  owner_type: string;
};

// Resources
const getVehicleDetails = (rideId?: string) => {
  return apiInstance.get<unknown, VehicleDetailResponse>(
    `/bus/${rideId || ""}`
  );
};

const useVehicleDetails = (rideId = "") => {
  const { dynamicKeys } = useAuthContext();

  return useQuery<VehicleDetailResponse, AxiosResponse>(
    ["vehicle-detail", rideId, dynamicKeys],
    () => getVehicleDetails(rideId)
  );
};

export { useVehicleDetails };
