/** @jsxImportSource theme-ui */
import { Fragment, useState } from "react";
import { ErrorMessage as FormikErrorMessage, FieldProps } from "formik";
import Input, { ErrorMessage } from "@swvl/input";

const FieldInputWrapper = ({
  field: { name, value, onChange, onBlur },
  form: { errors, touched },
  ...props
}: FieldProps) => {
  const [isFieldChanged, setFieldChanged] = useState(false);

  return (
    <Fragment>
      <Input
        name={name}
        value={value}
        onChange={(e) => {
          onChange(e);
          if (!isFieldChanged) {
            setFieldChanged(true);
          }
        }}
        onBlur={(e) => isFieldChanged && onBlur(e)}
        error={touched[name] && Boolean(errors[name])}
        success={touched[name] && !errors[name]}
        {...props}
      />
      <FormikErrorMessage name={name}>
        {(msg) => <ErrorMessage>{msg}</ErrorMessage>}
      </FormikErrorMessage>
    </Fragment>
  );
};

export default FieldInputWrapper;
