/** @jsxImportSource theme-ui */
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { HelmetProvider } from "react-helmet-async";
import { AxiosResponse } from "axios";
import { showAlert } from "@swvl/alert";

import "@fontsource/public-sans/index.css";
import "@fontsource/public-sans/500.css";

import AuthContextProvider from "contexts/authContext";
import ThemeControlWrapper from "components/ThemeControlWrapper";
import Main from "pages";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
      onError: (response) => {
        if (!response) return;

        showAlert({
          message: (response as AxiosResponse).data.message,
          id: "query-error-alert",
          variant: "error",
          mode: "light",
        });
      },
    },
    mutations: {
      onError: (response) => {
        if (!response) return;

        showAlert({
          message: (response as AxiosResponse).data.message,
          id: "mutation-error-alert",
          variant: "error",
          mode: "light",
        });
      },
    },
  },
});

function App(): JSX.Element {
  return (
    <AuthContextProvider>
      <QueryClientProvider client={queryClient}>
        <HelmetProvider>
          <ThemeControlWrapper>
            <Router>
              <Main />
            </Router>
          </ThemeControlWrapper>
        </HelmetProvider>
      </QueryClientProvider>
    </AuthContextProvider>
  );
}

export default App;
