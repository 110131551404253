import { useQuery } from "react-query";

import apiInstance from "api";

import { ManagerInfoResponse } from "utils/types";

// Resources
const getManagerInfo = async () => {
  return apiInstance.get<unknown, ManagerInfoResponse>("user/me");
};

const useManagerInfo = (isTokenInterceptorAdded = false) => {
  return useQuery("manager-info", getManagerInfo, {
    enabled: isTokenInterceptorAdded,
    retry: false,
    staleTime: Infinity,
  });
};

export { useManagerInfo };
