export const dateTimeFormat = "d LLL, y-hh:mm aaa";
export const dateTimeFormatCapital = "d LLL, y-hh:mm a";
export const timeFormat = "h:mm aa";
export const timeFormat24 = "HH:mm";
export const dateFormat = "d LLL";
export const dateFormatWithMonthFirst = "LLL d";
export const dateFormatWithYear = "d LLL, yy";
export const slashDateFormat = "LL/dd/yyyy";
export const validEmailRegex = /(?!^).(?=[^@]+@)/g;
export const numbersRegExp = /^[0-9]*$/;
export const arabicNumbersRegExp = /^[\u0660-\u0669]+$/;
