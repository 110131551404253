import apiInstance from "api";
import { AxiosResponse } from "axios";
import { showAlert } from "@swvl/alert";
import { useMutation } from "react-query";
import GA from "react-ga";

import trackEvents from "constants/trackEvents";

export interface UnassignRequestPayload {
  captain_id?: string;
  ride_id?: string;
  remove_mapping?: boolean;
}

interface UnassignCaptainResponse {
  message: string;
}

const events = trackEvents.getRideEvents();
const unassignCaptain = async ({
  captain_id,
  ride_id,
  remove_mapping,
}: UnassignRequestPayload) => {
  return await apiInstance
    .post<UnassignRequestPayload, UnassignCaptainResponse>(
      `/ride/${ride_id}/unassign_captain`,
      { captain_id, remove_mapping }
    )
    .then((response: UnassignCaptainResponse) => {
      GA.event({
        ...events.statusUnassignSuccessfully,
        label: JSON.stringify({ captain_id, ride_id, remove_mapping }),
      });
      return response;
    });
};

const useUnassignCaptain = () => {
  const mutation = useMutation(unassignCaptain, {
    mutationKey: `/ride/unassign_captain`,
    onSuccess: ({ message }) => {
      showAlert({
        message,
        variant: "success",
        mode: "light",
      });
    },
    onError: (response: AxiosResponse) => {
      if (!response) return;

      GA.event({
        ...events.statusUnassignFailed,
        label: response.data.message,
      });
      showAlert({
        message: response.data.message,
        variant: "error",
        mode: "light",
      });
    },
  });

  return mutation;
};

export { useUnassignCaptain };
