/** @jsxImportSource theme-ui */
import { ThemeUIStyleObject } from "theme-ui";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useFormik, FormikProvider, Field, FieldProps } from "formik";
import { string, object } from "yup";
import { Spinner } from "theme-ui";

import { Button, IconButton } from "@swvl/button";
import Drawer from "@swvl/drawer";
import FileUpload, { IFileWithMeta, StatusValue } from "@swvl/file-upload";
import {
  CalendarIcon,
  CloseIcon,
  MinusIcon,
  PlusIcon,
  WarningFilledIcon,
} from "@swvl/icons";
import Input, { ErrorMessage as SwvlUIErrorMessage } from "@swvl/input";
import DatePicker from "@swvl/date-picker";
import theme from "@swvl/theme";
import Tooltip from "@swvl/tooltip";

import InstructionsIcons from "shared/icons/InstructionsIcon";
import RefreshIcon from "shared/icons/RefreshIcon";
import UploadIcon from "shared/icons/UploadIcon";
import WarningIcon from "shared/icons/WarningFilledIcon";
import { Select } from "@swvl/select";
import FieldInputWrapper from "components/FieldInputWrapper";
import { useTranslation } from "react-i18next";
import FileUploadFilledIcon from "shared/icons/FileUploadIcon";
import { VERIFIABLE_TYPES, DOCUMENT_CATEGORIES } from "./constants";
import { maskString } from "./utils";
import Tag from "@swvl/tag";
import {
  VerifiableDocumentDetail,
  DOCUMENT_STATUSES,
  useCreateVerifiableDocument,
  useUpdateVerifiableDocument,
  VerifiableDocumentsResponse,
} from "resources/common/useVerifiableDocument";
import { isEmpty } from "lodash/fp";
import { differenceInDays } from "date-fns";
import {
  DocumentTypesType,
  useCountryConfig,
} from "resources/common/useCountryConfig";

export type MethodValue =
  | "delete"
  | "get"
  | "head"
  | "options"
  | "patch"
  | "post"
  | "put"
  | "DELETE"
  | "GET"
  | "HEAD"
  | "OPTIONS"
  | "PATCH"
  | "POST"
  | "PUT";

export interface IUploadParams {
  url: string;
  method?: MethodValue;
  body?: string | FormData | ArrayBuffer | Blob | File | URLSearchParams;
  fields?: { [name: string]: string | Blob };
  headers?: { [name: string]: string };
  meta?: { [name: string]: unknown };
}

const TAG_VARIANT: {
  [x: string]: "warning-light" | "negative-light" | "success-light";
} = {
  [DOCUMENT_STATUSES.PENDING]: "warning-light",
  [DOCUMENT_STATUSES.EXPIRING_SOON]: "warning-light",
  [DOCUMENT_STATUSES.REJECTED]: "negative-light",
  [DOCUMENT_STATUSES.EXPIRED]: "negative-light",
  [DOCUMENT_STATUSES.VERIFIED]: "success-light",
};

const TODAY = new Date();
const buttonCssReset: ThemeUIStyleObject = {
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  outline: 0,
  p: 0,
  borderColor: "transparent",
  textDecoration: "none",
  boxSizing: "border-box",
  cursor: "pointer",
  userSelect: "none",
  backgroundColor: "#F0F0FE",

  color: "content-primary",
  height: "32px",
  width: "32px",
  transition: "background-color .25s",

  "&:hover": {
    bg: "secondary-light-90",
  },
  "&:active": {
    bg: "secondary-light-80",
  },

  svg: {
    width: 20,
    height: 20,
  },
};

export const UploadVerifiableDocument = (props: {
  verifiableType: keyof typeof VERIFIABLE_TYPES;
  verifiableDocument: DocumentTypesType;
  verifiableName: string;
  documentCategory?: keyof typeof DOCUMENT_CATEGORIES;
  cityId?: string;
  documents?: VerifiableDocumentsResponse;
  refetchDocumentsData: () => void;
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [showFileSizeError, setShowFileSizeError] = useState(false);
  const [showFileFormatError, setShowFileFormatError] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [fileUrl, setFileUrl] = useState<string>("");
  const [dateOfIssue, setDateOfIssue] = useState<Date>();
  const [dateOfExpiry, setDateOfExpiry] = useState<Date>();
  const [zoomLevel, setZoomLevel] = useState<number>(0);
  const [isDocumentExpirable, setIsDocumentExpirable] = useState(true);
  const [selectedDocument, setSelectedDocument] =
    useState<VerifiableDocumentDetail | null>(null);
  const { id: verifiableId } = useParams();
  const { data: countryConfig } = useCountryConfig(props.cityId);

  const document = props.documents && props.documents[props.verifiableDocument];
  const isDocumentUploaded = !isEmpty(document?.documents);
  const isUploadAble = document?.isUploadable;

  const uploadedDocuments = document?.documents
    ? Object.keys(document.documents)
    : [];

  const { t } = useTranslation("captains");
  const { t: tForm } = useTranslation("captains", {
    keyPrefix: "document-verification-form",
  });

  const {
    mutate: saveDocumentVerification,
    isLoading,
    isSuccess,
  } = useCreateVerifiableDocument();

  const {
    mutate: updateDocumentVerification,
    isLoading: isUpdateLoading,
    isSuccess: isUpdateSuccess,
  } = useUpdateVerifiableDocument();

  useEffect(() => {
    if (isSuccess || isUpdateSuccess) {
      setIsDrawerOpen(false);
      setSelectedDocument(null);
      props.refetchDocumentsData();
    }
  }, [isSuccess, isUpdateSuccess]);

  const formSchema = object({
    documentType: object()
      .shape({ label: string(), value: string() })
      .required(tForm("document-type-required")),
    idNumber: string().required(tForm("id-number-required")),
    dateOfIssue: string().required(tForm("date-of-issue-required")),
    dateOfExpiry: isDocumentExpirable
      ? string().required(tForm("date-of-expiry-required"))
      : string(),
    notes: string(),
  });

  const selectedDocumentType = props.verifiableDocument;

  const documentTypeValue = {
    value: selectedDocumentType || "",
    label: t(`document-types.${selectedDocumentType}` || ""),
  };

  const documentTitle = props.documents
    ? props.documents[selectedDocumentType as DocumentTypesType]?.documents?.[
        props.verifiableDocument
      ]?.verifiable?.title || props.verifiableName
    : "";

  const documentName = props.verifiableName
    ? props.verifiableName
    : documentTitle;

  const formik = useFormik({
    initialValues: {
      documentType: documentTypeValue,
      idNumber: "",
      dateOfIssue: "",
      dateOfExpiry: "",
      notes: "",
    },
    validationSchema: formSchema,
    onSubmit: (values) => {
      if (!fileUrl) return;

      if (selectedDocument) {
        updateDocumentVerification({
          documentId: selectedDocument?._id || "",
          documents: selectedFile ? [selectedFile] : [],
          documentNumber: values?.idNumber,
          isExpirable: isDocumentExpirable,
          issuedAt: dateOfIssue ? dateOfIssue.toISOString() : "",
          expiredAt: dateOfExpiry ? dateOfExpiry.toISOString() : "",
          notes: values.notes,
        });
      } else {
        saveDocumentVerification({
          documents: selectedFile ? [selectedFile] : [],
          verifiableType: props.verifiableType,
          verifiableId: verifiableId ? verifiableId : "",
          documentCategory: props.documentCategory
            ? props.documentCategory
            : (DOCUMENT_CATEGORIES.other as keyof typeof DOCUMENT_CATEGORIES),

          documentType: documentTypeValue?.value,
          documentNumber: values?.idNumber,
          isExpirable: isDocumentExpirable,
          issuedAt: dateOfIssue ? dateOfIssue.toISOString() : "",
          expiredAt: dateOfExpiry ? dateOfExpiry.toISOString() : "",
          notes: values.notes,
        });
      }
    },
  });

  const { values, errors } = formik;

  const initializeForm = () => {
    if (selectedDocument) {
      setFileUrl(selectedDocument.documentsPath?.[0] || "");
      setIsDocumentExpirable(selectedDocument?.isExpirable ? true : false);

      formik.setValues({
        ...values,
        idNumber: selectedDocument?.documentNumber || "",
        dateOfIssue: selectedDocument?.issuedAt
          ? new Date(selectedDocument?.issuedAt).toLocaleDateString()
          : "",
        dateOfExpiry: selectedDocument?.expiredAt
          ? new Date(selectedDocument?.expiredAt).toLocaleDateString()
          : "",
        notes: selectedDocument?.notes?.creator || "",
      });

      if (selectedDocument?.issuedAt)
        setDateOfIssue(new Date(selectedDocument?.issuedAt));
      if (selectedDocument?.expiredAt)
        setDateOfExpiry(new Date(selectedDocument?.expiredAt));
    }
  };

  useEffect(() => {
    initializeForm();
  }, [selectedDocument, isDocumentUploaded]);

  const handleChangeStatus = ({ file }: IFileWithMeta, status: StatusValue) => {
    setShowFileFormatError(false);
    setShowFileSizeError(false);

    if (status === "done") {
      const fileUrl = URL.createObjectURL(file);
      setFileUrl(fileUrl);
      setSelectedFile(file);
    } else if (status === "rejected_file_type") {
      setShowFileFormatError(true);
    } else if (status === "rejected_max_files") {
      setShowFileSizeError(true);
    }
  };

  const mandatory =
    countryConfig?.[props.verifiableType as "captain" | "bus"]?.[
      props.verifiableDocument
    ]?.mandatory;

  return (
    <>
      <p sx={{ margin: "auto 0 !important" }}>
        {t(`document-types.${props.verifiableDocument}`)}
        {mandatory ? "*" : ""}
      </p>
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          width: "100%",
        }}
      >
        {isDocumentUploaded && (
          <div sx={{ display: "flex", width: "100%", flexDirection: "column" }}>
            {uploadedDocuments?.length > 0
              ? uploadedDocuments.map((key, index) => {
                  const _doc = document?.documents?.[key];
                  if (!_doc) return null;

                  const today = new Date();
                  const expiryDate = new Date(_doc.expiredAt);
                  const expiryDays = differenceInDays(expiryDate, today);

                  return (
                    <div
                      key={_doc._id}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        marginBottom:
                          uploadedDocuments.length - 1 === index
                            ? 0
                            : "spacing-s",
                      }}
                    >
                      <div sx={{ display: "flex" }}>
                        <span
                          sx={{
                            variant: "text.p-small",
                            color: "content-primary",
                          }}
                        >
                          {maskString(_doc.documentNumber, 4)}
                        </span>
                        {_doc.isExpiringSoon && _doc.isExpirable && (
                          <div
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              marginInlineStart: "spacing-xs",
                            }}
                          >
                            <WarningIcon
                              fill="#DA3B07"
                              sx={{ marginInlineEnd: "spacing-xxs" }}
                            />
                            <span
                              sx={{
                                color: "#DA3B07",
                                variant: "text.p-small",
                              }}
                            >
                              {expiryDays > 0
                                ? (t("document-expiring-soon") || "")
                                    .replace("{days}", expiryDays.toString())
                                    .replace(
                                      "{date}",
                                      expiryDate.toLocaleDateString()
                                    )
                                : (t("expired-on") || "").replace(
                                    "{date}",
                                    expiryDate.toLocaleDateString()
                                  )}
                            </span>
                          </div>
                        )}
                      </div>
                      <div
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <>
                          <div
                            data-for="refreshTooltip"
                            data-tip={t("replace")}
                            data-iscapture="true"
                            sx={{
                              visibility:
                                _doc.status === DOCUMENT_STATUSES.PENDING ||
                                _doc.status === DOCUMENT_STATUSES.REJECTED
                                  ? "visible"
                                  : "hidden",
                            }}
                          >
                            <RefreshIcon
                              sx={{
                                cursor: "pointer",
                                marginInlineEnd: "spacing-s",
                              }}
                              onClick={() => {
                                setIsDrawerOpen(true);
                                setSelectedDocument(_doc);
                                initializeForm();
                              }}
                              data-for="refreshTooltip"
                              data-tip={t("replace")}
                              data-iscapture="true"
                            />
                          </div>
                          <Tooltip
                            id="refreshTooltip"
                            place="top"
                            type="dark"
                            effect="solid"
                          />
                        </>
                        <Tag variant={TAG_VARIANT[_doc.status]}>
                          {t(_doc.status)}
                        </Tag>
                      </div>
                    </div>
                  );
                })
              : []}
          </div>
        )}
        {(!isDocumentUploaded || isUploadAble) && (
          <Button
            variant="tertiary"
            size={"small"}
            icon={<UploadIcon sx={{ marginInlineEnd: "spacing-xs" }} />}
            sx={{
              marginTop: uploadedDocuments.length > 0 ? "spacing-s" : "",
            }}
            onClick={() => {
              setIsDrawerOpen(true);
              setSelectedDocument(null);
            }}
          >
            {t("upload-document")}
          </Button>
        )}
      </div>

      {/* Drawer wrapper */}
      {isDrawerOpen && (
        <div className="drawer-wrapper">
          <Drawer
            closeDrawer={() => {
              setIsDrawerOpen(false);
            }}
            isOpen={isDrawerOpen}
            size={"big"}
            anchor={"right"}
          >
            <div sx={{ overflow: "auto", height: "100vh" }}>
              {/* Header */}
              <div
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  py: `${theme.space["spacing-m"]}`,
                  paddingInlineStart: `${theme.space["spacing-s"]}`,
                  paddingInlineEnd: `${theme.space["spacing-m"]}`,
                  borderBottom: `1px solid ${theme.colors["background-neutral"]}`,
                }}
              >
                <div sx={{ display: "flex", alignItems: "center" }}>
                  <span
                    sx={{
                      variant: "text.h5",
                      color: `${theme.colors["content-primary"]}`,
                    }}
                  >
                    {documentName}
                  </span>
                </div>
                <IconButton
                  icon={<CloseIcon />}
                  onClick={() => {
                    setIsDrawerOpen(false);
                  }}
                />
              </div>
              {/* Body */}
              {/* Rejection Alert */}
              {selectedDocument?.status === DOCUMENT_STATUSES.REJECTED &&
                selectedDocument?.notes?.approver && (
                  <div
                    sx={{
                      mx: "spacing-m",
                      mt: "spacing-s",
                      p: "spacing-s",
                      bg: "negative-light",
                      display: "flex",
                      alignItems: "center",
                      borderRadius: "6px",
                    }}
                  >
                    <WarningFilledIcon />
                    <span
                      sx={{
                        marginInlineStart: "spacing-s",
                        variant: "text.p-small",
                        color: "content-primary",
                      }}
                    >
                      {selectedDocument.notes.approver}
                    </span>
                  </div>
                )}
              <div
                sx={{
                  display: "flex",
                  marginTop: "spacing-m",
                  marginBottom: "spacing-xxxl",
                  paddingInlineStart: "spacing-s",
                  paddingInlineEnd: "spacing-s",
                }}
              >
                {/* Document Uploader */}
                {!fileUrl ? (
                  <div sx={{ flex: 1 }}>
                    <div
                      sx={{
                        '&  div[class$="UploadWrapper"]': {
                          paddingTop: "50px",
                          paddingBottom: "50px",
                        },
                      }}
                    >
                      <FileUpload
                        autoUpload={false}
                        maxSize={5}
                        accept=".jpg, .JPG, .PNG, .jpeg, .pdf, .PDF"
                        formatWarningText="Supports JPG, PNG, JPEG, PDF"
                        inputText={t("document-verification-input-text")}
                        maxFileSizeText={t(
                          "document-verification-file-size-text"
                        )}
                        mbSizeUnit="MB"
                        progress={{ of: "of", kbSizeUnit: "KB" }}
                        fileUploadIcon={<FileUploadFilledIcon />}
                        onChangeStatus={handleChangeStatus}
                      />
                    </div>
                    {showFileFormatError && (
                      <SwvlUIErrorMessage>
                        {t("file-format-error")}
                      </SwvlUIErrorMessage>
                    )}
                    {showFileSizeError && (
                      <SwvlUIErrorMessage>
                        {t("file-size-error")}
                      </SwvlUIErrorMessage>
                    )}
                    {formik.submitCount > 0 && (
                      <SwvlUIErrorMessage>
                        Document file is required
                      </SwvlUIErrorMessage>
                    )}
                    <div
                      sx={{
                        marginTop: "spacing-m",
                        padding: "spacing-s",
                        backgroundColor: "secondary-light-95",
                        color: "content-primary",
                        borderRadius: 16,
                      }}
                    >
                      <div sx={{ display: "flex", alignItems: "center" }}>
                        <InstructionsIcons
                          sx={{ marginInlineEnd: "spacing-s" }}
                        />
                        <span sx={{ variant: "text.p-medium-bold" }}>
                          {t("instructions")}
                        </span>
                      </div>
                      <ul sx={{ marginTop: "spacing-s", listStyle: "inherit" }}>
                        <li
                          sx={{
                            variant: "text.p-x-small",
                            padding: "0 !important",
                            display: "list-item !important",
                            marginBottom: "spacing-xxs",
                          }}
                        >
                          <b>{t("photo-id-instruction-1")}</b>{" "}
                          {t("photo-id-instruction-2")}
                          <ul>
                            <li
                              sx={{
                                "& > a": {
                                  color: "content-primary",
                                },
                              }}
                              dangerouslySetInnerHTML={{
                                __html: (
                                  t("photo-id-instruction-3") || ""
                                ).replace(
                                  "{i-love-pdf}",
                                  "<a href='http://ilovepdf.com/jpg_to_pdf' target='_blank'>ilovepdf.com/jpg_to_pdf</a>"
                                ),
                              }}
                            ></li>
                          </ul>
                        </li>
                        <li
                          sx={{
                            variant: "text.p-x-small",
                            padding: "0 !important",
                            display: "list-item !important",
                            borderTop: "0 !important",
                            marginBottom: "spacing-xxs",
                          }}
                        >
                          {t("photo-id-instruction-4")}
                        </li>
                        <li
                          sx={{
                            variant: "text.p-x-small",
                            padding: "0 !important",
                            display: "list-item !important",
                            borderTop: "0 !important",
                            marginBottom: "spacing-xxs",
                          }}
                        >
                          {t("photo-id-instruction-5")}
                        </li>
                      </ul>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div
                      sx={{
                        position: "relative",
                        flex: 1,
                        width: "476px",
                        maxWidth: "476px",
                        height: "fit-content",
                      }}
                    >
                      <div
                        sx={{
                          position: "relative",
                          height: "fit-content",
                          maxHeight: "550px",
                          borderRadius: "12px",
                          border: "2px solid #D9DBE9",
                          overflow: "auto",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        {fileUrl.endsWith(".pdf") ||
                        selectedFile?.type === "application/pdf" ? (
                          <embed
                            src={fileUrl}
                            sx={{
                              width: "100%",
                              height: "550px",
                              borderRadius: "12px",
                              transition: "transform 0.5s ease",
                              cursor: "zoom-in",
                              ...(zoomLevel > 0
                                ? {
                                    transform: "scale(2)",
                                    cursor: "zoom-out",
                                  }
                                : {}),
                            }}
                          />
                        ) : (
                          <img
                            src={fileUrl}
                            sx={{
                              width: "100%",
                              borderRadius: "12px",
                              transition: "transform 0.5s ease",
                              cursor: "zoom-in",
                              ...(zoomLevel > 0
                                ? {
                                    transform: "scale(2)",
                                    cursor: "zoom-out",
                                  }
                                : {}),
                            }}
                            onClick={() => {
                              if (zoomLevel === 0) setZoomLevel(1);
                              else setZoomLevel(0);
                            }}
                          />
                        )}
                      </div>
                      <div
                        sx={{
                          position: "absolute",
                          bottom: "spacing-m",
                          right: "spacing-m",
                          background: "F0F0FE",
                          boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.25)",
                          display: "flex",
                          flexDirection: "column",
                          overflow: "hidden",
                          borderRadius: 12,
                        }}
                      >
                        <button
                          sx={{ ...buttonCssReset }}
                          onClick={() => setZoomLevel(1)}
                          disabled={zoomLevel === 1}
                        >
                          <PlusIcon width={20} height={20} />
                        </button>
                        <button
                          sx={{ ...buttonCssReset }}
                          onClick={() => setZoomLevel(0)}
                          disabled={zoomLevel === 0}
                        >
                          <MinusIcon width={20} height={20} />
                        </button>
                      </div>
                    </div>
                    <div
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "spacing-xs",
                      }}
                    >
                      <p
                        sx={{
                          margin: 0,
                          variant: "text.p-medium",
                          maxWidth: "400px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {selectedFile?.name}
                      </p>
                      <Button
                        variant="text_link"
                        sx={{
                          marginInlineStart: "auto",
                          paddingInlineEnd: 0,
                        }}
                        onClick={() => {
                          setFileUrl("");
                        }}
                      >
                        {t("replace")}
                      </Button>
                    </div>
                  </div>
                )}

                {/* Document Meta Form */}
                <div sx={{ overflow: "auto", flex: 1 }}>
                  <FormikProvider value={formik}>
                    <form
                      noValidate
                      onSubmit={formik.handleSubmit}
                      sx={{
                        mx: "spacing-m",
                      }}
                    >
                      <div sx={{ mb: "spacing-m" }}>
                        <Field name="documentType">
                          {() => {
                            return (
                              <Select
                                id="documentType"
                                name="documentType"
                                label={t("document-type")}
                                placeholder={t("select")}
                                value={documentTypeValue}
                                required
                                isDisabled
                              />
                            );
                          }}
                        </Field>
                      </div>
                      <div sx={{ mb: "spacing-m" }}>
                        <Field
                          name="idNumber"
                          label={t("id-number")}
                          placeholder={t("enter-id-number")}
                          component={FieldInputWrapper}
                          autocomplete="off"
                          required
                        />
                      </div>
                      <div sx={{ mb: "spacing-m" }}>
                        <Field name="dateOfIssue">
                          {({
                            field,
                            meta: { touched, error },
                          }: FieldProps) => {
                            return (
                              <>
                                <p
                                  sx={{
                                    variant: "text.p-small-medium",
                                    marginBottom: "spacing-xs",
                                  }}
                                >
                                  {t("date-of-issue")}
                                </p>
                                <DatePicker
                                  placeholderText={t("select-date")}
                                  value={field.value}
                                  maxDate={TODAY}
                                  onChange={(date) => {
                                    formik.setValues({
                                      ...values,
                                      dateOfIssue:
                                        (date as Date)?.toLocaleDateString() ||
                                        "",
                                    });

                                    setDateOfIssue(date as Date);
                                  }}
                                  customInput={
                                    <Input
                                      sx={{
                                        cursor: "pointer",
                                      }}
                                      name="dateOfIssue"
                                      value={field.value}
                                      startEnhancer={
                                        <CalendarIcon width={18} height={18} />
                                      }
                                      error={touched && !!error}
                                      success={touched && !error}
                                    />
                                  }
                                />
                                {errors?.dateOfIssue && (
                                  <SwvlUIErrorMessage>
                                    {errors.dateOfIssue}
                                  </SwvlUIErrorMessage>
                                )}
                              </>
                            );
                          }}
                        </Field>
                      </div>
                      <div sx={{ mb: "spacing-m" }}>
                        <p
                          sx={{
                            variant: "text.p-small-medium",
                            marginBottom: "spacing-xs",
                          }}
                        >
                          {tForm("does-document-have-expiry")}
                        </p>
                        <div
                          sx={{
                            display: "flex",
                            width: "fit-content",
                            backgroundColor: "secondary-light-90",
                            borderRadius: "100px",
                          }}
                        >
                          <Button
                            sx={{
                              borderRadius: "100px",
                              height: "34px",
                              "& > span": {
                                fontSize: "14px",
                                fontWeight: 400,
                              },
                              ...(isDocumentExpirable
                                ? {
                                    boxShadow:
                                      "1px 1px 5px 0px rgba(9, 30, 66, 0.20), 0px 0px 1px 0px rgba(9, 30, 66, 0.31)",
                                  }
                                : {
                                    backgroundColor: "secondary-light-90",
                                    color: "content-primary",
                                    "&:hover": {
                                      backgroundColor: "secondary-light-90",
                                      color: "content-primary",
                                    },
                                  }),
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              setIsDocumentExpirable(true);
                            }}
                          >
                            {t("yes")}
                          </Button>
                          <Button
                            sx={{
                              borderRadius: "100px",
                              height: "34px",
                              "& > span": {
                                fontSize: "14px",
                                fontWeight: 400,
                              },
                              ...(isDocumentExpirable
                                ? {
                                    backgroundColor: "secondary-light-90",
                                    color: "content-primary",
                                    "&:hover": {
                                      backgroundColor: "secondary-light-90",
                                      color: "content-primary",
                                    },
                                  }
                                : {
                                    boxShadow:
                                      "1px 1px 5px 0px rgba(9, 30, 66, 0.20), 0px 0px 1px 0px rgba(9, 30, 66, 0.31)",
                                  }),
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              setIsDocumentExpirable(false);
                            }}
                          >
                            {t("no")}
                          </Button>
                        </div>
                      </div>
                      {isDocumentExpirable && (
                        <div sx={{ mb: "spacing-m" }}>
                          <Field name="dateOfExpiry">
                            {({
                              field,
                              meta: { touched, error },
                            }: FieldProps) => {
                              return (
                                <>
                                  <p
                                    sx={{
                                      variant: "text.p-small-medium",
                                      marginBottom: "spacing-xs",
                                    }}
                                  >
                                    {t("date-of-expiry")}
                                  </p>
                                  <DatePicker
                                    placeholderText={t("select-date")}
                                    value={field.value}
                                    minDate={TODAY}
                                    onChange={(date) => {
                                      formik.setValues({
                                        ...values,
                                        dateOfExpiry:
                                          (
                                            date as Date
                                          )?.toLocaleDateString() || "",
                                      });

                                      setDateOfExpiry(date as Date);
                                    }}
                                    customInput={
                                      <Input
                                        sx={{
                                          cursor: "pointer",
                                        }}
                                        name="dateOfExpiry"
                                        value={field.value}
                                        startEnhancer={
                                          <CalendarIcon
                                            width={18}
                                            height={18}
                                          />
                                        }
                                        error={touched && !!error}
                                        success={touched && !error}
                                      />
                                    }
                                  />
                                  {errors?.dateOfExpiry && (
                                    <SwvlUIErrorMessage>
                                      {errors.dateOfExpiry}
                                    </SwvlUIErrorMessage>
                                  )}
                                </>
                              );
                            }}
                          </Field>
                        </div>
                      )}
                      <div sx={{ mb: "spacing-m" }}>
                        <Field
                          name="notes"
                          label={t("additional-notes")}
                          placeholder={t("enter-notes")}
                          component={FieldInputWrapper}
                          autocomplete="off"
                        />
                      </div>
                    </form>
                  </FormikProvider>
                </div>
              </div>

              {/* Footer */}
              <footer
                sx={{
                  display: "block",
                  position: "absolute",
                  bottom: 0,
                  width: "100%",
                  background: "#fff",
                }}
              >
                <div
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    py: "spacing-s",
                    px: "spacing-m",
                    borderTop: "1px solid #ddd",
                  }}
                >
                  <Button
                    disabled={isLoading || isUpdateLoading}
                    type="submit"
                    variant="secondary"
                    onClick={() => {
                      formik.submitForm();
                    }}
                    sx={{
                      "& > span": {
                        display: "flex",
                      },
                    }}
                  >
                    {isLoading || isUpdateLoading ? (
                      <Spinner color="secondary" size="26" />
                    ) : (
                      t("submit")
                    )}
                  </Button>
                </div>
              </footer>
            </div>
          </Drawer>
        </div>
      )}
    </>
  );
};
