/** @jsxImportSource theme-ui */
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Select, MultiSelect } from "@swvl/select";
import { CloseIcon, FilterIcon } from "@swvl/icons";
import { Button } from "@swvl/button";
import Input from "@swvl/input";

import { components } from "react-select";
import GA from "react-ga";

import Autocomplete from "components/AutocompleteWrapper";
import trackEvents from "constants/trackEvents";
import InputGroup from "components/InputGroup";

import {
  ListRideData,
  ListRideFilters,
  useListRideCategories,
} from "resources/rides-list/useListRides";
import { useQueryCaptain } from "resources/common/useQueryCaptain";
import {
  useQueryBus,
  useQueryBusTypes,
  useQueryDistricts,
} from "resources/rides-list/useQueryBus";
import useSelectedVendor from "hooks/useSelectedVendor";

import { Bus, Option, Captain, BusType } from "utils/types";
import { FilterAction } from "hooks/useFilters";
import { getStatusesData } from "pages/fleet/utils";
import GroupDatePicker from "components/GroupDatePicker";

const RidesListMoreFilter = ({
  cancel,
  filters,
  dispatchFilters: updateFilters,
}: {
  cancel: () => void;
  filters: ListRideData;
  dispatchFilters: React.Dispatch<FilterAction<ListRideData>>;
}) => {
  const { t } = useTranslation("rides");
  const {
    mutate: queryCaptain,
    data: captainSuggestions = [],
    isLoading: isCaptainQueryLoading,
  } = useQueryCaptain();
  const { data: rideCategoryOptions } = useListRideCategories();

  const selectedVendor = useSelectedVendor();

  const {
    mutate: queryBus,
    data: busSuggestions,
    isLoading: isBusQueryLoading,
  } = useQueryBus();

  const {
    mutate: queryBusType,
    data: busTypeSuggestions,
    isLoading: isBusTypeQueryLoading,
  } = useQueryBusTypes();

  const {
    mutate: queryDistricts,
    data: districtSuggestions,
    isLoading: isDistrictQueryLoading,
  } = useQueryDistricts();

  const searchFiltersValue = [
    {
      label: t("captain"),
      value: "captain",
    },
    {
      label: t("vehicle"),
      value: "bus",
    },
    {
      label: t("vehicle-type"),
      value: "busType",
    },
    {
      label: t("origin-district"),
      value: "originDistrict",
    },
    {
      label: t("destination-district"),
      value: "destinationDistrict",
    },
    {
      label: t("ride-id"),
      value: "rideId",
    },
  ];
  const [captainSearchFilterValue] = searchFiltersValue;
  const [initialCaptainValue, setInitialCaptainValue] = useState("");
  const events = trackEvents.getRideEvents();
  const [initialBusValue, setInitialBusValue] = useState("");
  const [initialBusTypeValue, setInitialBusTypeValue] = useState("");
  const [initialDistrictValue, setInitialDistrictValue] = useState("");
  const [selectedSearchType, setSelectedSearchType] = useState<Option>(
    captainSearchFilterValue
  );
  const [rideId, setRideId] = useState("");

  const [rideFilters, setRideFilters] = useState<ListRideData>({
    captain_assigned: filters.captain_assigned,
    captain_confirmed: filters.captain_confirmed,
    start_date: filters.start_date,
    end_date: filters.end_date,
    status: filters.status || [],
    ride_category_ids: filters.ride_category_ids || [],
    captain_ids: filters.captain_ids || [],
    page: filters.page || 1,
    limit: filters.limit || 10,
    sort: filters.sort,
  });

  const rideStatusOptions = [
    { value: "scheduled", label: t("captain-status.scheduled") },
    { value: "stopped,started", label: t("captain-status.in-progress") },
    { value: "completed", label: t("captain-status.completed") },
    {
      value: "corporate_revised",
      label: t("captain-status.corporate-revised"),
    },
    { value: "cancelled", label: t("captain-status.cancelled") },
  ];

  const rideAssignOptions = [
    { value: undefined, label: t("all", { ns: "common" }) },
    { value: true, label: t("yes", { ns: "common" }) },
    { value: false, label: t("no", { ns: "common" }) },
  ];

  const captainAcceptanceAction = [
    { value: undefined, label: t("all", { ns: "common" }) },
    { value: true, label: t("accepted") },
    { value: false, label: t("not-accepted") },
  ];

  const updateCurrentFilters = (filters: ListRideFilters) => {
    const updatedFilters = {
      ...rideFilters,
      ...filters,
      page: 1,
    };
    setRideFilters(updatedFilters);
  };

  const applyFilters = () => {
    updateFilters({
      type: "UPDATE",
      filters: rideFilters,
    });
    GA.event(events.actionApplyFilters);

    // clear ride id input
    setRideId("");
    cancel();
  };

  useEffect(() => {
    // if the selected search type is bus and we
    // change the vendor from company to contractor
    // then we need to change the search filter from
    // bus to captain
    if (selectedVendor.type === "contractor") {
      setInitialBusValue("");
      setSelectedSearchType(captainSearchFilterValue);
    }
  }, [selectedVendor]);

  return (
    <div
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
        overflowY: "auto",
        paddingBottom: "70px",
      }}
    >
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "spacing-m-l",
          marginBottom: "spacing-xl",
          width: "90%",
        }}
      >
        <div
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "spacing-s",
            }}
          >
            <FilterIcon />
            <h2>{t("filter-by")}</h2>
          </div>
          <CloseIcon cursor="pointer" onClick={cancel} />
        </div>
        <GroupDatePicker
          label={t("rides-date-label")}
          startDate={rideFilters.start_date || ""}
          endDate={rideFilters.end_date || ""}
          onDateChange={(start_date, end_date, type) => {
            updateCurrentFilters({
              start_date: start_date.toISOString(),
              end_date: end_date.toISOString(),
            });

            applyFilters();
            GA.event({
              ...events.actionFilterDate,
              label: JSON.stringify({
                start_date: start_date.toISOString(),
                end_Date: end_date.toISOString(),
                type,
              }),
            });
          }}
        />
        <div>
          <MultiSelect
            label={t("ride-status-label")}
            required // to remove the (optional word in label)
            id="ride-status"
            name="rideStatus"
            height="compact"
            options={rideStatusOptions}
            value={getStatusesData(rideStatusOptions, rideFilters.status || [])}
            onChange={(values) => {
              const status = (values as Option[]).reduce(
                (allStatuses: string[], { value: status }) => {
                  return [...allStatuses, ...(status as string).split(",")];
                },
                []
              );
              updateCurrentFilters({ status });
              GA.event({
                ...events.actionFilterRideStatus,
                label: JSON.stringify({ ride_status: status }),
              });
            }}
            placeholder={t("captain-status.placeholder")}
            sx={{ flex: 1 }}
          />
        </div>
        <div>
          <MultiSelect
            label={t("ride-category-label")}
            required // to remove the (optional word in label)
            id="ride-category"
            name="ride_category"
            height="compact"
            options={rideCategoryOptions}
            value={getStatusesData(
              rideCategoryOptions || [],
              rideFilters.ride_category_ids?.map((cat) => cat.split(":")[0]) ||
                []
            )}
            onChange={(values) => {
              const ride_category_ids = (values as Option[]).reduce(
                (allCategories: string[], { value, label }) => {
                  return [...allCategories, `${value}:${label}`];
                },
                []
              );

              updateCurrentFilters({ ride_category_ids });
              GA.event({
                ...events.actionFilterRideStatus,
                label: JSON.stringify({ ride_category_ids }),
              });
            }}
            placeholder={t("ride-category-status.placeholder")}
            sx={{ flex: 1 }}
          />
        </div>
        <div>
          <Select
            label={t("captain-response-label")}
            required // to remove the (optional word in label)
            id="captain-acceptance-action"
            name="captainAcceptanceAction"
            height="compact"
            options={captainAcceptanceAction}
            value={captainAcceptanceAction.find(
              ({ value }) => rideFilters.captain_confirmed === value
            )}
            isClearable={false}
            onChange={(value) => {
              const option = value as Option;

              updateCurrentFilters({
                captain_confirmed: option.value as boolean | undefined,
              });

              GA.event({
                ...events.actionFilterCaptainAcceptance,
                label: JSON.stringify({ captain_acceptance: option.label }),
              });
            }}
            placeholder={t("captain-acceptance-action.placeholder")}
            sx={{ flex: 1 }}
          />
        </div>
        <div>
          <Select
            label={t("captain-assignment-label")}
            required // to remove the (optional word in label)
            id="ride-assign"
            name="rideAssign"
            height="compact"
            options={rideAssignOptions}
            value={rideAssignOptions.find(
              ({ value }) => rideFilters.captain_assigned === value
            )}
            isClearable={false}
            onChange={(value) => {
              const option = value as Option;
              updateCurrentFilters({
                captain_assigned: option.value as boolean | undefined,
              });
            }}
            customComponents={{
              SingleValue: ({ children, ...props }) => (
                <components.SingleValue {...props}>
                  {`${t("captain-assign-status.placeholder")} - ${t(
                    children as string
                  )}`}
                </components.SingleValue>
              ),
            }}
            defaultValue={{
              value: undefined,
              label: t("all", { ns: "common" }),
            }}
            sx={{ flex: 1 }}
          />
        </div>
        <InputGroup sx={{ flex: 1 }} label={t("search-label")}>
          <Select
            id="search-control"
            name="search-control"
            options={searchFiltersValue}
            variant="plain"
            height="compact"
            value={searchFiltersValue.find(
              ({ value }) => selectedSearchType.value === value
            )}
            onChange={(value) => {
              setSelectedSearchType(value as Option);
              setInitialCaptainValue("");
              setInitialBusValue("");
              setInitialBusTypeValue("");
              setInitialDistrictValue("");
            }}
            isClearable={false}
            defaultValue={selectedSearchType}
            menuPosition="fixed"
          />
          {selectedSearchType.value === "captain" && (
            <Autocomplete
              onChangeQuery={queryCaptain}
              suggestions={captainSuggestions}
              isLoading={isCaptainQueryLoading}
              initialValue={initialCaptainValue}
              onOptionSelect={(captain) => {
                const { name, _id } = captain as Captain;

                const filter_string = `${_id}:${name}`;
                if (
                  filters.captain_ids &&
                  !filters.captain_ids.includes(filter_string)
                ) {
                  updateCurrentFilters({
                    captain_ids: [...filters.captain_ids, filter_string],
                  });
                } else if (!filters.captain_ids) {
                  updateCurrentFilters({
                    captain_ids: [filter_string],
                  });
                }
              }}
              onClear={() => {
                setInitialCaptainValue("");
              }}
              placeholder={t("search-captain")}
              height="compact"
              variant="plain"
              noResultsMessage={t("no-result-found", { ns: "common" })}
            />
          )}
          {selectedSearchType.value === "bus" && (
            <Autocomplete
              onChangeQuery={(q) => queryBus({ q })}
              suggestions={busSuggestions || []}
              isLoading={isBusQueryLoading}
              initialValue={initialBusValue}
              minCharacters={3}
              onOptionSelect={(bus) => {
                const { plates, _id } = bus as Bus;

                const filter_string = `${_id}:${plates}`;
                if (
                  filters.bus_ids &&
                  !filters.bus_ids.includes(filter_string)
                ) {
                  updateCurrentFilters({
                    bus_ids: [...filters.bus_ids, filter_string],
                  });
                } else if (!filters.bus_ids) {
                  updateCurrentFilters({
                    bus_ids: [filter_string],
                  });
                }
              }}
              onClear={() => {
                setInitialBusValue("");
              }}
              placeholder={t("search-bus")}
              variant="plain"
              height="compact"
              noResultsMessage={t("no-result-found", { ns: "common" })}
            />
          )}
          {selectedSearchType.value === "busType" && (
            <Autocomplete
              minCharacters={3}
              onChangeQuery={(q) => {
                return queryBusType({ q });
              }}
              suggestions={busTypeSuggestions || []}
              isLoading={isBusTypeQueryLoading}
              initialValue={initialBusTypeValue}
              onOptionSelect={(busType) => {
                const { _source, _id } = busType as BusType;

                const filter_string = `${_id}:${_source?.name}`;
                if (
                  filters.bus_type_ids &&
                  !filters.bus_type_ids.includes(filter_string)
                ) {
                  updateCurrentFilters({
                    bus_type_ids: [...filters.bus_type_ids, filter_string],
                  });
                } else if (!filters.bus_type_ids) {
                  updateCurrentFilters({
                    bus_type_ids: [filter_string],
                  });
                }
              }}
              onClear={() => {
                setInitialBusTypeValue("");
              }}
              placeholder={t("search-vehicle-type")}
              height="compact"
              variant="plain"
              noResultsMessage={t("no-result-found", { ns: "common" })}
            />
          )}
          {selectedSearchType.value === "originDistrict" && (
            <Autocomplete
              minCharacters={3}
              onChangeQuery={(q) => {
                return queryDistricts({ q });
              }}
              suggestions={districtSuggestions || []}
              isLoading={isDistrictQueryLoading}
              initialValue={initialDistrictValue}
              onOptionSelect={(originDistrict) => {
                const { _source, _id } = originDistrict as BusType;

                const filter_string = `${_id}:${_source?.name}`;
                if (
                  filters.origin_districts &&
                  !filters.origin_districts.includes(filter_string)
                ) {
                  updateCurrentFilters({
                    origin_districts: [
                      ...filters.origin_districts,
                      filter_string,
                    ],
                  });
                } else if (!filters.origin_districts) {
                  updateCurrentFilters({
                    origin_districts: [filter_string],
                  });
                }
              }}
              onClear={() => {
                setInitialDistrictValue("");
              }}
              placeholder={t("search-origin-district")}
              height="compact"
              variant="plain"
              noResultsMessage={t("no-result-found", { ns: "common" })}
            />
          )}
          {selectedSearchType.value === "destinationDistrict" && (
            <Autocomplete
              minCharacters={3}
              onChangeQuery={(q) => {
                return queryDistricts({ q });
              }}
              suggestions={districtSuggestions || []}
              isLoading={isDistrictQueryLoading}
              initialValue={initialDistrictValue}
              onOptionSelect={(destinationDistrict) => {
                const { _source, _id } = destinationDistrict as BusType;

                const filter_string = `${_id}:${_source?.name}`;
                if (
                  filters.destination_districts &&
                  !filters.destination_districts.includes(filter_string)
                ) {
                  updateCurrentFilters({
                    destination_districts: [
                      ...filters.destination_districts,
                      filter_string,
                    ],
                  });
                } else if (!filters.destination_districts) {
                  updateCurrentFilters({
                    destination_districts: [filter_string],
                  });
                }
              }}
              onClear={() => {
                setInitialDistrictValue("");
              }}
              placeholder={t("search-destination-district")}
              height="compact"
              variant="plain"
              noResultsMessage={t("no-result-found", { ns: "common" })}
            />
          )}
          {selectedSearchType.value === "rideId" && (
            <Input
              placeholder={t("enter-ride-id")}
              height="compact"
              variant="plain"
              value={rideId}
              onChange={(e) => {
                setRideId(e.target.value);
                const filter_string = `${rideId}`;
                updateCurrentFilters({
                  ride_id: filter_string,
                });
              }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  applyFilters();
                }
              }}
            />
          )}
        </InputGroup>
      </div>
      <div
        sx={{
          display: "flex",
          position: "absolute",
          bottom: 0,
          zIndex: 9999,
          backgroundColor: "#FFFFFF",
          alignItems: "center",
          justifyContent: "space-between",
          minHeight: "70px",
          width: "100%",
          padding: "0 5%",
          borderTop: "1px solid",
          borderColor: "border",
        }}
      >
        <Button
          variant="text_link"
          onClick={cancel}
          sx={{
            padding: 0,
          }}
        >
          {t("close")}
        </Button>
        <Button
          onClick={applyFilters}
          sx={{
            paddingInlineStart: "12px",
            paddingInlineEnd: "spacing-s",
          }}
        >
          {t("apply")}
        </Button>
      </div>
    </div>
  );
};

export default RidesListMoreFilter;
