/** @jsxImportSource theme-ui */
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import GA from "react-ga";

import trackEvents from "constants/trackEvents";
import NotFound from "components/NotFound";
import Vehicles from "./Vehicles";
import Captains from "./Captains";
import CaptainDetails from "./CaptainDetails";
import VehicleDetails from "./VehicleDetails";

const Fleet = () => {
  const { t } = useTranslation("common");
  const events = trackEvents.getCommonEvents();
  const navigate = useNavigate();
  useEffect(() => {
    GA.event(events.actionSelectFleet);
  }, []);

  return (
    <Routes>
      <Route index element={<Navigate to="vehicles" replace />} />
      <Route key="vehicles" path="vehicles/*" element={<Vehicles />} />
      <Route
        key="vehicle details"
        path="vehicles/:id"
        element={<VehicleDetails />}
      />
      <Route
        key="captain details tabs"
        path={`captains/:id/:key`}
        element={<CaptainDetails />}
      />

      <Route
        key="captain details"
        path="captains/:id"
        element={<CaptainDetails />}
      />
      <Route key="captains" path="captains" element={<Captains />} />
      <Route
        path="*"
        element={
          <NotFound
            title={t("not-found-title")}
            description={t("not-found-description")}
            goBackCallback={() =>
              navigate("/rides-management/rides", { replace: true })
            }
          />
        }
      />
    </Routes>
  );
};
export default Fleet;
