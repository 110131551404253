/** @jsxImportSource theme-ui */
import Accordion from "@swvl/accordion";
import RatingBreakDown from "pages/rides-management/RatingBreakDown";
import { useTranslation } from "react-i18next";
import { CaptainRatingResponse } from "resources/common/useCaptainRating";
import StarFilledIcon from "shared/icons/StarFilledIcon";

const OverallRating = ({
  ratingData,
  captainRating,
}: {
  ratingData: CaptainRatingResponse;
  captainRating: number;
}) => {
  const { t } = useTranslation("captains");

  const keyAreas = [
    {
      title: t("rating-reviews.calculate-rating.key-areas.punctuality.title"),
      value: t(
        "rating-reviews.calculate-rating.key-areas.punctuality.description"
      ),
    },
    {
      title: t("rating-reviews.calculate-rating.key-areas.cleanliness.title"),
      value: t(
        "rating-reviews.calculate-rating.key-areas.cleanliness.description"
      ),
    },
    {
      title: t("rating-reviews.calculate-rating.key-areas.safety.title"),
      value: t("rating-reviews.calculate-rating.key-areas.safety.description"),
    },
    {
      title: t("rating-reviews.calculate-rating.key-areas.communication.title"),
      value: t(
        "rating-reviews.calculate-rating.key-areas.communication.description"
      ),
    },
    {
      title: t("rating-reviews.calculate-rating.key-areas.feedback.title"),
      value: t(
        "rating-reviews.calculate-rating.key-areas.feedback.description"
      ),
    },
  ];

  if (!ratingData?.summary) return null;

  return (
    <div
      sx={{
        width: 500,
      }}
    >
      <div
        sx={{
          background: "rgba(233, 238, 254, 0.3)",
          border: "1px solid rgba(0, 0, 0, 0.08)",
          padding: 24,
          borderRadius: 16,
        }}
      >
        <div sx={{ display: "flex", alignItems: "start", gap: 10 }}>
          <StarFilledIcon width={22} height={22} />
          <div>
            <h3
              sx={{
                fontSize: 24,
                fontWeight: 700,
                display: "flex",
                margin: "0 0 10px",
                alignItems: "end",
                gap: 4,
              }}
            >
              {captainRating}
              <span
                sx={{
                  color: "content-secondary",
                  fontSize: 16,
                  fontWeight: 400,
                }}
              >
                ({t("rating-reviews.overall-ratings")})
              </span>
            </h3>
          </div>
        </div>

        <RatingBreakDown ratingData={ratingData} />
      </div>
      <div
        sx={{
          background: "rgba(233, 238, 254, 0.3)",
          marginTop: 24,
          "& > div": {
            borderRadius: 16,
          },
        }}
      >
        <Accordion title={t("rating-reviews.calculate-rating.title")}>
          <div sx={{ padding: "0 24px 24px" }}>
            <p
              sx={{
                color: "content-secondary",
                fontWeight: 400,
                fontSize: 14,
                marginBottom: 24,
              }}
            >
              {t("rating-reviews.calculate-rating.description")}
            </p>
            <h3 sx={{ fontSize: 14, fontWeight: 500 }}>
              {t("rating-reviews.calculate-rating.key-areas.title")}
            </h3>
            {keyAreas?.map((item, i) => (
              <p key={i} sx={{ color: "content-secondary", fontSize: 14 }}>
                <span sx={{ fontWeight: 500 }}>{item.title}</span> {item.value}
              </p>
            ))}
          </div>
        </Accordion>
      </div>
    </div>
  );
};

export default OverallRating;
