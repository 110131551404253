/** @jsxImportSource theme-ui */
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";

import NotFoundLogo from "assets/not-found.svg";
import { NotFound as NotFoundProps } from "utils/types";

interface Path {
  url: string;
  title: string;
  goBackCallback?: () => void;
}

const LinkTag = ({ url, title, goBackCallback }: Path) => {
  let element = (
    <Link
      to={url}
      sx={{
        variant: "text.link-text-small",
        color: "primary-dark-100",
      }}
      replace={true}
    >
      {title}
    </Link>
  );

  if (goBackCallback) {
    element = (
      <button
        onClick={goBackCallback}
        sx={{
          padding: 0,
          border: "none",
          cursor: "pointer",
          color: "primary-dark-100",
          variant: "text.link-text-small",
          backgroundColor: "background-primary",
        }}
      >
        {title}
      </button>
    );
  }

  return element;
};

const NotFound = ({ title, description, goBackCallback }: NotFoundProps) => {
  const { t } = useTranslation("common");
  const location = useLocation();

  return (
    <div
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        px: "spacing-m",
        pt: "spacing-m",
      }}
    >
      <div
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <h3
            sx={{
              variant: "text.h3",
              mb: "spacing-m",
              maxWidth: "450px",
              my: 0,
            }}
          >
            {title}
          </h3>
          <h5
            sx={{
              variant: "text.h5",
              maxWidth: "375px",
              mt: "spacing-m-l",
              mb: "40px",
            }}
          >
            {description}
          </h5>
          <p sx={{ variant: "text.p-small-medium", mb: "spacing-s" }}>
            {t("useful-link")}
          </p>
          <ul sx={{ padding: 0 }}>
            <li sx={{ listStyleType: "none", mb: "12px" }}>
              <LinkTag
                url="/rides-management/rides"
                title={t("navigation.rides-management")}
              />
            </li>
            <li sx={{ listStyleType: "none", mb: "12px" }}>
              <LinkTag
                url="/fleet/captains"
                title={t("navigation.captains")}
                goBackCallback={
                  location.pathname.includes("/fleet/captains")
                    ? goBackCallback
                    : undefined
                }
              />
            </li>
            <li sx={{ listStyleType: "none", mb: "12px" }}>
              <LinkTag
                url="/fleet/vehicles"
                title={t("navigation.vehicles")}
                goBackCallback={
                  location.pathname.includes("/fleet/vehicles")
                    ? goBackCallback
                    : undefined
                }
              />
            </li>
          </ul>
        </div>
        <img
          sx={{ marginInlineStart: "spacing-xxl" }}
          src={NotFoundLogo}
          alt="Not Found Logo"
        />
      </div>
      <button
        onClick={goBackCallback}
        sx={{
          mb: "12px",
          border: "none",
          cursor: "pointer",
          color: "primary-dark-100",
          variant: "text.link-text-small",
          backgroundColor: "background-primary",
        }}
      >
        {t("go-back")}
      </button>
    </div>
  );
};

export default NotFound;
