/** @jsxImportSource theme-ui */

import useLang from "hooks/useLang";
import { useTranslation } from "react-i18next";
import { RideRatingSummary } from "resources/rides-list/useRideDetails";

const RatingBreakDown = ({
  ratingData,
}: {
  ratingData?: RideRatingSummary;
}) => {
  const { t } = useTranslation("rides");
  const [lang] = useLang();

  if (!ratingData) return null;
  return (
    <div>
      <div
        sx={{
          display: "grid",
          gridTemplateColumns: "auto",
          gap: "16px",
          mt: "spacing-m",
        }}
      >
        {["5", "4", "3", "2", "1"].map((starCount) => {
          const breakdown = ratingData.summary?.breakdown[starCount];
          if (!breakdown) return null;
          return (
            <div
              key={starCount}
              sx={{
                display: "grid",
                gridTemplateColumns: "18% 67% 15%",
                alignItems: "center",
                gap: "16px",
              }}
            >
              <span
                sx={{
                  variant: "text.p-small",
                  direction: lang.code === "en" ? "ltr" : "rtl",
                }}
              >
                {starCount} {starCount === "1" ? t("star") : t("stars")}
              </span>
              <div
                sx={{
                  position: "relative",
                  height: "4px",
                  borderRadius: "100px",
                  flex: 2,
                  bg: "#D9D9D9",
                  overflow: "hidden",
                }}
              >
                <div
                  sx={{
                    position: "absolute",
                    left: lang.code === "en" ? 0 : "none",
                    top: 0,
                    height: "4px",
                    width: `${breakdown.percentage}%`,
                    bg: "#2356F9",
                  }}
                ></div>
              </div>
              <span sx={{ variant: "text.p-x-small" }}>
                {breakdown.percentage}%
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RatingBreakDown;
