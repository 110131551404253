import * as React from "react";

const StarEmptyIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.343 5.33244L8.00905 1.33668C8.00645 1.33228 8.00012 1.33227 7.99752 1.33668L5.65785 5.33244L1.3383 6.41661C1.33339 6.41784 1.33159 6.42389 1.33503 6.42761L4.32715 9.66211L3.2037 14.6586C3.20251 14.6638 3.20776 14.6682 3.21276 14.6662L8.00425 12.6827L12.7844 14.6662C12.7894 14.6682 12.7947 14.6638 12.7935 14.6586L11.6721 9.65458L14.6648 6.43198C14.6682 6.42826 14.6664 6.42221 14.6615 6.42097L10.343 5.33244Z"
        stroke="#BDBDBD"
      />
    </svg>
  );
};

export default StarEmptyIcon;
