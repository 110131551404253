/** @jsxImportSource theme-ui */
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { useQueryClient } from "react-query";
import { Helmet } from "react-helmet-async";
import { AlertWrapper } from "@swvl/alert";

import { useAuthContext } from "contexts/authContext";
import { useManagerInfo } from "resources/auth/useManagerInfo";
import useLang from "hooks/useLang";
import useAlert from "hooks/useAlert";
import useSelectedVendorIndex from "hooks/useSelectedVendorIndex";
import { dispatchSingletonFilters } from "hooks/useFilters";

import GA from "react-ga";

import {
  createRequestInterceptor,
  createResponseInterceptor,
  ejectInterceptors,
} from "../api";

import AuthControlWrapper from "components/AuthControlWrapper";
import Login from "pages/login";
import Dashboard from "pages/dashboard";

let previousVendor: string | undefined;

/** Specify main modules */
export default function Main() {
  const queryClient = useQueryClient();
  const [isTokenInterceptorAdded, setTokenInterceptorAdded] = useState(false);
  const {
    accessToken,
    removeAuthData,
    setManagerVendorLoadedInInterceptor,
    setDynamicKeys,
  } = useAuthContext();
  const [lang] = useLang();
  const [alertDuration] = useAlert();
  const { data: managerInfo } = useManagerInfo(isTokenInterceptorAdded);
  const [selectedVendorIndex] = useSelectedVendorIndex();
  const selectedVendor = managerInfo?.vendors[selectedVendorIndex];
  const isDifferentVendor = previousVendor !== selectedVendor?.id;

  useEffect(() => {
    const reqInterceptor = createRequestInterceptor(
      accessToken,
      selectedVendor,
      lang.code
    );
    const resInterceptor = createResponseInterceptor(
      removeAuthData,
      queryClient.isMutating()
    );

    if (accessToken) {
      setTokenInterceptorAdded(true);

      if (managerInfo) {
        setManagerVendorLoadedInInterceptor(true);
        setDynamicKeys(`${selectedVendor?.id}-${lang.code}`);
        if (isDifferentVendor) {
          dispatchSingletonFilters({ type: "RESET" });
        }
        previousVendor = selectedVendor?.id;
      }
    }

    return () => {
      ejectInterceptors(reqInterceptor, resInterceptor);
      setTokenInterceptorAdded(false);
    };
  }, [accessToken, selectedVendorIndex, lang.code, managerInfo]);

  useEffect(() => {
    if (!managerInfo) return;

    GA.initialize(window.__env__.GA_TRACKING_ID, {
      gaOptions: {
        userId: managerInfo.user.email,
      },
      debug: window.__env__.NODE_ENV === "development",
    });
  }, [managerInfo]);

  return (
    <div sx={{ fontFamily: "body" }}>
      <Helmet htmlAttributes={{ lang: lang.code, dir: lang.direction }} />
      <AuthControlWrapper>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/*" element={<Dashboard />} />
        </Routes>
      </AuthControlWrapper>

      <AlertWrapper
        direction={lang.direction}
        position={lang.direction === "rtl" ? "bottom-left" : undefined}
        duration={alertDuration || undefined}
      />
    </div>
  );
}
