/** @jsxImportSource theme-ui */
import { ThemeUIStyleObject } from "theme-ui";
import EmptyBoxImage from "assets/logo-empty-box.svg";
import { useTranslation } from "react-i18next";

const NoData = ({
  title,
  description,
  image,
  style,
}: {
  title: string;
  description?: string;
  image?: string;
  style?: ThemeUIStyleObject;
}) => {
  const { t } = useTranslation();
  return (
    <div
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "80px 0",
        ...(style || {}),
      }}
    >
      <img src={image || EmptyBoxImage} />
      <p sx={{ variant: "text.h5", mt: "58px", mb: "8px" }}>{title}</p>
      <p
        sx={{
          color: "content-tertiary",
          fontSize: 16,
          fontWeight: 400,
          maxWidth: 600,
          margin: "15px auto 0",
          lineHeight: "24px",
          textAlign: "center",
        }}
      >
        {description || t("no-data-title", { ns: "common" })}
      </p>
    </div>
  );
};
export default NoData;
