import { useEffect, FC, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { useAuthContext } from "contexts/authContext";
import useSubscribeRefreshToken from "hooks/useSubscribeRefreshToken";

const loginPath = encodeURIComponent("/login");

const AuthControlWrapper: FC = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { accessToken } = useAuthContext();
  const back = encodeURIComponent(location.pathname);
  const backQuery = back === loginPath ? "" : `?back=${back}`;

  useSubscribeRefreshToken();

  useEffect(() => {
    if (!accessToken) navigate(`/login${backQuery}${location.search}`);
  }, [accessToken]);

  return <Fragment>{children}</Fragment>;
};

export default AuthControlWrapper;
