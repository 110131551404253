import * as React from "react";

const CompanyLogoIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="58"
      height="58"
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="29"
        cy="29"
        r="28.5"
        fill="#F8FAFF"
        stroke="#D9DBE9"
        strokeDasharray="4 4"
      />
      <path
        d="M23 25C24.1046 25 25 24.1046 25 23C25 21.8954 24.1046 21 23 21C21.8954 21 21 21.8954 21 23C21 24.1046 21.8954 25 23 25Z"
        stroke="#BDBDBD"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3334 29C16.3334 23.0289 16.3334 20.0433 18.1884 18.1883C20.0434 16.3333 23.0289 16.3333 29 16.3333C34.9711 16.3333 37.9567 16.3333 39.8118 18.1883C41.6667 20.0433 41.6667 23.0289 41.6667 29C41.6667 34.9711 41.6667 37.9567 39.8118 39.8117C37.9567 41.6667 34.9711 41.6667 29 41.6667C23.0289 41.6667 20.0434 41.6667 18.1884 39.8117C16.3334 37.9567 16.3334 34.9711 16.3334 29Z"
        stroke="#BDBDBD"
        strokeWidth="2"
      />
      <path
        d="M19.6666 40.9999C25.4966 34.0333 32.0321 24.8453 41.6633 31.0565"
        stroke="#BDBDBD"
        strokeWidth="2"
      />
    </svg>
  );
};

export default CompanyLogoIcon;
