import { useMutation } from "react-query";
import apiInstance from "api";

const changeCommunicationLang = (language: string) => {
  return apiInstance.put<unknown>("user/language", { language });
};

const useChangeCommunicationLang = () => {
  const mutation = useMutation(changeCommunicationLang, {
    mutationKey: `/user/language`,
  });

  return mutation;
};

export { useChangeCommunicationLang };
