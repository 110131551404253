import * as React from "react";

const WarningFilledIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.90804 1.34333C7.61764 1.10778 8.38244 1.10778 9.09204 1.34333C9.79877 1.57791 10.3536 2.13427 10.911 2.91597C11.4666 3.69507 12.0806 4.78137 12.8719 6.18167L12.903 6.23664C13.6945 7.63713 14.3084 8.7234 14.691 9.60433C15.0754 10.4895 15.2668 11.2538 15.1141 11.9881C14.9602 12.7274 14.581 13.3997 14.0286 13.9078C13.4775 14.4147 12.7272 14.6285 11.7824 14.7312C10.843 14.8333 9.61417 14.8333 8.03258 14.8333H7.96757C6.38592 14.8333 5.15707 14.8333 4.21765 14.7312C3.27289 14.6285 2.52257 14.4147 1.97152 13.9078C1.41912 13.3997 1.03983 12.7274 0.886008 11.9881C0.733241 11.2538 0.924708 10.4895 1.30914 9.60433C1.69172 8.7234 2.30561 7.63713 3.09709 6.23663L3.12816 6.18167C3.91951 4.78138 4.53343 3.69507 5.08902 2.91597C5.64646 2.13427 6.20133 1.57791 6.90804 1.34333ZM7.78797 7.35153C7.95264 7.37367 8.18051 7.43293 8.37384 7.62627C8.56718 7.8196 8.62644 8.0474 8.64857 8.21213C8.66691 8.34853 8.66677 8.5072 8.66671 8.6404V8.66667V11.3333C8.66671 11.7015 8.36824 12 8.00004 12C7.63184 12 7.33337 11.7015 7.33337 11.3333V8.66667C6.96517 8.66667 6.66671 8.3682 6.66671 8C6.66671 7.6318 6.96517 7.33333 7.33337 7.33333H7.35964C7.49291 7.33327 7.65151 7.33313 7.78797 7.35153ZM7.99697 4.66667C7.63044 4.66667 7.33331 4.96515 7.33331 5.33333C7.33331 5.70152 7.63044 6 7.99697 6H8.00297C8.36951 6 8.66664 5.70152 8.66664 5.33333C8.66664 4.96515 8.36951 4.66667 8.00297 4.66667H7.99697Z"
        fill="#DA3B07"
      />
    </svg>
  );
};

export default WarningFilledIcon;
