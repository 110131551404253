import * as React from "react";

const CalendarRoundedIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 1.33337V2.66671M4 1.33337V2.66671"
        stroke="#141B34"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.997 8.66663H8.003M7.997 11.3333H8.003M10.6607 8.66663H10.6667M5.33333 8.66663H5.33931M5.33333 11.3333H5.33931"
        stroke="#141B34"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.33333 5.33337H13.6667"
        stroke="#141B34"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.66667 8.16213C1.66667 5.25729 1.66667 3.80485 2.50141 2.90243C3.33616 2 4.67966 2 7.36667 2H8.63333C11.3203 2 12.6639 2 13.4986 2.90243C14.3333 3.80485 14.3333 5.25729 14.3333 8.16213V8.50453C14.3333 11.4094 14.3333 12.8618 13.4986 13.7643C12.6639 14.6667 11.3203 14.6667 8.63333 14.6667H7.36667C4.67966 14.6667 3.33616 14.6667 2.50141 13.7643C1.66667 12.8618 1.66667 11.4094 1.66667 8.50453V8.16213Z"
        stroke="#141B34"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 5.33337H14"
        stroke="#141B34"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CalendarRoundedIcon;
