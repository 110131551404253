import * as React from "react";

const UploadImageIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="12"
        cy="12"
        r="11"
        fill="#2356F9"
        stroke="white"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9833 6.625H12.0147C12.3167 6.625 12.5668 6.62499 12.7732 6.64258C12.9896 6.66101 13.1851 6.70035 13.3737 6.79371C13.7097 6.96005 13.9221 7.22439 14.0712 7.4895C14.1867 7.69502 14.2765 7.92591 14.3525 8.12159C14.3705 8.16777 14.3877 8.21198 14.4043 8.25345L14.478 8.43767C14.5142 8.5283 14.5323 8.57361 14.5698 8.59935C14.6073 8.62508 14.6566 8.62573 14.7553 8.62702C15.2159 8.63304 15.564 8.6591 15.8697 8.7874C16.3436 8.98631 16.7282 9.35316 16.9526 9.81774C17.0527 10.025 17.1036 10.2477 17.1373 10.5063C17.1702 10.7584 17.1895 11.0695 17.2134 11.4587L17.2934 12.7561C17.3534 13.7278 17.4007 14.4957 17.3565 15.098C17.3111 15.7164 17.1661 16.2209 16.7921 16.6243C16.4174 17.0286 15.9275 17.2077 15.3187 17.2926C14.7274 17.375 13.9673 17.375 13.0075 17.375H10.9904C10.0306 17.375 9.27052 17.375 8.67916 17.2926C8.07043 17.2077 7.58051 17.0286 7.20579 16.6243C6.8318 16.2209 6.68678 15.7164 6.64137 15.098C6.59715 14.4957 6.64452 13.7278 6.70445 12.7561L6.78448 11.4586C6.80848 11.0694 6.82766 10.7584 6.86054 10.5063C6.89427 10.2477 6.94523 10.025 7.04532 9.81774C7.26965 9.35316 7.65429 8.98631 8.12819 8.7874C8.43389 8.65909 8.78205 8.63304 9.24273 8.62702C9.34139 8.62573 9.39073 8.62508 9.42821 8.59935C9.4657 8.57361 9.48382 8.5283 9.52007 8.43767L9.59376 8.25345C9.61035 8.21198 9.62753 8.16777 9.64547 8.1216C9.72153 7.92592 9.81128 7.69502 9.92686 7.4895C10.076 7.22439 10.2883 6.96005 10.6243 6.79371C10.8129 6.70035 11.0084 6.66101 11.2248 6.64258C11.4312 6.62499 11.6813 6.625 11.9833 6.625ZM11.5 9C11.5 8.72386 11.7228 8.5 11.9977 8.5C12.2707 8.5 12.5 8.72396 12.5 9C12.5 9.27605 12.2707 9.5 11.9977 9.5C11.7228 9.5 11.5 9.27615 11.5 9ZM12 11C10.8954 11 10 11.8955 10 13C10 14.1045 10.8954 15 12 15C13.1045 15 14 14.1045 14 13C14 11.8955 13.1045 11 12 11Z"
        fill="white"
      />
    </svg>
  );
};

export default UploadImageIcon;
