/** @jsxImportSource theme-ui */
import { useState } from "react";
import { useTranslation } from "react-i18next";
import noop from "lodash/noop";
import { MultiSelect, Select } from "@swvl/select";
import { CloseIcon, FilterIcon } from "@swvl/icons";
import { Button } from "@swvl/button";
import { Option } from "utils/types";

import { FilterAction } from "hooks/useFilters";
import { getStatusesData } from "pages/fleet/utils";
import { ListVehicleData } from "resources/vehicles-list/useListVehicle";

const VehiclesListMoreFilters = ({
  cancel,
  filters,
  dispatchFilters: updateFilters,
  clearAllFilters = noop,
}: {
  cancel: () => void;
  filters: ListVehicleData;
  dispatchFilters: React.Dispatch<FilterAction<ListVehicleData>>;
  clearAllFilters: () => void;
}) => {
  const { t } = useTranslation("vehicles");

  const [vehicleFilters, setVehicleFilters] = useState<ListVehicleData>({
    document_verification_status:
      filters.document_verification_status || undefined,
    status: filters.status || undefined,
    page: filters.page || 1,
    limit: filters.limit || 10,
  });

  const busStatusOptions = [
    { value: undefined, label: t("bus-statuses.all") },
    { value: "active", label: t("bus-statuses.active") },
    {
      value: "inactive",
      label: t("bus-statuses.inactive"),
    },
  ];
  const documentVerificationOptions = [
    { value: "verified", label: t("document-verification-statuses.verified") },
    { value: "pending", label: t("document-verification-statuses.pending") },
    {
      value: "expired",
      label: t("document-verification-statuses.expired"),
    },
    {
      value: "expiring_soon",
      label: t("document-verification-statuses.expiring_soon"),
    },
  ];

  const updateCurrentFilters = (filters: ListVehicleData) => {
    const updatedFilters = {
      ...vehicleFilters,
      ...filters,
      page: 1,
    };
    setVehicleFilters(updatedFilters);
  };

  const applyFilters = () => {
    updateFilters({
      type: "UPDATE",
      filters: vehicleFilters,
    });
    cancel();
  };
  const clearFilters = () => {
    clearAllFilters();
    cancel();
  };

  return (
    <div
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "spacing-m-l",
          width: "90%",
        }}
      >
        <div
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "spacing-s",
            }}
          >
            <FilterIcon />
            <h2>{t("filter-by")}</h2>
          </div>
          <CloseIcon cursor="pointer" onClick={cancel} />
        </div>
      </div>
      <div
        sx={{
          width: "90%",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          gap: "spacing-m",
        }}
      >
        <div>
          <Select
            label={t("bus-status-label")}
            required // to remove the (optional word in label)
            id="more-filters-bus-status"
            name="more-filters-bus-status"
            height="compact"
            options={busStatusOptions}
            value={busStatusOptions.find(
              ({ value }) => vehicleFilters.status === value
            )}
            isClearable={false}
            onChange={(value) => {
              const option = value as Option;
              if (option.value === "all") return;

              updateCurrentFilters({ status: option.value as string, page: 1 });
            }}
            placeholder={t("bus-statuses.placeholder")}
            sx={{ flex: 1 }}
          />
        </div>
        <div>
          <MultiSelect
            label={t("document-verification-status")}
            required // to remove the (optional word in label)
            id="filters-document-verification-status"
            name="documentVerificationStatus"
            height="compact"
            options={documentVerificationOptions}
            value={getStatusesData(
              documentVerificationOptions,
              vehicleFilters.document_verification_status || []
            )}
            onChange={(values) => {
              const status = (values as Option[]).reduce(
                (allStatuses: string[], { value: status }) => {
                  return [...allStatuses, ...(status as string).split(",")];
                },
                []
              );
              updateCurrentFilters({
                document_verification_status: status,
                page: 1,
              });
            }}
            placeholder={t("document-verification-statuses.placeholder")}
            sx={{ flex: 1 }}
          />
        </div>
      </div>

      <div
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          minHeight: "70px",
          width: "100%",
          padding: "0 5%",
          borderTop: "1px solid",
          borderColor: "border",
        }}
      >
        <Button
          variant="text_link"
          onClick={clearFilters}
          sx={{
            paddingInlineStart: "12px",
          }}
        >
          {t("clear-all")}
        </Button>
        <Button
          onClick={applyFilters}
          sx={{
            paddingInlineStart: "12px",
            paddingInlineEnd: "spacing-s",
          }}
        >
          {t("apply")}
        </Button>
      </div>
    </div>
  );
};

export default VehiclesListMoreFilters;
