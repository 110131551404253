/** @jsxImportSource theme-ui */
import { useState, useRef, useEffect, useMemo } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import GA from "react-ga";
import { useTheme } from "@swvl/theme";
import { showAlert } from "@swvl/alert";
import {
  Menu,
  ControlledMenu,
  MenuHeader,
  MenuItem,
  MenuDivider,
} from "@swvl/menu";
import { GlobeIcon, MenuIcon } from "@swvl/icons";
import { IconButton } from "@swvl/button";
import { TopNavigation } from "@swvl/navigation";
import Avatar from "@swvl/avatar";

import { useLogout } from "resources/auth/useLogout";
import useLang, { localizationLanguages, Lang } from "hooks/useLang";
import useMenuOpen from "hooks/useMenuOpen";
import useSelectedVendorIndex from "hooks/useSelectedVendorIndex";
import useAlert from "hooks/useAlert";

import SwvlLogo from "assets/logo-primary.svg";
import AvatarDefault from "assets/avatar-default.png";

import { ManagerInfoResponse } from "utils/types";
import { useChangeCommunicationLang } from "resources/common/useChangeCommunicationLang";
import trackEvents from "constants/trackEvents";

const CHANGE_PREFERENCE_LANG_ALERT_DURATION = 30000; // half a minute

const Header = () => {
  const userRef = useRef(null);
  const [isProfileOpen, setProfileOpen] = useState(false);
  const { theme } = useTheme();
  const [lang, setLang] = useLang();
  const [isMenuOpen, setIsMenuOpen] = useMenuOpen();
  const { t } = useTranslation("common");
  const [, setAlertDuration] = useAlert();
  const { mutate: logout } = useLogout();
  const { mutateAsync: changeCommunicationLanguage } =
    useChangeCommunicationLang();
  const [selectedVendorIndex, setSelectedVendorIndex] =
    useSelectedVendorIndex();
  const queryClient = useQueryClient();
  const managerInfo =
    queryClient.getQueryData<ManagerInfoResponse>("manager-info");
  const managerVendors = useMemo(
    () => managerInfo?.vendors || [],
    [managerInfo?.vendors]
  );
  const selectedVendor = managerVendors[selectedVendorIndex];
  const events = trackEvents.getCommonEvents();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (managerVendors.length) {
      const vendorId = searchParams.get("vendor_id");
      const vendor = managerVendors[selectedVendorIndex];

      if (vendor?.id !== vendorId) {
        searchParams.set("vendor_id", vendor.id);
        setSearchParams(searchParams, { replace: true });
      } else if (vendorId && !selectedVendorIndex) {
        const vendorIndex = managerVendors.findIndex(
          (info) => info.id === vendorId
        );
        setSelectedVendorIndex(vendorIndex ?? managerVendors?.[0]?.id);
      }
    }
  }, [
    managerVendors,
    searchParams,
    setSelectedVendorIndex,
    selectedVendorIndex,
    setSearchParams,
  ]);

  const handleChangeCommunicationLang = () => {
    changeCommunicationLanguage(lang.code)
      .then(() => {
        showAlert({
          message: t("communication-language-updated-msg"),
          id: "update-communication-language-alert",
          variant: "success",
          mode: "light",
        });

        GA.event({
          ...events.actionChangeCommunicationLanguage,
          label: JSON.stringify({ lang }),
        });
        setAlertDuration(undefined);
      })
      .catch((response) => {
        showAlert({
          message: changeLanguageRetryMsg,
          id: "update-communication-language-alert",
          variant: "error",
          mode: "light",
        });

        GA.event({
          ...events.actionChangeCommunicationLanguageFailed,
          label: JSON.stringify({ label: response.data.message }),
        });
        setAlertDuration(CHANGE_PREFERENCE_LANG_ALERT_DURATION);
      })
      .finally(() => {
        setLang({ ...lang, updatedBeforeReload: false });
      });
  };

  const changeLanguagePreferenceMsg = (
    <div>
      <span>{t("change-language-preference-message")}, </span>
      <span
        sx={{
          textDecoration: "underline",
          cursor: "pointer",
        }}
        onClick={handleChangeCommunicationLang}
      >
        {t("please-click-here")}
      </span>
    </div>
  );
  const changeLanguageRetryMsg = (
    <div>
      <span>{t("communication-language-update-failure-msg")}, </span>
      <span
        sx={{
          textDecoration: "underline",
          cursor: "pointer",
        }}
        onClick={handleChangeCommunicationLang}
      >
        {t("try-again")}
      </span>
    </div>
  );

  const saveLangPreference = (lang: Lang) => {
    setLang({
      ...lang,
      updatedBeforeReload: true,
    });
    setAlertDuration(CHANGE_PREFERENCE_LANG_ALERT_DURATION);
  };

  useEffect(() => {
    if (lang.updatedBeforeReload) {
      showAlert({
        message: changeLanguagePreferenceMsg,
        id: "communication-language-alert",
        variant: "info",
        mode: "light",
      });
      setTimeout(() => {
        setLang({ ...lang, updatedBeforeReload: false });
      }, 0);
      setTimeout(() => {
        setAlertDuration(undefined);
      }, CHANGE_PREFERENCE_LANG_ALERT_DURATION);
    }
  }, []);

  return (
    <TopNavigation sx={{ paddingInlineStart: 18 }}>
      <div sx={{ display: "flex", alignItems: "center" }}>
        <IconButton
          icon={<MenuIcon />}
          sx={{
            svg: {
              width: 20,
            },
          }}
          onClick={() =>
            setIsMenuOpen(isMenuOpen === undefined ? false : !isMenuOpen)
          }
        />
        <Link to="/">
          <img
            src={SwvlLogo}
            alt="Swvl Logo"
            sx={{ height: 32, marginInlineStart: 20, marginInlineEnd: 36 }}
          />
        </Link>
      </div>

      <div
        sx={{
          display: "flex",
          alignItems: "center",
          "& > *:nth-child(1n+2)": {
            marginInlineStart: "spacing-m",
          },
        }}
      >
        {managerInfo && managerVendors.length === 1 && (
          <p
            sx={{
              variant: "text.p-medium-medium",
              color: "content-primary",
              my: 0,
            }}
          >
            {selectedVendor?.name}
          </p>
        )}

        {managerInfo && managerVendors.length > 1 && (
          <Menu
            menuButton={
              <div
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginInlineEnd: "spacing-xs",
                }}
              >
                {selectedVendor?.name}
              </div>
            }
          >
            {managerInfo.vendors.map((vendor, index) => (
              <MenuItem
                key={vendor.id}
                active={selectedVendor?.id === vendor?.id}
                onClick={() => {
                  setSelectedVendorIndex(index);
                }}
              >
                {vendor?.name}
              </MenuItem>
            ))}
          </Menu>
        )}

        <Menu
          menuButton={
            <div
              sx={{
                display: "flex",
                alignItems: "center",
                marginInlineEnd: "spacing-xs",
              }}
            >
              <GlobeIcon sx={{ marginInlineEnd: "spacing-xxs" }} />
              {lang.displayName}
            </div>
          }
        >
          {localizationLanguages
            ? localizationLanguages.map((language) => (
                <MenuItem
                  key={language.code}
                  active={lang.code === language.code}
                  onClick={() => {
                    setLang(language);
                    saveLangPreference(language);
                    window.location.reload();
                  }}
                >
                  {language.name}
                </MenuItem>
              ))
            : null}
        </Menu>
        <div>
          <div ref={userRef}>
            <Avatar
              src={managerInfo?.user.picture || AvatarDefault}
              title={managerInfo?.user.name}
              alt="Manager Picture"
              size="32px"
              onClick={() => setProfileOpen(true)}
              sx={{
                cursor: "pointer",
                boxShadow: isProfileOpen
                  ? `0px 0px 0px 2px ${theme.colors.secondary}`
                  : null,
                transition: "box-shadow .2s",

                "&:hover": {
                  boxShadow: `0px 0px 0px 2px ${theme.colors.secondary}`,
                },
              }}
            />
          </div>
          <ControlledMenu
            state={isProfileOpen ? "open" : "closed"}
            onClose={() => setProfileOpen(false)}
            anchorRef={userRef}
            sx={{
              ".szh-menu": {
                width: 224,
              },
            }}
          >
            <MenuHeader sx={{ px: "spacing-xs" }}>
              <div
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  pt: "spacing-xs",
                  mb: "spacing-m",
                }}
              >
                <Avatar
                  src={managerInfo?.user.picture || AvatarDefault}
                  title={managerInfo?.user.name}
                  alt="Manager Picture"
                  size="64px"
                />
                <p
                  sx={{
                    variant: "text.p-medium-medium",
                    my: "spacing-xs",
                    mb: 0,
                    textTransform: "none",
                    color: `${theme.colors["content-primary"]} !important`,
                  }}
                >
                  {managerInfo?.user.name}
                </p>
                <p
                  sx={{
                    variant: "text.p-x-small",
                    my: 0,
                    textTransform: "none",
                    color: `${theme.colors["content-primary"]} !important`,
                  }}
                >
                  {managerInfo?.user.email}
                </p>
              </div>
            </MenuHeader>
            <MenuDivider sx={{ mx: -8 }} />
            <MenuItem onClick={() => logout()}>
              <p
                sx={{
                  my: -8,
                  py: 8,
                  textAlign: "center",
                  width: "100%",
                  variant: "text.p-medium-medium",

                  "&:hover": { textDecoration: "underline" },
                }}
              >
                {t("logout", { ns: "login" })}
              </p>
            </MenuItem>
          </ControlledMenu>
        </div>
      </div>
    </TopNavigation>
  );
};

export default Header;
