import { useMutation } from "react-query";
import { useQueryClient } from "react-query";

import useMenuOpen from "hooks/useMenuOpen";
import { useAuthContext } from "contexts/authContext";

import apiInstance from "api";

type LogoutRequest = {
  refresh_token: string;
};

const logoutUser = async (data: LogoutRequest) => {
  return apiInstance.post("sign_out", data);
};

const useLogout = () => {
  const { refreshToken, removeAuthData, setManagerVendorLoadedInInterceptor } =
    useAuthContext();
  const [, setMenuOpen] = useMenuOpen();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    () => logoutUser({ refresh_token: refreshToken }),
    {
      mutationKey: "user/logout",
      onSuccess: () => {
        removeAuthData();
        setMenuOpen(undefined);
        queryClient.removeQueries("manager-info");
        setManagerVendorLoadedInInterceptor(false);
      },
    }
  );

  return mutation;
};

export { useLogout };
