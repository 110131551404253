import { useMutation } from "react-query";
import { showAlert } from "@swvl/alert";

import apiInstance from "api";

// Types
interface VerifyUserEmailRequest {
  token: string;
}

interface VerifyUserEmailResponse {
  message: string;
}

// Resources
const verifyUserEmail = async (data: VerifyUserEmailRequest) => {
  return await apiInstance.post<
    VerifyUserEmailRequest,
    VerifyUserEmailResponse
  >("/verify_email", data);
};

const useVerifyUserEmail = () => {
  const mutation = useMutation(verifyUserEmail, {
    mutationKey: "/verify_email",
    onSuccess: ({ message }) => {
      showAlert({
        message,
        variant: "success",
        mode: "light",
      });
    },
    onError: undefined,
  });

  return mutation;
};

export { useVerifyUserEmail };
