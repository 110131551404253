import { useMutation } from "react-query";

import apiInstance from "api";

import { Bus, AutoCompleteOption } from "utils/types";

export interface BusQueryResponse {
  hits: Bus[];
}

export interface SnakeCasedQueryResponse {
  hits: {
    _id: string;
    _score: number;
    _source: {
      deleted: boolean;
      city: string;
      name: string;
      description: string;
      seats: number;
      createdAt: string;
      updatedAt: string;
    };
  }[];
}

export type AssignReassignRequestParams = {
  q: string;
  bus_type_id?: string;
  captain_id?: string;
};

const transformResponse = ({
  hits,
}: BusQueryResponse): AutoCompleteOption[] => {
  return hits.map((option) => ({
    label: option.year ? `${option.plates} (${option.year})` : option.plates,
    labelInfo: option.busTypeName,
    value: option,
  }));
};

const transformAutoCompleteResponse = ({
  hits,
}: SnakeCasedQueryResponse): AutoCompleteOption[] => {
  return hits.map((option) => ({
    label: option?._source?.name,
    labelInfo: option?._source?.description,
    value: option,
  }));
};

const queryBus = async (params: AssignReassignRequestParams) => {
  const response = await apiInstance.get<
    AssignReassignRequestParams,
    BusQueryResponse
  >("bus/autocomplete", { params });

  return transformResponse(response);
};

const queryBusType = async (params: AssignReassignRequestParams) => {
  const response = await apiInstance.get<
    AssignReassignRequestParams,
    SnakeCasedQueryResponse
  >("bus_type/autocomplete", { params });

  return transformAutoCompleteResponse(response);
};

const queryDistricts = async (params: AssignReassignRequestParams) => {
  const response = await apiInstance.get<
    AssignReassignRequestParams,
    SnakeCasedQueryResponse
  >("district/autocomplete", { params });

  return transformAutoCompleteResponse(response);
};

const useQueryBus = (query = "") => {
  return useMutation(["bus-autocomplete", query], queryBus);
};

const useQueryBusTypes = (query = "") => {
  return useMutation(["bus-type-autocomplete", query], queryBusType);
};

const useQueryDistricts = (query = "") => {
  return useMutation(["districts-autocomplete", query], queryDistricts);
};

export { useQueryBus, useQueryBusTypes, useQueryDistricts };
