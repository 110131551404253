/** @jsxImportSource theme-ui */
import omitBy from "lodash/fp/omitBy";
import isNil from "lodash/isNil";
import { useEffect } from "react";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Spinner } from "theme-ui";
import queryString from "query-string";
import { useQueryClient } from "react-query";
import { AxiosResponse } from "axios";

import Header from "components/Header";
import SideNav from "components/SideNav";
import NotFound from "components/NotFound";
import VerificationPopup from "./VerificationPopup";

import { useAuthContext } from "contexts/authContext";
import { useManagerInfo } from "resources/auth/useManagerInfo";
import useSelectedVendor from "hooks/useSelectedVendor";
import useFreshDeskWidget from "hooks/useFreshDeskWidget";
import { useVerifyUserEmail } from "resources/auth/useVerifyUserEmail";
import { ManagerInfoResponse, FreshDeskArgs } from "utils/types";

import RidesManagement from "pages/rides-management";
import Fleet from "pages/fleet";

const removeUndefined = omitBy(isNil);

const getKeysToHide = (object: { [key: string]: string }) => {
  const keysToHide = removeUndefined({
    name: object.name,
    "custom_fields.cf_country": object.country_name,
    "custom_fields.cf_city": object.city_name,
    "custom_fields.cf_vendorpartner_id": object.id,
    "custom_fields.cf_ticket_form": true,
  });
  return keysToHide;
};

const Dashboard = () => {
  const { t } = useTranslation("common");
  const { isManagerVendorLoadedInInterceptor } = useAuthContext();
  const { isLoading: isManagerInfoLoading } = useManagerInfo();
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryClient = useQueryClient();
  const selectedVendor = useSelectedVendor();
  const { customizeForm } = useFreshDeskWidget();

  const {
    mutateAsync: verifyUserEmail,
    isLoading: isVerifyingToken,
    status: verifyEmailAPIStatus,
    error: verifyEmailError,
  } = useVerifyUserEmail();

  const managerInfo =
    queryClient.getQueryData<ManagerInfoResponse>("manager-info");

  const { verificationToken } = queryString.parse(search);

  const isEmailVerified = managerInfo && managerInfo.user.has_verified_email;
  const isVerificationNeeded =
    isManagerVendorLoadedInInterceptor &&
    managerInfo &&
    !managerInfo.user.has_verified_email &&
    verificationToken;

  useEffect(() => {
    if (isVerificationNeeded) {
      verifyUserEmail({ token: `${verificationToken}` }).then(() => {
        queryClient.invalidateQueries(["manager-info"]);
      });
    }
  }, [verificationToken, managerInfo, isManagerVendorLoadedInInterceptor]);

  useEffect(() => {
    if (isManagerVendorLoadedInInterceptor && managerInfo) {
      const freshDeskForm: FreshDeskArgs = {
        email: managerInfo.user.email,
        name: selectedVendor.name,
        custom_fields: {
          cf_country: selectedVendor.country_name,
          cf_city: selectedVendor.city_name,
          cf_ticket_form: "Captain Support",
          cf_vendorpartner_phone_number: managerInfo.user.phone,
          cf_vendorpartner_id: selectedVendor.id,
        },
      };

      customizeForm("prefill", "ticketForm", freshDeskForm);
      customizeForm(
        "hide",
        "ticketForm",
        Object.keys(getKeysToHide(selectedVendor))
      );
    }
  }, [isManagerVendorLoadedInInterceptor, managerInfo, selectedVendor]);

  if (isManagerVendorLoadedInInterceptor && !isEmailVerified) {
    return (
      <VerificationPopup
        managerInfo={managerInfo as ManagerInfoResponse}
        isVerifyingToken={isVerifyingToken}
        verifyEmailAPIStatus={verifyEmailAPIStatus}
        verifyEmailError={verifyEmailError as AxiosResponse}
        showResendButton={
          verifyEmailAPIStatus !== "success" || !isVerifyingToken
        }
      />
    );
  }

  return (
    <div
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header />
      <div sx={{ flexGrow: 1, display: "flex" }}>
        <SideNav />
        <main
          sx={{
            flexGrow: 1,
            pb: "spacing-l",
            overflow: "auto",
            position: "relative",
          }}
        >
          {isManagerVendorLoadedInInterceptor &&
            managerInfo?.user.has_verified_email && (
              <Routes>
                <Route
                  index
                  element={<Navigate to="rides-management" replace />}
                />
                <Route
                  key="Rides Management"
                  path="rides-management/*"
                  element={<RidesManagement />}
                />
                <Route key="Fleet" path="fleet/*" element={<Fleet />} />
                <Route
                  path="*"
                  element={
                    <NotFound
                      title={t("not-found-title")}
                      description={t("not-found-description")}
                      goBackCallback={() =>
                        navigate("/rides-management/rides", { replace: true })
                      }
                    />
                  }
                />
              </Routes>
            )}

          {isManagerInfoLoading && !isManagerVendorLoadedInInterceptor && (
            <div
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <Spinner color="secondary" />
              <h5 sx={{ variant: "text.h5" }}>{t("loading-profile")}</h5>
            </div>
          )}
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
