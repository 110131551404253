import { useAuthContext } from "contexts/authContext";
import { useQuery } from "react-query";
import apiInstance from "api";
import { DOCUMENT_CATEGORIES, DOCUMENT_TYPES } from "pages/fleet/constants";

export type DocumentTypesType = keyof typeof DOCUMENT_TYPES;
export interface CountryConfig {
  captain: {
    [key in DocumentTypesType]: {
      mandatory: boolean;
      document_category: keyof typeof DOCUMENT_CATEGORIES;
    };
  };
  bus: {
    [key in DocumentTypesType]: {
      mandatory: boolean;
      document_category: keyof typeof DOCUMENT_CATEGORIES;
    };
  };
}

interface DocumentVerificationsList {
  document_categories: string[];
  document_types: string[];
}

const getCountryConfig = (cityId: string) => {
  if (!cityId) return;

  return apiInstance.get<unknown, CountryConfig>(
    `configs/country-applicable-documents`,
    {
      params: {
        city_id: cityId,
      },
    }
  );
};

const getDocumentVerifications = () => {
  return apiInstance.get<unknown, DocumentVerificationsList>(
    `configs/document-verifications`
  );
};

const useCountryConfig = (cityId = "") => {
  const { dynamicKeys } = useAuthContext();
  return useQuery(
    ["country-config", cityId, dynamicKeys],
    () => getCountryConfig(cityId),
    {
      staleTime: Infinity,
    }
  );
};

const useDocumentVerifications = () => {
  const { dynamicKeys } = useAuthContext();

  return useQuery(["document-verifications-list", dynamicKeys], () =>
    getDocumentVerifications()
  );
};

export { useCountryConfig, useDocumentVerifications };
