import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { AxiosResponse } from "axios";

import GA from "react-ga";

import { useAuthContext } from "contexts/authContext";
import apiInstance from "api";
import { LoginResponseTransform, LoginResponse } from "utils/types";
import trackEvents from "constants/trackEvents";

// Types
interface LoginRequest {
  email: string;
  password: string;
}

// Transforms
const transformResponse = ({
  access_token: accessToken,
  refresh_token: refreshToken,
  token_type: tokenType,
  expires_in,
  message,
}: LoginResponse): LoginResponseTransform => {
  const expiresInMS = expires_in * 1000;

  return {
    accessToken,
    refreshToken,
    tokenType,
    expiresIn: expiresInMS,
    message,
    logInTimeStamp: Date.now(),
  };
};

// Resources
const loginUser = async (data: LoginRequest) => {
  const response = await apiInstance.post<LoginRequest, LoginResponse>(
    "sign_in",
    data
  );
  return transformResponse(response);
};

const useLogin = () => {
  const location = useLocation();
  const { setAuthData } = useAuthContext();
  const events = trackEvents.getLoginEvents();

  const navigate = useNavigate();

  const mutation = useMutation(loginUser, {
    mutationKey: "user/login",
    onSuccess: ({
      accessToken,
      refreshToken,
      tokenType,
      expiresIn,
      logInTimeStamp,
    }) => {
      GA.event(events.actionLoginSuccess);
      setAuthData({
        accessToken,
        refreshToken,
        tokenType,
        expiresIn,
        logInTimeStamp,
      });
      const searchParams = queryString.parse(location.search, {
        arrayFormat: "bracket",
        parseBooleans: true,
        parseNumbers: true,
      });
      if (searchParams.back) {
        const back = decodeURIComponent(searchParams.back as string);
        navigate(back);
      } else {
        navigate("/");
      }
    },
    onError: (response: AxiosResponse) => {
      if (!response) return;

      GA.event({
        ...events.actionLoginFailed,
        label: response.data.message,
      });
    },
  });
  return mutation;
};

export { useLogin };
