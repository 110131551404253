/** @jsxImportSource theme-ui */
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import startCase from "lodash/startCase";
import { Spinner } from "theme-ui";

import Tag from "@swvl/tag";
import TabsWrapper, { TabWrapper } from "@swvl/tabs";
import { IconButton } from "@swvl/button";
import { ArrowLeftIcon, ArrowRightIcon } from "@swvl/icons";
import { useTheme } from "@swvl/theme";

import GA from "react-ga";

import NotFound from "components/NotFound";
import useLang from "hooks/useLang";
import { useCaptainDetails } from "resources/common/useCaptainDetails";
import { BAD_REQUEST, NOT_FOUND } from "constants/httpCode";
import trackEvents from "constants/trackEvents";
import { InfoWrapper, InfoWrapperItem } from "utils/styled";
import {
  CAPTAINS_DETAILS_TABS,
  STATUS_COLOR,
  STATUS_LABEL,
  DOCUMENT_STATUS_COLOR,
} from "./constants";
import { UploadCaptainPhoto } from "./UploadCaptainPhoto";
import CaptainLocation from "./CaptainLocation";
import VerifiableDocuments from "./VerifiableDocuments";
import CaptainRatingAndReviews from "./CaptainRatingAndReviews";
import StarRating from "./StarRating";
import { truncateDecimalPoints } from "utils";
import CaptainVehicles from "./CaptainVehicles";
import NoData from "components/NoData";

const CaptainDetails = () => {
  const DOCUMENT_TAB_KEY = "documents";
  const { t } = useTranslation("captains");
  const [lang] = useLang();
  const { id: captainId, key } = useParams();
  const navigate = useNavigate();
  const { theme } = useTheme();

  useEffect(() => {
    if (key && !CAPTAINS_DETAILS_TABS.includes(key)) {
      navigate("/captains/not-found");
    }

    if (key === DOCUMENT_TAB_KEY) {
      handleDocumentTabEvents();
    }
  }, [key]);

  const {
    data: captain,
    isLoading: isCaptainDetailLoading,
    error,
  } = useCaptainDetails(captainId);

  const events = trackEvents.getCaptainEvents();

  useEffect(() => {
    GA.event({
      ...events.screenCaptainInfo,
      label: JSON.stringify({ captain_id: captainId }),
    });
  }, []);

  useEffect(() => {
    if (!captain) return;

    GA.event({
      ...events.actionOpenCaptainInfo,
      label: JSON.stringify(captain),
    });

    return () => {
      GA.event({
        ...events.actionCloseCaptainInfo,
        label: JSON.stringify(captain),
      });
    };
  }, [captain]);

  const handleDocumentTabEvents = () => {
    GA.event({
      ...events.screenCaptainDocuments,
      label: JSON.stringify({ captain_id: captainId }),
    });
    GA.event({
      ...events.actionOpenCaptainDocuments,
      label: JSON.stringify(captain),
    });
  };

  const captainTabClicked = (tab: string) => {
    if (tab === DOCUMENT_TAB_KEY) handleDocumentTabEvents();
    if (tab === "info") {
      navigate(`/fleet/captains/${captainId}`);
    } else {
      navigate(`/fleet/captains/${captainId}/${tab}`);
    }
  };

  const goBack = () => navigate("/fleet/captains", { replace: true });
  if (error) {
    const { status } = error;

    if (status === BAD_REQUEST || status === NOT_FOUND) {
      return (
        <NotFound
          title={t("not-found-title", { ns: "common" })}
          description={t("not-found-description", { ns: "common" })}
          goBackCallback={goBack}
        />
      );
    }
  }

  if (isCaptainDetailLoading) {
    return (
      <div
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Spinner color="secondary" />{" "}
      </div>
    );
  }

  if (!captain) return null;

  return (
    <div
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "spacing-m",
          background: "linear-gradient(0deg, #FFF 0%, #F4F7FF 100%)",
        }}
      >
        <IconButton
          sx={{
            marginInlineEnd: "spacing-m",
            "& svg": { fill: theme.colors["gray-700"] },
          }}
          icon={lang.code === "ar" ? <ArrowRightIcon /> : <ArrowLeftIcon />}
          onClick={goBack}
        />
        <div
          sx={{
            display: "flex",
            alignItems: "self-end",
            gap: 24,
          }}
        >
          <UploadCaptainPhoto picture={captain?.picture} />

          <div>
            <h4
              title={captain?.name}
              sx={{
                color: "content-primary",
                variant: "text.h4",
                marginTop: "spacing-s",
                my: "spacing-xs",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {captain?.name || "-"}
            </h4>
            <div sx={{ display: "flex", alignItems: "center", gap: 16 }}>
              <Tag
                variant={
                  captain?.active
                    ? STATUS_COLOR["active"]
                    : STATUS_COLOR["inactive"]
                }
              >
                {startCase(
                  t(
                    captain?.active
                      ? STATUS_LABEL["active"]
                      : STATUS_LABEL["inactive"]
                  )
                )}
              </Tag>
              <div
                sx={{
                  backgroundColor: "content-quarternary",
                  height: 4,
                  width: 4,
                  borderRadius: 100,
                }}
              />
              <div sx={{ display: "flex", alignItems: "center", gap: 4 }}>
                <StarRating rating={truncateDecimalPoints(captain?.score)} />
                <p
                  sx={{
                    margin: 0,
                    color: "content-primary",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  {captain?.score > 0 ? captain?.score : t("captain-unrated")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div sx={{ marginTop: "spacing-s", px: "spacing-m", pt: "spacing-m" }}>
        <TabsWrapper
          defaultActiveKey={key ?? "info"}
          tabSize="medium"
          onTabClick={captainTabClicked}
        >
          <TabWrapper tab={t("information")} key={"info"}>
            <div
              sx={{
                paddingTop: "spacing-m",
                overflow: "auto",
                flexGrow: "1",
              }}
            >
              <InfoWrapper>
                <InfoWrapperItem>
                  <p>{t("captain-phone")}</p>
                  <p>{captain?.phone || "-"}</p>
                </InfoWrapperItem>
                <InfoWrapperItem>
                  <p>{t("captain-city")}</p>
                  <p>{captain?.city?.name || "-"}</p>
                </InfoWrapperItem>
                <InfoWrapperItem>
                  <p>{t("captain-documents-status")}</p>
                  <Tag
                    variant={
                      DOCUMENT_STATUS_COLOR[
                        captain?.document_verification_status
                      ]
                    }
                  >
                    {t(
                      `document-verification-statuses.${captain?.document_verification_status}`
                    )}
                  </Tag>
                </InfoWrapperItem>
              </InfoWrapper>
            </div>{" "}
          </TabWrapper>
          <TabWrapper tab={t("rating-reviews.title")} key={"ratings"}>
            <CaptainRatingAndReviews captainRating={captain?.score} />
          </TabWrapper>
          <TabWrapper tab={t("documents")} key={DOCUMENT_TAB_KEY}>
            {/* Personal Documents */}

            <div
              sx={{
                overflow: "auto",
                flexGrow: "1",
                marginTop: 24,
              }}
            >
              <InfoWrapper>
                <VerifiableDocuments
                  verifiableName={captain?.name}
                  verifiableType="captain"
                  cityId={captain?.city?._id}
                  noDataComponent={
                    <NoData title={t("No-documents-available")} />
                  }
                />
              </InfoWrapper>
            </div>
          </TabWrapper>
          <TabWrapper
            tab={t("location-information.tab-title")}
            key={"location"}
          >
            <CaptainLocation captainId={captain?._id} />
          </TabWrapper>
          <TabWrapper tab={t("vehicle-details.tab-title")} key={"vehicles"}>
            <CaptainVehicles />
          </TabWrapper>
        </TabsWrapper>
      </div>
    </div>
  );
};

export default CaptainDetails;
