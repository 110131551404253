import { useState, useContext, createContext, FC } from "react";

import useAuthData from "hooks/useAuthData";

import { AuthenticationModel } from "utils/types";

type UpdateState<T> = (newValue: T | ((value: T) => T)) => void;

interface AuthContextType extends AuthenticationModel {
  isManagerVendorLoadedInInterceptor: boolean;
  setManagerVendorLoadedInInterceptor: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setAuthData: UpdateState<AuthenticationModel>;
  removeAuthData: () => void;
  dynamicKeys: string;
  setDynamicKeys: React.Dispatch<React.SetStateAction<string>>;
}

export const AuthContext = createContext<AuthContextType>({
  isManagerVendorLoadedInInterceptor: false,
  setManagerVendorLoadedInInterceptor: () => false,
  accessToken: "",
  refreshToken: "",
  tokenType: "",
  expiresIn: 8.64e7,
  logInTimeStamp: Date.now(),
  setAuthData: () => false,
  removeAuthData: () => false,
  dynamicKeys: "",
  setDynamicKeys: () => false,
});

const AuthProvider: FC = ({ children }) => {
  const [authData, setAuthData, { removeItem: removeAuthData }] = useAuthData();
  const [
    isManagerVendorLoadedInInterceptor,
    setManagerVendorLoadedInInterceptor,
  ] = useState<boolean>(false);
  const [dynamicKeys, setDynamicKeys] = useState("");

  return (
    <AuthContext.Provider
      value={{
        ...authData,
        isManagerVendorLoadedInInterceptor,
        setManagerVendorLoadedInInterceptor,
        setAuthData,
        removeAuthData,
        dynamicKeys,
        setDynamicKeys,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    console.error("useAuthContext must be used within a AppContextProvider");
  }
  return context;
};

export default AuthProvider;
