import apiInstance from "api";
import { useMutation, useQuery } from "react-query";
import { useAuthContext } from "contexts/authContext";
import { useTranslation } from "react-i18next";
import { showAlert } from "@swvl/alert";
import { AxiosResponse } from "axios";
import { DOCUMENT_CATEGORIES, VERIFIABLE_TYPES } from "pages/fleet/constants";

export const DOCUMENT_STATUSES = {
  PENDING: "pending",
  REJECTED: "rejected",
  EXPIRED: "expired",
  EXPIRING_SOON: "expiring_soon",
  VERIFIED: "verified",
};

export interface VerifiableDocumentDetail {
  _id: string;
  notes: { approver: string; creator: string };
  isExpirable: boolean;
  isExpiringSoon: boolean;
  status: string;
  documentsPath: string[];
  verifiableId: string;
  verifiableType: string;
  documentCategory: string;
  documentType: string;
  documentNumber: string;
  issuedAt: string;
  expiredAt: string;
  createdAt: string;
  updatedAt: string;
  verifiable: { plates: string; title: string; _id: string };
}

interface DocumentResponse {
  isUploadable: boolean;
  documents: {
    [name: string]: VerifiableDocumentDetail;
  };
}
export interface VerifiableDocumentsResponse {
  [key: string]: DocumentResponse;
}

const getDocuments = async (
  verifiableId: string,
  verifiableType: keyof typeof VERIFIABLE_TYPES
) => {
  const response = await apiInstance.get<unknown, VerifiableDocumentsResponse>(
    `document_verification/${verifiableType}/${verifiableId}/`
  );
  return response;
};

const useVerifiableDocuments = (
  verifiableId = "",
  verifiableType: keyof typeof VERIFIABLE_TYPES
) => {
  const { dynamicKeys } = useAuthContext();

  return useQuery(
    ["verifiable-documents", verifiableId, verifiableType, dynamicKeys],
    () => getDocuments(verifiableId, verifiableType),
    {
      staleTime: Infinity,
    }
  );
};

type DocumentMetaData = {
  documents: File[];
  verifiableType: keyof typeof VERIFIABLE_TYPES;
  verifiableId: string;
  documentCategory: keyof typeof DOCUMENT_CATEGORIES;
  documentType: string;
  documentNumber: string;
  isExpirable: boolean;
  issuedAt: string;
  expiredAt: string;
  notes: string;
};

type DocumentMetaDataUpdate = {
  documentId: string;
  documents: File[];
  documentNumber: string;
  isExpirable: boolean;
  issuedAt: string;
  expiredAt: string;
  notes: string;
};

const createVerifiableDocument = (data: DocumentMetaData) => {
  const formData = new FormData();
  if (data.documents[0]) formData.append("documents", data.documents[0]);
  // formData.append("verifiableType", data.verifiableType);
  // formData.append("verifiableId", data.verifiableId);
  formData.append("documentCategory", data.documentCategory);
  formData.append("documentType", data.documentType);
  formData.append("isExpirable", String(data.isExpirable));
  if (data.documentNumber)
    formData.append("documentNumber", data.documentNumber);
  if (data.issuedAt) formData.append("issuedAt", data.issuedAt);
  if (data.isExpirable) formData.append("expiredAt", data.expiredAt);
  if (data.notes) formData.append("notes", data.notes);

  return apiInstance.post(
    `document_verification/${data.verifiableType}/${data.verifiableId}/`,
    formData
  );
};

const updateVerifiableDocument = (data: DocumentMetaDataUpdate) => {
  const formData = new FormData();
  if (data.documents[0]) formData.append("documents", data.documents[0]);
  formData.append("isExpirable", String(data.isExpirable));
  if (data.documentNumber)
    formData.append("documentNumber", data.documentNumber);
  if (data.issuedAt) formData.append("issuedAt", data.issuedAt);
  if (data.isExpirable) formData.append("expiredAt", data.expiredAt);
  if (data.notes) formData.append("notes", data.notes);

  return apiInstance.patch(
    `document_verification/${data?.documentId}/resubmit`,
    formData
  );
};

const useCreateVerifiableDocument = () => {
  const { dynamicKeys } = useAuthContext();
  const { t } = useTranslation("captains");

  return useMutation(
    ["document-verification-create", dynamicKeys],
    (data: DocumentMetaData) => createVerifiableDocument(data),
    {
      onSuccess: () => {
        showAlert({
          message: t("verification-data-saved"),
          id: "document-verification-success-alert",
          variant: "success",
          mode: "light",
          autoClose: 5000,
        });
      },
      onError: (response: AxiosResponse) => {
        if (!response) return;

        const path = response?.data?.path ? response.data.path[0] : "";
        showAlert({
          message: `${path ? path + " " : ""}${response?.data?.message}`,
          id: "document-verification-failure-alert",
          variant: "error",
          mode: "light",
          autoClose: 5000,
        });
      },
    }
  );
};

const useUpdateVerifiableDocument = () => {
  const { dynamicKeys } = useAuthContext();
  const { t } = useTranslation("captains");

  return useMutation(
    ["document-verifications-update", dynamicKeys],
    (data: DocumentMetaDataUpdate) => updateVerifiableDocument(data),
    {
      onSuccess: () => {
        showAlert({
          message: t("verification-data-saved"),
          id: "document-verification-success-alert",
          variant: "success",
          mode: "light",
          autoClose: 5000,
        });
      },
      onError: (response: AxiosResponse) => {
        if (!response) return;

        const path = response?.data?.path ? response.data.path[0] : "";
        showAlert({
          message: `${path ? path + " " : ""}${response?.data?.message}`,
          id: "document-verification-failure-alert",
          variant: "error",
          mode: "light",
          autoClose: 5000,
        });
      },
    }
  );
};

export {
  useVerifiableDocuments,
  useCreateVerifiableDocument,
  useUpdateVerifiableDocument,
};
