import * as React from "react";

const StarIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.71874 12.0261L7.99999 10.0469L11.2812 12.0521L10.4219 8.30214L13.3125 5.80214L9.5104 5.4636L7.99999 1.92193L6.48957 5.43756L2.68749 5.7761L5.57811 8.30214L4.71874 12.0261ZM7.99999 11.273L4.21796 13.5566C4.10702 13.6113 4.00415 13.6333 3.90936 13.6227C3.81457 13.612 3.72247 13.5792 3.63306 13.5245C3.54348 13.4697 3.47533 13.3912 3.42863 13.2891C3.38193 13.187 3.37794 13.0765 3.41665 12.9576L4.42238 8.67662L1.09504 5.79016C1.00146 5.71395 0.939658 5.6228 0.909623 5.51672C0.879588 5.41047 0.885925 5.30865 0.928634 5.21126C0.971342 5.11369 1.02881 5.03417 1.10103 4.97271C1.17308 4.91143 1.27056 4.87002 1.39348 4.8485L5.78436 4.46594L7.49712 0.411516C7.54382 0.296585 7.61162 0.213773 7.70051 0.163079C7.7894 0.112385 7.88922 0.0870361 7.99999 0.0870361C8.11075 0.0870361 8.21058 0.112385 8.29947 0.163079C8.38836 0.213773 8.45615 0.296585 8.50285 0.411516L10.2156 4.46594L14.6065 4.8485C14.7294 4.87002 14.8269 4.91143 14.8989 4.97271C14.9712 5.03417 15.0286 5.11369 15.0713 5.21126C15.114 5.30865 15.1204 5.41047 15.0904 5.51672C15.0603 5.6228 14.9985 5.71395 14.9049 5.79016L11.5776 8.67662L12.5833 12.9576C12.622 13.0765 12.618 13.187 12.5713 13.2891C12.5246 13.3912 12.4565 13.4697 12.3669 13.5245C12.2775 13.5792 12.1854 13.612 12.0906 13.6227C11.9958 13.6333 11.893 13.6113 11.782 13.5566L7.99999 11.273Z"
        fill={props.fill ?? "#2356F9"}
      />
    </svg>
  );
};

export default StarIcon;
