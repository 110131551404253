/** @jsxImportSource theme-ui */
import { useEffect, useRef, useState } from "react";
import { useTheme } from "@swvl/theme";
import {
  CloseCircleIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@swvl/icons";
import {
  RideAppliedFilters,
  VehicleAppliedFilters,
} from "resources/rides-list/useListRides";
import FilterTag from "components/FilterTag";
import useLang from "hooks/useLang";

type FormattedFiltersType = {
  filterObj: { title: string; value: string | string[] };
  key: string;
  index: number;
};

interface AppliedFiltersProps {
  title: string;
  filters: RideAppliedFilters | VehicleAppliedFilters;
  clearAll: () => void;
  removeFilter: ({ key, value }: { key: string; value: string }) => void;
}
const AppliedFilters = ({
  title,
  filters,
  clearAll,
  removeFilter,
}: AppliedFiltersProps) => {
  const { theme } = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);
  const [isScrollable, setIsScrollable] = useState(false);
  const [lang] = useLang();

  const isContentOverflowing = () => {
    if (!containerRef.current) return false;
    return containerRef.current.scrollWidth > containerRef.current.clientWidth;
  };

  const horizontalScroll = (direction: string) => {
    if (containerRef.current) {
      containerRef.current.scrollLeft += direction === "right" ? 100 : -100;
    }
  };

  const handleWindowResize = () => {
    setIsScrollable(isContentOverflowing());
  };

  const handleRemoveFilter = ({
    key,
    value,
  }: {
    key: string;
    value: string;
  }) => {
    setIsScrollable(isContentOverflowing());
    removeFilter({ key, value });
  };

  useEffect(() => {
    setIsScrollable(isContentOverflowing());
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [containerRef, isContentOverflowing]);

  const filterValuesFormatted = ({
    filterObj,
    key,
    index,
  }: FormattedFiltersType) => {
    const { value: filterValue, title: filterTitle } = filterObj;
    if (Array.isArray(filterValue)) {
      return filterValue.map((value: string) => (
        <FilterTag
          key={`${filterTitle}-${value}-${index}`}
          value={`${filterTitle}: ${value}`}
          removeFn={() => handleRemoveFilter({ key, value })}
        />
      ));
    } else {
      return (
        <FilterTag
          key={`${filterTitle}-${filterValue}-${index}`}
          value={`${filterTitle}: ${filterValue}`}
          removeFn={() => handleRemoveFilter({ key, value: filterValue })}
        />
      );
    }
  };

  return (
    <div sx={{ borderTop: "1px solid rgba(217, 219, 233, 1)" }}>
      <h4
        sx={{
          fontWeight: 500,
          marginBottom: "10px",
        }}
      >
        {title}
      </h4>
      <div sx={{ position: "relative", maxWidth: "fit-content" }}>
        <div
          ref={containerRef}
          sx={{
            position: "static",
            display: "flex",
            gap: "spacing-xs",
            justifyContent: "start",
            alignItems: "center",
            paddingLeft:
              isScrollable && lang.code === "en"
                ? "40px"
                : lang.code === "en"
                ? "18px"
                : "80px",
            paddingRight:
              isScrollable && lang.code === "en"
                ? "80px"
                : lang.code === "en"
                ? "40px"
                : "18px",
            border: `2px solid ${theme.colors["content-quinary"]}`,
            borderRadius: "8px",
            minWidth: "200px",
            height: "40px",
            marginBottom: "spacing-s",
            width: "fit-content",
            maxWidth: "100%",
            overflowX: "auto",
            msOverflowStyle: "none",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {Object.entries(filters).map(([key, filterObj], index) =>
            filterValuesFormatted({ key, filterObj, index })
          )}
          <div
            sx={{
              position: "absolute",
              display: "flex",
              gap: "spacing-xs",
              width: "fit-content",
              minWidth: isScrollable && lang.code === "en" ? "80px" : "10px",
              padding: `0 ${theme.space["spacing-xs"]}`,
              height: "inherit",
              backgroundColor: "primary-light-100",
              border: "inherit",
              borderLeft: "none",
              borderRadius: "0 8px 8px 0",
              right: "0",
              alignItems: "center",
            }}
          >
            {isScrollable && (
              <HorizontalScrollButton
                icon={ChevronRightIcon}
                color={theme.colors["content-primary"]}
                onMouseDown={() => horizontalScroll("right")}
              />
            )}
            {lang.code === "en" && (
              <CloseCircleIcon
                onClick={clearAll}
                width={"20px"}
                color={theme.colors["content-tertiary"]}
                cursor={"pointer"}
              />
            )}
          </div>
          <div
            sx={{
              position: "absolute",
              display: "flex",
              width: "fit-content",
              gap: "spacing-xs",
              minWidth: isScrollable && lang.code === "ar" ? "80px" : "10px",
              padding: `0 ${theme.space["spacing-xs"]}`,
              height: "inherit",
              backgroundColor: "primary-light-100",
              left: "0",
              alignItems: "center",
              border: "inherit",
              borderRight: "none",
              borderRadius: "8px 0 0 8px",
            }}
          >
            {lang.code === "ar" && (
              <CloseCircleIcon
                onClick={clearAll}
                width={"20px"}
                color={theme.colors["content-tertiary"]}
                cursor={"pointer"}
              />
            )}
            {isScrollable && (
              <HorizontalScrollButton
                icon={ChevronLeftIcon}
                color={theme.colors["content-primary"]}
                onMouseDown={() => horizontalScroll("left")}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppliedFilters;

const HorizontalScrollButton = ({
  icon: IconComponent,
  onMouseDown,
  color,
}: {
  icon: React.ElementType;
  onMouseDown: () => void;
  color: string;
}) => {
  return (
    <IconComponent
      onMouseDown={onMouseDown}
      width={"26px"}
      height={"26px"}
      fontWeight={"normal"}
      color={color}
      cursor={"pointer"}
      sx={{
        borderRadius: "50%",
        boxShadow: "0px 2px 6px 0px rgba(0,0,0,0.14)",
      }}
    />
  );
};
