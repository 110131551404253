import { NavigateFunction } from "react-router-dom";
import queryString from "query-string";
import isPast from "date-fns/isPast";
import { CountryCode, getCountryCallingCode } from "libphonenumber-js";

export const tablePageSizeOptions: number[] = [10, 20, 50, 100];

export const isExpired = (expiry: string) => {
  const expiryDate = new Date(expiry);
  return isPast(expiryDate);
};

export const getFromQuery = (key: string, search: string) => {
  const queryParams = queryString.parse(search, {
    arrayFormat: "bracket",
    parseBooleans: true,
    parseNumbers: true,
  });
  return queryParams[key] as string | string[] | undefined;
};

export const handleQuery = (
  type: "add" | "remove",
  key: string,
  navigate: NavigateFunction,
  search: string,
  value?: string | string[] | null
): void => {
  const searchParams = queryString.parse(search, {
    arrayFormat: "bracket",
    parseBooleans: true,
    parseNumbers: true,
  });

  if (type === "add" && value !== undefined) {
    searchParams[key] = value;
  } else if (type === "remove") {
    delete searchParams[key];
  }

  const stringifiedParams = queryString.stringify(searchParams, {
    arrayFormat: "bracket",
  });
  navigate({
    search: stringifiedParams,
  });
};

export const setQueries = (
  queries: { [key: string]: unknown },
  navigate: NavigateFunction
) => {
  const stringifiedParams = queryString.stringify(queries, {
    arrayFormat: "bracket",
  });
  navigate({ search: stringifiedParams });
};

export const getCountryCallingCodeString = (
  countryCode: CountryCode
): string => {
  return getCountryCallingCode(countryCode).toString();
};

export const getZeroPaddedTime = (date: Date | null) => {
  if (!date) return "00:00";

  // Get hours and minutes from the Date object
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Pad the values with zeros if needed
  const paddedHours = hours < 10 ? "0" + hours : hours;
  const paddedMinutes = minutes < 10 ? "0" + minutes : minutes;

  // Return the formatted time as a string
  return `${paddedHours}:${paddedMinutes}`;
};

export const groupBy = <T>(array: T[], key: keyof T) => {
  return array.reduce((result: { [key: string]: T[] }, currentValue) => {
    const groupKey = currentValue[key] as unknown as string;

    if (!result[groupKey]) {
      result[groupKey] = [];
    }

    result[groupKey].push(currentValue);

    return result;
  }, {});
};

export const truncateDecimalPoints = (num: number) => {
  return Math.floor(num * 10) / 10;
};
