import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import useLang from "hooks/useLang";
import { getElementByIdAsync, querySelectorAllAsync } from "utils/dom";

const useFreshDeskWidget = () => {
  const [lang] = useLang();
  const { t } = useTranslation("common", { keyPrefix: "navigation" });

  useEffect(() => {
    window.FreshworksWidget?.("hide", "launcher");
  }, []);

  useEffect(() => {
    justifyWidget();
  }, [lang.code]);

  function justifyWidget() {
    const launcherButton = document.querySelector(
      `button[title="${t("need-help")}"]`
    ) as HTMLButtonElement;

    if (!launcherButton) return;

    launcherButton.addEventListener("click", () => {
      getElementByIdAsync<HTMLDivElement>({
        id: "freshworks-frame-wrapper",
        target: document.getElementById("freshworks-container"),
      }).then((widgetFrameWrapper) => {
        changeWidgetDirection(widgetFrameWrapper);
      });
    });
  }

  function changeWidgetDirection(widgetFrameWrapper: HTMLDivElement) {
    widgetFrameWrapper.style.bottom = "54px";
    if (lang.code !== "ar") {
      widgetFrameWrapper.style.left = "24px";
      widgetFrameWrapper.style.right = "unset !important";
    }

    const [widgetFrame] = widgetFrameWrapper.getElementsByTagName("iframe");
    const documentBody = widgetFrame.contentDocument?.body;
    if (!documentBody) return;

    if (lang.code === "ar") {
      documentBody?.setAttribute("dir", "rtl");
      querySelectorAllAsync<NodeListOf<HTMLLabelElement>>({
        query: "form label",
        htmlDocument: widgetFrame.contentDocument,
        target: documentBody,
      }).then((labels) => {
        labels.forEach((el) => {
          el.style.textAlign = "right";
        });
      });
    }
  }

  function show() {
    window.FreshworksWidget?.("show");
  }

  function hide() {
    window.FreshworksWidget?.("hide");
  }

  function open() {
    window.FreshworksWidget?.("open");
  }

  function close() {
    window.FreshworksWidget?.("close");
  }

  function customizeForm(
    action: string,
    formName: string,
    freshDeskArgs?: unknown
  ) {
    window.FreshworksWidget?.(action, formName, freshDeskArgs);
  }

  return {
    show,
    hide,
    open,
    close,
    customizeForm,
  };
};

export default useFreshDeskWidget;
