import * as React from "react";

const StarBlueIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 0.833374C8.17787 0.833374 8.34227 0.927827 8.43187 1.08144L10.6593 4.89994L14.7889 5.94143C14.9601 5.9846 15.0958 6.11481 15.146 6.28403C15.1962 6.45325 15.1535 6.63641 15.0336 6.76591L12.212 9.81364L13.2748 14.5574C13.3157 14.7398 13.2514 14.9298 13.1081 15.0498C12.9649 15.17 12.7666 15.2001 12.5941 15.128L8 13.2086L3.40587 15.128C3.2334 15.2001 3.03513 15.17 2.89188 15.0498C2.74864 14.9298 2.68435 14.7398 2.72522 14.5574L3.78802 9.81364L0.966442 6.76591C0.846529 6.63641 0.803789 6.45325 0.854002 6.28403C0.904209 6.11481 1.03992 5.9846 1.21108 5.94143L5.34066 4.89994L7.56813 1.08144C7.65773 0.927827 7.8222 0.833374 8 0.833374Z"
        fill="#2356F9"
      />
    </svg>
  );
};

export default StarBlueIcon;
