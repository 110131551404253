/** @jsxImportSource theme-ui */
import { Fragment, useEffect } from "react";
import { Spinner } from "theme-ui";
import GA from "react-ga";
import isEmpty from "lodash/isEmpty";
import startCase from "lodash/startCase";
import upperFirst from "lodash/upperFirst";
import { isToday, format } from "date-fns";
import { useTranslation, TFunction } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button } from "@swvl/button";
import Tag from "@swvl/tag";
import {
  Timeline,
  TimePill,
  Timestamp,
  TimelineCard,
  TimelineCardHeader,
  TimelineCardContent,
  TimelineCardSubHeader,
} from "@swvl/timeline";

import { useGetActionHistory } from "resources/rides-list/useActionHistory";
import useSelectedVendor from "hooks/useSelectedVendor";

import { timeFormat, dateFormatWithMonthFirst } from "constants/index";
import trackEvents from "constants/trackEvents";

import EmptyStateLogo from "assets/logo-empty-state.svg";
import { ActionHistory } from "utils/types";
import { RidesUserSources } from "./constants";

type RideHistoryProps = {
  rideId: string;
};
const events = trackEvents.getCommonEvents();

enum RideUserTag {
  fms = "warning-light",
  captain = "success-light",
  dashboard = "negative-light",
}

const getActionInfo = (
  history: ActionHistory,
  t: TFunction<"rides", undefined>
) => {
  if (!history.actionData) return;
  const {
    actionData: { captain, reason, bus, busType },
  } = history;
  const rideContext: string =
    history.actionData.status || history.context || history.action;

  const captainDescription = captain && `(${captain.name} - ${captain.phone})`;

  switch (rideContext) {
    case "unassign_captain":
      return {
        description:
          captainDescription &&
          `${t("ride-actions.unassign_captain")} ${captainDescription}`,
        reason,
      };
    case "unassign_bus":
      return {
        description:
          captainDescription &&
          `${t("ride-actions.unassign_bus")} ${captainDescription}`,
        reason,
      };
    case "assign_captain":
      return {
        description:
          captainDescription &&
          `${t("ride-actions.assign_captain")} ${captainDescription}`,
        reason,
      };
    case "assign_bus":
      return {
        description:
          bus &&
          busType &&
          `${t("ride-actions.assign_bus")} (${busType.name} - ${bus.plates})`,
        reason,
      };
    case "reject_ride":
      return {
        reason,
      };
    case "accept_ride":
      return {
        description:
          captainDescription &&
          `${t("ride-actions.accept_ride")} ${captainDescription}`,
      };
    case "stopped":
      return {
        description:
          captainDescription &&
          `${t("ride-actions.stopped")} ${captainDescription}`,
        reason,
      };
    case "cancelled":
      return {
        description:
          captainDescription &&
          `${t("ride-actions.cancelled")} ${captainDescription}`,
        reason,
      };
    case "corporate_revised":
      return {
        description:
          captainDescription &&
          `${t("ride-actions.corporate_revised")} ${captainDescription}`,
        reason,
      };
    case "completed":
      return {
        description:
          captainDescription &&
          `${t("ride-actions.completed")} ${captainDescription}`,
      };
    case "started":
      return {
        description:
          captainDescription &&
          `${t("ride-actions.started")} ${captainDescription}`,
      };
    default:
      return;
  }
};

const ActionDataContent = ({
  actionDataContent,
}: {
  actionDataContent: string;
}) => {
  return (
    <TimelineCardContent>
      <p
        sx={{
          variant: "text.p-small-medium",
          margin: 0,
          padding: 0,
          marginTop: "spacing-xxs",
        }}
      >
        {actionDataContent}
      </p>
    </TimelineCardContent>
  );
};

const ActionDataReason = ({
  reason,
  event,
}: {
  reason: string;
  event: ActionHistory;
}) => {
  const { t } = useTranslation("rides");

  if (event.metaData?.source === RidesUserSources.FLEET_MANAGER) {
    return null;
  }

  return (
    <TimelineCardContent>
      <TimelineCardSubHeader>
        {t("reason", { ns: "common" })}
      </TimelineCardSubHeader>
      <p
        sx={{
          variant: "text.p-small-medium",
          margin: 0,
          padding: 0,
          marginTop: "spacing-xxs",
        }}
      >
        {t(`reasons.${reason}`)}
      </p>
    </TimelineCardContent>
  );
};

const CreatedBy = ({ event }: { event: ActionHistory }) => {
  const { t } = useTranslation("rides");
  const navigate = useNavigate();

  const {
    metaData: { source },
    createdBy,
  } = event;

  const name =
    source !== RidesUserSources.DASHBOARD && createdBy?.name
      ? createdBy?.name
      : "****";

  return (
    <div
      sx={{
        display: "flex",
        marginTop: "spacing-xxs",
      }}
    >
      <p
        sx={{
          margin: 0,
          padding: 0,
          display: "flex",
          alignItems: "center",
          variant: "text.p-small",
          marginInlineEnd: "spacing-xxs",
          button: {
            padding: 0,
            color: "secondary",
            span: {
              variant: "text.p-small-medium",
              boxShadow: "none",
              "&:hover": {
                boxShadow: "none",
              },
            },
          },
        }}
      >
        <span
          sx={{
            marginInlineEnd: "spacing-xxs",
          }}
        >
          {t("by", { ns: "common" })}
        </span>
        {source === RidesUserSources.CAPTAIN ? (
          <Button
            variant="text_link"
            size={"small"}
            onClick={() => navigate(`/fleet/captains/${event.createdBy._id}`)}
          >
            {name}
          </Button>
        ) : (
          <span
            sx={{
              variant: "text.p-small-medium",
              verticalAlign: name !== "****" ? "initial" : "sub",
            }}
          >
            {name}
          </span>
        )}
      </p>
      {event.metaData?.source && (
        <Tag variant={RideUserTag[event.metaData.source]}>
          {startCase(t(`action-created-by.${event.metaData.source}`))}
        </Tag>
      )}
    </div>
  );
};

const TimelineContainer = ({ history }: { history: ActionHistory }) => {
  const { t } = useTranslation("rides");
  const actionHistoryInfo = getActionInfo(history, t);
  return (
    <TimelineCard>
      <TimelineCardContent>
        <TimelineCardHeader>
          {t(
            `ride-actions.${
              history.actionData?.status ||
              history.context ||
              history.action ||
              "N/A"
            }`
          )}
        </TimelineCardHeader>
        <CreatedBy event={history} />
      </TimelineCardContent>
      {actionHistoryInfo?.description && (
        <ActionDataContent actionDataContent={actionHistoryInfo.description} />
      )}
      {actionHistoryInfo?.reason && (
        <ActionDataReason reason={actionHistoryInfo.reason} event={history} />
      )}
    </TimelineCard>
  );
};

const RideHistory = ({ rideId }: RideHistoryProps) => {
  const { t } = useTranslation("rides");
  const selectedVendor = useSelectedVendor();
  const { data: actionHistoryData, isLoading: isActionHistoryLoading } =
    useGetActionHistory({ action_ref: rideId, limit: 50 });

  useEffect(() => {
    GA.event({
      ...events.screenRideHistory,
      label: JSON.stringify({
        id: selectedVendor.id,
        name: selectedVendor.name,
        country: selectedVendor.country_name,
        city: selectedVendor.city_name,
        timestamp: new Date(),
      }),
    });
  }, []);

  const getTimelineDate = (date: string) => {
    let formattedDate = "";
    if (isToday(new Date(date))) {
      formattedDate = upperFirst(t("today", { ns: "common" }));
    } else {
      formattedDate = format(new Date(date), dateFormatWithMonthFirst);
    }
    return formattedDate;
  };

  return (
    <div
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {actionHistoryData && isEmpty(actionHistoryData) && (
        <div
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={EmptyStateLogo} alt="Empty state logo" />
          <h5 sx={{ p: 0, m: 0, mt: "spacing-m-l", variant: "text.h5" }}>
            {t("no-action-history")}
          </h5>
          <p sx={{ p: 0, m: 0, mt: "spacing-xs" }}>{t("no-ride-changes")}</p>
        </div>
      )}
      <div
        sx={{ flexGrow: 1, px: "spacing-m", pb: "spacing-m", overflow: "auto" }}
      >
        {isActionHistoryLoading && (
          <div
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Spinner color="secondary" />
            <h5 sx={{ variant: "text.h5" }}>{t("loading-action-history")}</h5>
          </div>
        )}
        {actionHistoryData && !isEmpty(actionHistoryData) && (
          <Timeline
            title={t("time", { ns: "common" })}
            action={t("activities", { ns: "common" })}
          >
            {Object.keys(actionHistoryData).map((currentDate: string) => (
              <Fragment key={currentDate}>
                <TimePill>{getTimelineDate(currentDate)}</TimePill>
                {Object.keys(actionHistoryData[currentDate]).map(
                  (timestamp: string) => (
                    <Timestamp
                      time={format(new Date(timestamp), timeFormat)}
                      key={`action-history-timestamp-${timestamp}`}
                    >
                      <div style={{ flexGrow: 1 }}>
                        {actionHistoryData[currentDate][timestamp].map(
                          (history: ActionHistory, index: number) => (
                            <TimelineContainer
                              history={history}
                              key={`timeline-container-${index}`}
                            />
                          )
                        )}
                      </div>
                    </Timestamp>
                  )
                )}
              </Fragment>
            ))}
          </Timeline>
        )}
      </div>
    </div>
  );
};

export default RideHistory;
