import { createLocalStorageStateHook } from "use-local-storage-state";

import { AuthenticationModel } from "utils/types";

const useAuthData = createLocalStorageStateHook<AuthenticationModel>(
  "authData",
  {
    accessToken: "",
    refreshToken: "",
    tokenType: "",
    expiresIn: 8.64e7,
    logInTimeStamp: Date.now(),
  }
);

export default useAuthData;
