import apiInstance from "api";
import { useQuery } from "react-query";

import { Captain } from "utils/types";

export interface Bus {
  _id: string;
  plates: string;
  type: {
    name: string;
    _id: string;
  };
  captain: Captain;
}

interface CaptainsBusesResponse {
  page: number;
  limit: number;
  total: number;
  hits: Bus[];
}

const transformResponse = ({
  total,
  page,
  limit,
  hits,
}: CaptainsBusesResponse) => {
  return { total, page, limit, hits };
};

const getCaptainsBuses = async (captain_id: string) => {
  const response = await apiInstance.get<unknown, CaptainsBusesResponse>(
    `/bus?captain_id=${captain_id}`
  );
  return transformResponse(response);
};

const useCaptainsBuses = (captain_id: string) => {
  return useQuery(["captains-buses", captain_id], () =>
    getCaptainsBuses(captain_id)
  );
};

export { useCaptainsBuses };
