import { useRef, useEffect, useState } from "react";
import io from "socket.io-client";
import { useAuthContext } from "contexts/authContext";

const SOCKETS_END_POINT = `${window.__env__.SOCKETS_URL}partner`;

type Location = { lat: number; lng: number; date: string; bearing: number };

const useRideLocation = (rideId: string | undefined) => {
  const { accessToken } = useAuthContext();
  const socketRef = useRef<SocketIOClient.Socket | null>(null);
  const [connecting, setConnecting] = useState<boolean>(false);
  const [connected, setConnected] = useState<boolean>(false);
  const [location, setLocation] = useState<Location | null>(null);

  useEffect(() => {
    if (rideId && accessToken && window.__env__.SOCKETS_URL) {
      socketRef.current = io(SOCKETS_END_POINT, {
        query: { socketToken: accessToken },
        transports: ["websocket"],
        reconnection: true,
        timeout: window.__env__.SOCKETS_TIMEOUT,
      });

      setConnecting(true);

      socketRef.current.on("connect", () => {
        setConnecting(false);
        setConnected(true);
        socketRef.current?.emit("ride:subscribe", {
          rideId,
        });
      });

      socketRef.current.on("disconnect", () => {
        setConnecting(false);
        setConnected(false);
      });

      socketRef.current.on(
        "ride:location:update",
        (data: { location: Location }) => {
          if (!data) return;
          const payload = typeof data === "string" ? JSON.parse(data) : data;
          setLocation(payload.location as Location);
        }
      );

      socketRef.current.on("reconnect_attempt", () => {
        const transports = ["websocket"];
        if (socketRef.current?.io?.opts?.transports)
          socketRef.current.io.opts.transports = transports;
      });

      socketRef.current.on("connect_error", () => {
        setConnecting(false);
        setConnected(false);
      });
    }

    return () => {
      socketRef.current?.close();
    };
  }, [accessToken, rideId]);

  return {
    connecting,
    connected,
    location,
  };
};

export default useRideLocation;
