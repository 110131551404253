import * as React from "react";

const StarFilledIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 11.273L4.21797 13.5566C4.10703 13.6113 4.00417 13.6333 3.90938 13.6227C3.81459 13.612 3.72249 13.5792 3.63308 13.5245C3.54349 13.4697 3.47535 13.3912 3.42865 13.2891C3.38195 13.187 3.37795 13.0765 3.41667 12.9576L4.4224 8.67662L1.09506 5.79016C1.00148 5.71395 0.939673 5.6228 0.909638 5.51672C0.879604 5.41047 0.885941 5.30865 0.928649 5.21126C0.971357 5.11369 1.02882 5.03417 1.10104 4.97271C1.17309 4.91143 1.27058 4.87002 1.39349 4.8485L5.78438 4.46594L7.49714 0.411516C7.54384 0.296585 7.61163 0.213773 7.70052 0.163079C7.78941 0.112385 7.88924 0.0870361 8 0.0870361C8.11077 0.0870361 8.21059 0.112385 8.29948 0.163079C8.38837 0.213773 8.45617 0.296585 8.50287 0.411516L10.2156 4.46594L14.6065 4.8485C14.7294 4.87002 14.8269 4.91143 14.899 4.97271C14.9712 5.03417 15.0286 5.11369 15.0714 5.21126C15.1141 5.30865 15.1204 5.41047 15.0904 5.51672C15.0603 5.6228 14.9985 5.71395 14.905 5.79016L11.5776 8.67662L12.5833 12.9576C12.6221 13.0765 12.6181 13.187 12.5714 13.2891C12.5247 13.3912 12.4565 13.4697 12.3669 13.5245C12.2775 13.5792 12.1854 13.612 12.0906 13.6227C11.9958 13.6333 11.893 13.6113 11.782 13.5566L8 11.273Z"
        fill="#2356F9"
      />
    </svg>
  );
};

export default StarFilledIcon;
