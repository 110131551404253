import apiInstance from "api";
import { format } from "date-fns";
import { useQuery } from "react-query";

import { ActionHistoryResponse, ActionHistoryData } from "utils/types";

import { slashDateFormat } from "constants/index";

type ActionHistoryRequest = {
  action_ref: string;
  limit?: number;
};

const transformData = (actionsHistoryData: ActionHistoryResponse) => {
  const transformedData: ActionHistoryData = {};
  for (const timestamp in actionsHistoryData) {
    const currentDate = format(new Date(timestamp), slashDateFormat);
    transformedData[currentDate] = {
      ...transformedData[currentDate],
      [timestamp]: [...actionsHistoryData[timestamp]],
    };
  }
  return transformedData;
};

const getActionHistory = async (params: ActionHistoryRequest) => {
  const response = await apiInstance.get<
    ActionHistoryRequest,
    ActionHistoryResponse
  >("action_history", { params });
  return transformData(response);
};

const useGetActionHistory = (params: ActionHistoryRequest) => {
  return useQuery(["action-history", params], () => getActionHistory(params));
};

export { useGetActionHistory };
