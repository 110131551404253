import { useMutation } from "react-query";
import { showAlert } from "@swvl/alert";

import apiInstance from "api";

// Types
interface ResendVerificationEmailRequest {
  email: string;
}

interface ResendVerificationEmailResponse {
  message: string;
}

// Resources
const resendVerificationEmail = async (
  data: ResendVerificationEmailRequest
) => {
  return await apiInstance.post<
    ResendVerificationEmailRequest,
    ResendVerificationEmailResponse
  >("/resend_email_verification_link", data);
};

const useResendVerificationEmail = () => {
  const mutation = useMutation(resendVerificationEmail, {
    mutationKey: "/resend_email_verification_link",
    onSuccess: ({ message }) => {
      showAlert({
        message,
        variant: "success",
        mode: "light",
      });
    },
  });

  return mutation;
};

export { useResendVerificationEmail };
