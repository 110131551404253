/** @jsxImportSource theme-ui */
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "@swvl/select";
import Input from "@swvl/input";

import GA from "react-ga";

import Autocomplete from "components/AutocompleteWrapper";
import GroupDatePicker from "components/GroupDatePicker";
import trackEvents from "constants/trackEvents";
import InputGroup from "components/InputGroup";

import { ListRideData } from "resources/rides-list/useListRides";
import { useQueryCaptain } from "resources/common/useQueryCaptain";
import {
  useQueryBus,
  useQueryBusTypes,
  useQueryDistricts,
} from "resources/rides-list/useQueryBus";
import useSelectedVendor from "hooks/useSelectedVendor";

import { Bus, Option, Captain, BusType } from "utils/types";
import { FilterAction } from "hooks/useFilters";

const RidesListFilter = ({
  filters,
  dispatchFilters: updateFilters,
}: {
  filters: ListRideData;
  dispatchFilters: React.Dispatch<FilterAction<ListRideData>>;
}) => {
  const { t } = useTranslation("rides");
  const {
    mutate: queryCaptain,
    data: captainSuggestions = [],
    isLoading: isCaptainQueryLoading,
  } = useQueryCaptain();
  const selectedVendor = useSelectedVendor();

  const {
    mutate: queryBus,
    data: busSuggestions,
    isLoading: isBusQueryLoading,
  } = useQueryBus();

  const {
    mutate: queryBusType,
    data: busTypeSuggestions,
    isLoading: isBusTypeQueryLoading,
  } = useQueryBusTypes();

  const {
    mutate: queryDistricts,
    data: districtSuggestions,
    isLoading: isDistrictQueryLoading,
  } = useQueryDistricts();

  const searchFiltersValue = [
    {
      label: t("captain"),
      value: "captain",
    },
    {
      label: t("vehicle"),
      value: "bus",
    },
    {
      label: t("vehicle-type"),
      value: "busType",
    },
    {
      label: t("origin-district"),
      value: "originDistrict",
    },
    {
      label: t("destination-district"),
      value: "destinationDistrict",
    },
    {
      label: t("ride-id"),
      value: "rideId",
    },
  ];

  const events = trackEvents.getRideEvents();
  const [captainSearchFilterValue] = searchFiltersValue;
  const [initialCaptainValue, setInitialCaptainValue] = useState("");
  const [initialBusValue, setInitialBusValue] = useState("");
  const [initialBusTypeValue, setInitialBusTypeValue] = useState("");
  const [initialDistrictValue, setInitialDistrictValue] = useState("");
  const [rideId, setRideId] = useState("");

  const [selectedSearchType, setSelectedSearchType] = useState<Option>(
    captainSearchFilterValue
  );

  useEffect(() => {
    setSelectedSearchType(captainSearchFilterValue);
  }, []);

  useEffect(() => {
    // if the selected search type is bus and we
    // change the vendor from company to contractor
    // then we need to change the search filter from
    // bus to captain
    if (selectedVendor.type === "contractor") {
      setInitialBusValue("");
      setSelectedSearchType(captainSearchFilterValue);
    }
  }, [selectedVendor]);

  useEffect(() => {
    setTimeout(() => {
      setInitialCaptainValue("");
    }, 1000);
  }, [filters.captain_ids?.length]);

  return (
    <div
      sx={{
        display: "flex",
        width: "fit-content",
        gap: "spacing-s",
      }}
    >
      <GroupDatePicker
        key={JSON.stringify(`${filters.start_date},${filters.end_date}`)}
        label={t("rides-date-label")}
        startDate={filters.start_date}
        endDate={filters.end_date}
        onDateChange={(start_date, end_date, type) => {
          updateFilters({
            type: "UPDATE",
            filters: {
              start_date: start_date.toISOString(),
              end_date: end_date.toISOString(),
              page: 1,
            },
          });

          GA.event({
            ...events.actionFilterDate,
            label: JSON.stringify({
              start_date: start_date.toISOString(),
              end_Date: end_date.toISOString(),
              type,
            }),
          });
        }}
      />
      <InputGroup sx={{ flex: 2, p: 0 }} label={t("search-label")}>
        <Select
          id="search-control"
          name="search-control"
          options={searchFiltersValue}
          variant="plain"
          height="compact"
          value={searchFiltersValue.find(
            ({ value }) => selectedSearchType.value === value
          )}
          onChange={(value) => {
            setSelectedSearchType(value as Option);
            setInitialCaptainValue("");
            setInitialBusValue("");
            setInitialBusTypeValue("");
            setInitialDistrictValue("");
          }}
          isClearable={false}
          defaultValue={selectedSearchType}
        />
        {selectedSearchType.value === "captain" && (
          <Autocomplete
            onChangeQuery={queryCaptain}
            suggestions={captainSuggestions}
            isLoading={isCaptainQueryLoading}
            initialValue={initialCaptainValue}
            onOptionSelect={(captain) => {
              const { name, _id } = captain as Captain;

              const filter_string = `${_id}:${name}`;
              if (
                filters.captain_ids &&
                !filters.captain_ids.includes(filter_string)
              ) {
                updateFilters({
                  type: "UPDATE",
                  filters: {
                    captain_ids: [...filters.captain_ids, filter_string],
                    page: 1,
                  },
                });
              } else if (!filters.captain_ids) {
                updateFilters({
                  type: "UPDATE",
                  filters: {
                    captain_ids: [filter_string],
                    page: 1,
                  },
                });
              }
            }}
            onClear={() => {
              setInitialCaptainValue("");
            }}
            placeholder={t("search-captain")}
            height="compact"
            variant="plain"
            noResultsMessage={t("no-result-found", { ns: "common" })}
          />
        )}
        {selectedSearchType.value === "bus" && (
          <Autocomplete
            onChangeQuery={(q) => queryBus({ q })}
            suggestions={busSuggestions || []}
            isLoading={isBusQueryLoading}
            initialValue={initialBusValue}
            minCharacters={3}
            onOptionSelect={(bus) => {
              const { plates, _id } = bus as Bus;

              const filter_string = `${_id}:${plates}`;
              if (filters.bus_ids && !filters.bus_ids.includes(filter_string)) {
                updateFilters({
                  type: "UPDATE",
                  filters: {
                    bus_ids: [...filters.bus_ids, filter_string],
                    page: 1,
                  },
                });
              } else if (!filters.bus_ids) {
                updateFilters({
                  type: "UPDATE",
                  filters: {
                    bus_ids: [filter_string],
                    page: 1,
                  },
                });
              }
            }}
            onClear={() => {
              setInitialBusValue("");
            }}
            placeholder={t("search-vehicle")}
            variant="plain"
            height="compact"
            noResultsMessage={t("no-result-found", { ns: "common" })}
          />
        )}
        {selectedSearchType.value === "busType" && (
          <Autocomplete
            minCharacters={3}
            onChangeQuery={(q) => {
              return queryBusType({ q });
            }}
            suggestions={busTypeSuggestions || []}
            isLoading={isBusTypeQueryLoading}
            initialValue={initialBusTypeValue}
            onOptionSelect={(busType) => {
              const { _source, _id } = busType as BusType;

              const filter_string = `${_id}:${_source?.name}`;
              if (
                filters.bus_type_ids &&
                !filters.bus_type_ids.includes(filter_string)
              ) {
                updateFilters({
                  type: "UPDATE",
                  filters: {
                    bus_type_ids: [...filters.bus_type_ids, filter_string],
                    page: 1,
                  },
                });
              } else if (!filters.bus_type_ids) {
                updateFilters({
                  type: "UPDATE",
                  filters: {
                    bus_type_ids: [filter_string],
                    page: 1,
                  },
                });
              }
            }}
            onClear={() => {
              setInitialBusTypeValue("");
            }}
            placeholder={t("search-vehicle-type")}
            height="compact"
            variant="plain"
            noResultsMessage={t("no-result-found", { ns: "common" })}
          />
        )}
        {selectedSearchType.value === "originDistrict" && (
          <Autocomplete
            minCharacters={3}
            onChangeQuery={(q) => {
              return queryDistricts({ q });
            }}
            suggestions={districtSuggestions || []}
            isLoading={isDistrictQueryLoading}
            initialValue={initialDistrictValue}
            onOptionSelect={(originDistrict) => {
              const { _source, _id } = originDistrict as BusType;

              const filter_string = `${_id}:${_source?.name}`;
              if (
                filters.origin_districts &&
                !filters.origin_districts.includes(filter_string)
              ) {
                updateFilters({
                  type: "UPDATE",
                  filters: {
                    origin_districts: [
                      ...filters.origin_districts,
                      filter_string,
                    ],
                    page: 1,
                  },
                });
              } else if (!filters.origin_districts) {
                updateFilters({
                  type: "UPDATE",
                  filters: {
                    origin_districts: [filter_string],
                    page: 1,
                  },
                });
              }
            }}
            onClear={() => {
              setInitialDistrictValue("");
            }}
            placeholder={t("search-origin-district")}
            height="compact"
            variant="plain"
            noResultsMessage={t("no-result-found", { ns: "common" })}
          />
        )}
        {selectedSearchType.value === "destinationDistrict" && (
          <Autocomplete
            minCharacters={3}
            onChangeQuery={(q) => {
              return queryDistricts({ q });
            }}
            suggestions={districtSuggestions || []}
            isLoading={isDistrictQueryLoading}
            initialValue={initialDistrictValue}
            onOptionSelect={(destinationDistrict) => {
              const { _source, _id } = destinationDistrict as BusType;

              const filter_string = `${_id}:${_source?.name}`;
              if (
                filters.destination_districts &&
                !filters.destination_districts.includes(filter_string)
              ) {
                updateFilters({
                  type: "UPDATE",
                  filters: {
                    destination_districts: [
                      ...filters.destination_districts,
                      filter_string,
                    ],
                    page: 1,
                  },
                });
              } else if (!filters.destination_districts) {
                updateFilters({
                  type: "UPDATE",
                  filters: {
                    destination_districts: [filter_string],
                    page: 1,
                  },
                });
              }
            }}
            onClear={() => {
              setInitialDistrictValue("");
            }}
            placeholder={t("search-destination-district")}
            height="compact"
            variant="plain"
            noResultsMessage={t("no-result-found", { ns: "common" })}
          />
        )}
        {selectedSearchType.value === "rideId" && (
          <Input
            placeholder={t("enter-ride-id")}
            height="compact"
            variant="plain"
            value={rideId}
            onChange={(e) => {
              setRideId(e.target.value);
            }}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                const filter_string = `${rideId}`;

                updateFilters({
                  type: "UPDATE",
                  filters: {
                    ride_id: filter_string,
                    page: 1,
                  },
                });

                setRideId("");
              }
            }}
          />
        )}
      </InputGroup>
    </div>
  );
};

export default RidesListFilter;
