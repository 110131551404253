import { useState, useReducer, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";

import SingletonState from "modules/SingletonState";

export interface FilterAction<T> {
  type: "UPDATE" | "RESET";
  filters: Partial<T>;
}

const {
  createSingletonStateHook,
  updateSingletonState: dispatchSingletonFilters,
} = SingletonState();

function useFilters<T>(
  initialValue: Partial<T>
): [T, React.Dispatch<FilterAction<T>>, string] {
  const initialValueRef = useRef<Partial<T>>(initialValue);
  const { search } = useLocation();
  const navigate = useNavigate();
  const searchParams = queryString.parse(search, {
    arrayFormat: "bracket",
    parseBooleans: true,
    parseNumbers: true,
  });
  const mergedFilters: unknown = {
    ...initialValue,
    ...searchParams,
  };

  const [filtersString, setFiltersString] = useState<string>(() =>
    queryString.stringify(mergedFilters as T, {
      arrayFormat: "bracket",
    })
  );
  const [filters, dispatchFilters] = useReducer(
    filtersReducer,
    mergedFilters as T
  );

  function filtersReducer(
    filtersState: T,
    { type, filters: newFilters }: FilterAction<T>
  ): T {
    switch (type) {
      case "UPDATE":
        return updateFilters(filtersState, newFilters);
      case "RESET":
        return resetFilters();
      default:
        return filtersState;
    }
  }

  function updateFilters(filtersState: T, newFilters: Partial<T>) {
    const allFilters: T = {
      ...filtersState,
      ...newFilters,
    };

    const allFiltersString = queryString.stringify(allFilters, {
      arrayFormat: "bracket",
    });
    setFiltersString(allFiltersString);
    navigate({ search: allFiltersString }, { replace: true });

    return allFilters;
  }

  function resetFilters() {
    const allFiltersString = queryString.stringify(initialValueRef.current, {
      arrayFormat: "bracket",
    });
    setFiltersString(allFiltersString);
    navigate({ search: allFiltersString }, { replace: true });

    return initialValueRef.current as T;
  }

  return [filters, dispatchFilters, filtersString];
}

export default createSingletonStateHook<typeof useFilters>(useFilters);
export { dispatchSingletonFilters };
