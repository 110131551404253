import * as React from "react";

const SvgImageUpload = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3 14L3.23384 14.6626C4.144 17.2413 4.59908 18.5307 5.63742 19.2654C6.67576 20 8.0431 20 10.7778 20H13.2222C15.9569 20 17.3242 20 18.3626 19.2654C19.4009 18.5307 19.856 17.2413 20.7662 14.6626L21 14"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M12 4V14M12 4C11.2998 4 9.99153 5.9943 9.5 6.5M12 4C12.7002 4 14.0085 5.9943 14.5 6.5"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SvgImageUpload;
