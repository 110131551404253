/** @jsxImportSource theme-ui */
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CaptainDeleteIcon } from "@swvl/icons";
import { Button } from "@swvl/button";
import Popup, { PopupHeader, PopupContent, PopupFooter } from "@swvl/popup";
import { RadioButtonGroup, RadioButton } from "@swvl/radio-button";

import { RideData } from "./types";
import { UnassignRequestPayload } from "resources/rides-list/useUnassignCaptain";

type UnassignRideCaptainPopupProps = {
  showUnassignRideCaptainPopup: boolean;
  setShowUnassignRideCaptainPopup: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  onUnassignSubmit: (param: UnassignRequestPayload) => void;
  rideData: RideData | undefined;
};

const UnassignRideCaptainPopup: FC<UnassignRideCaptainPopupProps> = ({
  showUnassignRideCaptainPopup,
  setShowUnassignRideCaptainPopup,
  onUnassignSubmit,
  rideData,
}) => {
  const { t } = useTranslation("rides");
  const { t: translateCommon } = useTranslation("common");
  const [removeMapping, setRemoveMapping] = useState<boolean>(false);

  useEffect(() => {
    const captainExists = Boolean(rideData?.captainId);
    setRemoveMapping(!captainExists);
  }, [rideData]);

  const handleUnassign = () => {
    const params: UnassignRequestPayload = {
      ride_id: rideData?.rideId,
      captain_id: rideData?.captainId,
      remove_mapping: removeMapping,
    };
    closePopup();
    onUnassignSubmit(params);
  };

  const closePopup = () => {
    setShowUnassignRideCaptainPopup(false);
  };

  if (!rideData) return null;

  return (
    <Popup isOpen={showUnassignRideCaptainPopup} closePopup={closePopup}>
      <PopupHeader>
        <CaptainDeleteIcon /> {t("unassign.title")}
      </PopupHeader>
      <PopupContent>
        {rideData.captainId && (
          <p sx={{ variant: "text.p-medium-bold", mt: 0, mb: "spacing-xs" }}>
            {t("unassign-captain.select-option")}
          </p>
        )}

        <RadioButtonGroup
          name="remove_mapping"
          value={removeMapping}
          onChange={setRemoveMapping}
        >
          <RadioButton
            value={true}
            title={t("unassign.remove-from-rides-list")}
          />
          {rideData?.captainId && (
            <RadioButton
              value={false}
              title={t("unassign-captain.unassign-and-keep-option")}
            />
          )}
        </RadioButtonGroup>
      </PopupContent>

      <PopupFooter
        sx={{
          button: {
            span: {
              boxShadow: "none",
            },
          },
        }}
      >
        <Button onClick={closePopup} variant="text_link">
          {translateCommon("cancel")}
        </Button>
        <Button
          variant="primary"
          iconPosition="start"
          icon={<CaptainDeleteIcon />}
          onClick={handleUnassign}
        >
          {t("unassign.title")}
        </Button>
      </PopupFooter>
    </Popup>
  );
};

export default UnassignRideCaptainPopup;
