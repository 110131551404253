import apiInstance from "api";
import { AxiosResponse } from "axios";
import { showAlert } from "@swvl/alert";
import { useMutation } from "react-query";
import GA from "react-ga";

import trackEvents from "constants/trackEvents";
import { UnassignRequestPayload } from "./useUnassignCaptain";

interface UnassignRideResponse {
  message: string;
}

const events = trackEvents.getRideEvents();
const unassignRide = async ({ ride_id }: UnassignRequestPayload) => {
  return await apiInstance
    .post<UnassignRequestPayload, UnassignRideResponse>(
      `/ride/${ride_id}/unassign_vendor`
    )
    .then((response: UnassignRideResponse) => {
      GA.event({
        ...events.statusUnassignSuccessfully,
        label: JSON.stringify({ ride_id }),
      });
      return response;
    });
};

const useUnassignRide = () => {
  const mutation = useMutation(unassignRide, {
    mutationKey: `/ride/unassign_vendor`,
    onSuccess: ({ message }) => {
      showAlert({
        message,
        variant: "success",
        mode: "light",
      });
    },
    onError: (response: AxiosResponse) => {
      if (!response) return;

      GA.event({
        ...events.statusUnassignFailed,
        label: response.data.message,
      });
      showAlert({
        message: response.data.message,
        variant: "error",
        mode: "light",
      });
    },
  });

  return mutation;
};

export { useUnassignRide };
