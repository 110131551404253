export function getElementByIdAsync<Type extends HTMLElement>({
  id,
  target,
  htmlDocument,
}: {
  id: string;
  target?: HTMLElement | null;
  htmlDocument?: Document | null;
}) {
  return new Promise<Type>((resolve) => {
    if (!htmlDocument) htmlDocument = document;

    const element = htmlDocument.getElementById(id);
    if (element) return resolve(element as Type);

    const observer = new MutationObserver(() => {
      if (!htmlDocument) htmlDocument = document;
      const element = htmlDocument.getElementById(id);
      if (element) {
        observer.disconnect();
        resolve(element as Type);
      }
    });

    if (!target) target = htmlDocument.body;
    observer.observe(target, { childList: true, subtree: true });
  });
}

export function querySelectorAllAsync<Type extends NodeListOf<HTMLElement>>({
  query,
  target,
  htmlDocument,
}: {
  query: string;
  target?: HTMLElement | null;
  htmlDocument?: Document | null;
}) {
  return new Promise<Type>((resolve) => {
    if (!htmlDocument) htmlDocument = document;
    const elements = htmlDocument.querySelectorAll(query);
    if (elements.length > 0) return resolve(elements as Type);

    const observer = new MutationObserver(() => {
      if (!htmlDocument) htmlDocument = document;
      const elements = htmlDocument.querySelectorAll(query);
      if (elements.length > 0) {
        observer.disconnect();
        resolve(elements as Type);
      }
    });

    if (!target) target = htmlDocument.body;
    observer.observe(target, { childList: true, subtree: true });
  });
}

export function querySelectorAsync<Type extends HTMLElement>({
  query,
  target,
  htmlDocument,
}: {
  query: string;
  target?: HTMLElement | null;
  htmlDocument?: Document | null;
}) {
  return new Promise<Type>((resolve) => {
    if (!htmlDocument) htmlDocument = document;
    const element = htmlDocument.querySelector(query);
    if (element) return resolve(element as Type);

    const observer = new MutationObserver(() => {
      if (!htmlDocument) htmlDocument = document;
      const element = htmlDocument.querySelector(query);
      if (element) {
        observer.disconnect();
        resolve(element as Type);
      }
    });

    if (!target) target = htmlDocument.body;
    observer.observe(target, { childList: true, subtree: true });
  });
}
