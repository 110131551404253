enum CATEGORIES {
  login = "LOGIN",
  common = "COMMON",
  rides = "RIDES",
  vehicles = "VEHICLES",
  captains = "CAPTAINS",
}

const trackEvents = {
  [CATEGORIES.login]: {
    screenLogin: {
      category: CATEGORIES.login,
      action: "screen_login",
      label: "screen",
    },
    actionEnterName: {
      category: CATEGORIES.login,
      action: "action_enter_name",
      label: "action",
    },
    actionEnterPassword: {
      category: CATEGORIES.login,
      action: "action_enter_password",
      label: "action",
    },
    actionSignIn: {
      category: CATEGORIES.login,
      /* cspell:disable-next-line */
      action: "action_signin",
      label: "action",
    },
    actionViewPassword: {
      category: CATEGORIES.login,
      action: "action_view_password",
      label: "action",
    },
    actionLoginSuccess: {
      category: CATEGORIES.login,
      action: "status_login_successfully",
      label: "status",
    },
    actionLoginFailed: {
      category: CATEGORIES.login,
      action: "status_login_failed",
      label: "status",
    },
  },
  [CATEGORIES.common]: {
    actionChangeCommunicationLanguage: {
      category: CATEGORIES.common,
      action: "action_change_communication_language",
      label: "action",
    },
    actionChangeCommunicationLanguageFailed: {
      category: CATEGORIES.common,
      action: "action_change_communication_language_failed",
      label: "action",
    },
    actionSelectRideManagement: {
      category: CATEGORIES.common,
      action: "action_select_ride_management",
      label: "action",
    },
    actionSelectFleet: {
      category: CATEGORIES.common,
      action: "action_select_fleet",
      label: "action",
    },
    screenRideHistory: {
      category: CATEGORIES.common,
      action: "view_ride_history",
      label: "screen",
    },
    screenVerificationPopup: {
      category: CATEGORIES.common,
      action: "screen_verification_popup",
      label: "screen",
    },
    actionVerificationResent: {
      category: CATEGORIES.common,
      action: "action_verification_resent",
      label: "action",
    },
  },
  [CATEGORIES.captains]: {
    screenCaptainsList: {
      category: CATEGORIES.captains,
      action: "screen_captains_list",
      label: "screen",
    },
    screenCaptainInfo: {
      category: CATEGORIES.captains,
      action: "screen_captain_info",
      label: "screen",
    },
    screenCaptainDocuments: {
      category: CATEGORIES.captains,
      action: "screen_captain_documents",
      label: "screen",
    },
    screenInviteCaptain: {
      category: CATEGORIES.captains,
      action: "screen_invite_captain",
      label: "screen",
    },
    actionOpenCaptainDocuments: {
      category: CATEGORIES.captains,
      action: "action_open_captain_documents",
      label: "action",
    },
    actionOpenInviteCaptain: {
      category: CATEGORIES.captains,
      action: "action_open_invite_captain",
      label: "action",
    },
    actionCloseInviteCaptain: {
      category: CATEGORIES.captains,
      action: "action_close_invite_captain",
      label: "action",
    },
    actionOpenCaptainMoreFilters: {
      category: CATEGORIES.captains,
      action: "action_open_captain_filters",
      label: "action",
    },
    actionCloseCaptainMoreFilters: {
      category: CATEGORIES.captains,
      action: "action_close_captain_filters",
      label: "action",
    },
    actionInviteCaptain: {
      category: CATEGORIES.captains,
      action: "action_invite_captain",
      label: "action",
    },
    actionEnterInviteeName: {
      category: CATEGORIES.captains,
      action: "action_enter_invitee_name",
      label: "action",
    },
    actionEnterInviteeNumber: {
      category: CATEGORIES.captains,
      action: "action_enter_invitee_number",
      label: "action",
    },
    statusInviteSuccessfully: {
      category: CATEGORIES.captains,
      action: "status_invite_successfully",
      label: "status",
    },
    statusInviteFailed: {
      category: CATEGORIES.captains,
      action: "status_invite_failed",
      label: "status",
    },
    statusSearchSuccessfully: {
      category: CATEGORIES.captains,
      action: "status_search_successfully",
      label: "status",
    },
    actionSelectVehicle: {
      category: CATEGORIES.captains,
      action: "action_select_vehicles",
      label: "action",
    },
    actionFilterSearch: {
      category: CATEGORIES.captains,
      action: "action_filter_search",
      label: "action",
    },
    actionFilterCaptainStatus: {
      category: CATEGORIES.captains,
      action: "action_filter_captain_status",
      label: "action",
    },
    actionFilterCaptainVerificationStatus: {
      category: CATEGORIES.captains,
      action: "action_filter_captain_verification_status",
      label: "action",
    },
    actionFilterCaptainRating: {
      category: CATEGORIES.captains,
      action: "action_filter_captain_rating",
      label: "action",
    },
    actionFilterCaptainFeedback: {
      category: CATEGORIES.captains,
      action: "action_filter_captain_feedback",
      label: "action",
    },
    actionEnterSearch: {
      category: CATEGORIES.captains,
      action: "action_enter_search",
      label: "action",
    },
    actionSubmitSearch: {
      category: CATEGORIES.captains,
      action: "action_submit_search",
      label: "action",
    },
    actionOpenCaptainInfo: {
      category: CATEGORIES.captains,
      action: "action_open_captain_info",
      label: "action",
    },
    actionCloseCaptainInfo: {
      category: CATEGORIES.captains,
      action: "action_close_captain_info",
      label: "action",
    },
    actionChangePage: {
      category: CATEGORIES.captains,
      action: "action_change_page",
      label: "action",
    },
    actionChangeRowsPerPage: {
      category: CATEGORIES.captains,
      action: "action_change_rows_per_page",
      label: "action",
    },
  },
  [CATEGORIES.vehicles]: {
    screenVehicleList: {
      category: CATEGORIES.vehicles,
      action: "screen_vehicle_list",
      label: "screen",
    },
    actionFilterSearch: {
      category: CATEGORIES.vehicles,
      action: "action_filter_search",
      label: "action",
    },
    actionEnterSearch: {
      category: CATEGORIES.vehicles,
      action: "action_enter_search",
      label: "action",
    },
    actionSubmitSearch: {
      category: CATEGORIES.vehicles,
      action: "action_submit_search",
      label: "action",
    },
    actionChangePage: {
      category: CATEGORIES.vehicles,
      action: "action_change_page",
      label: "action",
    },
    actionChangeRowsPerPage: {
      category: CATEGORIES.vehicles,
      action: "action_change_rows_per_page",
      label: "action",
    },
    screenVehicleInfo: {
      category: CATEGORIES.vehicles,
      action: "screen_vehicle_info",
      label: "screen",
    },
    actionOpenVehicleInfo: {
      category: CATEGORIES.vehicles,
      action: "action_open_vehicle_info",
      label: "action",
    },
    actionCloseVehicleInfo: {
      category: CATEGORIES.vehicles,
      action: "action_close_vehicle_info",
      label: "action",
    },
  },
  [CATEGORIES.rides]: {
    screenRidesList: {
      category: CATEGORIES.rides,
      action: "screen_rides_list",
      label: "screen",
    },
    screenRideReassignCaptain: {
      category: CATEGORIES.rides,
      action: "screen_ride_reassign_captain",
      label: "screen",
    },
    screenRideAssignCaptain: {
      category: CATEGORIES.rides,
      action: "screen_ride_assign_captain",
      label: "screen",
    },
    screenRidesFilters: {
      category: CATEGORIES.rides,
      action: "screen_rides_filters",
      label: "screen",
    },
    screenRideInfo: {
      category: CATEGORIES.rides,
      action: "screen_ride_info",
      label: "screen",
    },
    screenMapView: {
      category: CATEGORIES.rides,
      action: "screen_map_view",
      label: "screen",
    },
    actionChangePage: {
      category: CATEGORIES.rides,
      action: "action_change_page",
      label: "action",
    },
    actionChangeRowsPerPage: {
      category: CATEGORIES.rides,
      action: "action_change_rows_per_page",
      label: "action",
    },
    actionFilterDate: {
      category: CATEGORIES.rides,
      action: "action_filter_date",
      label: "action",
    },
    actionOpenMoreFilters: {
      category: CATEGORIES.rides,
      action: "action_open_more_filters",
      label: "action",
    },
    actionApplyFilters: {
      category: CATEGORIES.rides,
      action: "action_apply_filters",
      label: "action",
    },
    actionClearFilters: {
      category: CATEGORIES.rides,
      action: "action_clear_filters",
      label: "action",
    },
    actionDownloadRides: {
      category: CATEGORIES.rides,
      action: "action_download_rides",
      label: "action",
    },
    actionCloseMoreFilters: {
      category: CATEGORIES.rides,
      action: "action_close_more_filters",
      label: "action",
    },
    actionFilterSort: {
      category: CATEGORIES.rides,
      action: "action_filter_sort",
      label: "action",
    },
    actionFilterRideStatus: {
      category: CATEGORIES.rides,
      action: "action_filter_ride_status",
      label: "action",
    },
    actionFilterCaptainAcceptance: {
      category: CATEGORIES.rides,
      action: "action_filter_captain_acceptance",
      label: "action",
    },
    statusSearchSuccessfully: {
      category: CATEGORIES.rides,
      action: "status_search_successfully",
      label: "action",
    },
    statusSearchFailure: {
      category: CATEGORIES.rides,
      action: "status_search_failure",
      label: "action",
    },
    actionReassignCaptain: {
      category: CATEGORIES.rides,
      action: "action_reassign_captain",
      label: "action",
    },
    actionAssignCaptain: {
      category: CATEGORIES.rides,
      action: "action_assign_captain",
      label: "action",
    },
    actionSubmitReassignCaptain: {
      category: CATEGORIES.rides,
      action: "action_submit_reassign_captain",
      label: "action",
    },
    actionEditBusInfo: {
      category: CATEGORIES.rides,
      action: "action_edit_bus_info",
      label: "action",
    },
    actionSubmitBusInfo: {
      category: CATEGORIES.rides,
      action: "action_submit_bus_info",
      label: "action",
    },
    actionSelectStation: {
      category: CATEGORIES.rides,
      action: "action_select_station",
      label: "action",
    },
    statusUnassignSuccessfully: {
      category: CATEGORIES.rides,
      action: "status_unassign_successfully",
      label: "status",
    },
    statusUnassignFailed: {
      category: CATEGORIES.rides,
      action: "status_unassign_failed",
      label: "status",
    },
    statusAssignSuccessfully: {
      category: CATEGORIES.rides,
      action: "status_assign_successfully",
      label: "status",
    },
    statusAssignFailed: {
      category: CATEGORIES.rides,
      action: "status_assign_failed",
      label: "status",
    },
    statusBusInfoUpdateSuccessfully: {
      category: CATEGORIES.rides,
      action: "status_bus_info_update_successfully",
      label: "status",
    },
    statusBusInfoUpdateFailed: {
      category: CATEGORIES.rides,
      action: "status_bus_info_update_failed",
      label: "status",
    },
  },
};

export default {
  getLoginEvents: () => trackEvents[CATEGORIES.login],
  getCommonEvents: () => trackEvents[CATEGORIES.common],
  getCaptainEvents: () => trackEvents[CATEGORIES.captains],
  getVehicleEvents: () => trackEvents[CATEGORIES.vehicles],
  getRideEvents: () => trackEvents[CATEGORIES.rides],
};
