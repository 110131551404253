import * as React from "react";

const CircleUserIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.5651 32.2224C11.3644 33.486 5.59412 36.0662 9.10858 39.2948C10.8254 40.872 12.7374 42 15.1414 42H28.8586C31.2626 42 33.1746 40.872 34.8914 39.2948C38.4058 36.0662 32.6356 33.486 30.4348 32.2224C25.2742 29.2592 18.7258 29.2592 13.5651 32.2224Z"
        stroke="#BDBDBD"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29 18C29 21.866 25.866 25 22 25C18.134 25 15 21.866 15 18C15 14.134 18.134 11 22 11C25.866 11 29 14.134 29 18Z"
        stroke="#BDBDBD"
        strokeWidth="3"
      />
      <path
        d="M3.708 30C2.61002 27.5328 2 24.802 2 21.9292C2 10.9226 10.9543 2 22 2C33.0456 2 42 10.9226 42 21.9292C42 24.802 41.39 27.5328 40.292 30"
        stroke="#BDBDBD"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default CircleUserIcon;
