import { useQueryClient } from "react-query";

import useSelectedVendorIndex from "hooks/useSelectedVendorIndex";

import { ManagerInfoResponse } from "utils/types";

const useSelectedVendor = () => {
  const queryClient = useQueryClient();
  const [selectedVendorIndex] = useSelectedVendorIndex();
  const managerInfo =
    queryClient.getQueryData<ManagerInfoResponse>("manager-info");
  const managerVendors = managerInfo?.vendors || [];
  const selectedVendor = managerVendors[selectedVendorIndex];

  return selectedVendor;
};

export default useSelectedVendor;
