/** @jsxImportSource theme-ui */
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SideNav } from "@swvl/navigation";

import useMenuOpen from "hooks/useMenuOpen";
import sideBarTabs from "constants/sideBarTabs";

const SideNavigation = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation("common", { keyPrefix: "navigation" });
  const [isMenuOpen] = useMenuOpen();

  return (
    <div
      sx={{
        "& > aside > div ul": {
          paddingY: "spacing-xxs",
        },
      }}
    >
      <SideNav isExpanded={isMenuOpen ?? true}>
        <SideNav.Section grow>
          {sideBarTabs.map(({ text, icon, link, subItems }) => (
            <SideNav.Item
              key={link}
              title={t(text)}
              icon={icon}
              onClick={() => {
                if (link) navigate(link);
                else if (!isMenuOpen && subItems && subItems?.length > 0)
                  navigate(subItems[0].link);
              }}
              active={
                (typeof link === "string" && pathname.startsWith(link)) ||
                (subItems &&
                  subItems.some((item) => pathname.startsWith(item.link)))
              }
            >
              {subItems &&
                subItems.map((subItem) => (
                  <SideNav.SubItem
                    key={subItem.link}
                    title={t(subItem.text)}
                    onClick={() => subItem.link && navigate(subItem.link)}
                    active={
                      typeof subItem.link === "string" &&
                      pathname.startsWith(subItem.link)
                    }
                  />
                ))}
            </SideNav.Item>
          ))}
        </SideNav.Section>
      </SideNav>
    </div>
  );
};

export default SideNavigation;
