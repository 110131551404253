/** @jsxImportSource theme-ui */
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import GA from "react-ga";

import trackEvents from "constants/trackEvents";

import Rides from "./Rides";
import RideDetails from "./RideDetails";
import NotFound from "components/NotFound";

const RidesManagement = () => {
  const { t } = useTranslation("common");
  const events = trackEvents.getCommonEvents();
  const navigate = useNavigate();

  useEffect(() => {
    GA.event(events.actionSelectRideManagement);
  }, []);

  return (
    <Routes>
      <Route index element={<Navigate to="rides" replace />} />
      <Route key="Rides" path="rides" element={<Rides />} />
      <Route key="Ride Details" path="rides/:id/*" element={<RideDetails />} />
      <Route
        path="*"
        element={
          <NotFound
            title={t("not-found-title")}
            description={t("not-found-description")}
            goBackCallback={() =>
              navigate("/rides-management/rides", { replace: true })
            }
          />
        }
      />
    </Routes>
  );
};
export default RidesManagement;
