/** @jsxImportSource theme-ui */

import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";

const ClickableCell = (
  id: string,
  renderContent: () => string | ReactElement
) => {
  const navigate = useNavigate();

  return (
    <div
      sx={{
        display: "flex",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
      onClick={(e) => {
        e.preventDefault();
        navigate(`${location.pathname}/${id}`);
      }}
    >
      {renderContent()}
    </div>
  );
};

export default ClickableCell;
