import { useQuery } from "react-query";
import GA from "react-ga";
import apiInstance from "api";

import trackEvents from "constants/trackEvents";
import { useAuthContext } from "contexts/authContext";
import { Bus, BusType, Captain } from "utils/types";
import { startCase } from "lodash/fp";

// Types
export interface ListRideData {
  start_date: string;
  end_date: string;
  date?: "tomorrow";
  page: number;
  limit: number;
  status?: string[];
  ride_category_ids?: string[];
  captain_assigned?: boolean;
  captain_ids?: string[];
  bus_ids?: string[];
  bus_type_ids?: string[];
  origin_districts?: string[];
  destination_districts?: string[];
  ride_id?: string;
  captain_confirmed?: boolean;
  sort?: string;
}

export interface ListRideFilters {
  ride_id?: string;
  captain_assigned?: boolean;
  captain_confirmed?: boolean;
  start_date?: string;
  end_date?: string;
  status?: string[];
  ride_category_ids?: string[];
  captain_ids?: string[];
  bus_ids?: string[];
  bus_type_ids?: string[];
  origin_districts?: string[];
  destination_districts?: string[];
  page?: number;
}
export interface RideAppliedFilters {
  captain_assigned?: { title: string; value: string };
  captain_confirmed?: { title: string; value: string };
  status?: { title: string; value: string[] };
  bus_ids?: { title: string; value: string[] };
  captain_ids?: { title: string; value: string[] };
  score?: { title: string; value: unknown };
}

export interface VehicleAppliedFilters {
  document_verification_status?: { title: string; value: string[] };
}

interface Route {
  _id: string;
  name: string;
}

export const RideFeatureKeys = [
  "bus_has_ac",
  "captain_same_city",
  "pricing_version",
  "dispatcher_config",
  "waiting_time",
  "plan_schedule_time_modification",
  "auto_skip_station_config",
  "allow_bus_type_update",
] as const;

export type Ride = {
  _id: string;
  status: string;
  date: string;
  corporates: {
    _id: string;
    name: string;
  }[];
  predicted_end_date: string;
  actual_distance: number;
  route_distance: number;
  route: Route;
  captain: Captain;
  captain_price?: number;
  bus: Bus;
  bus_type: BusType;
  confirmed?: boolean;
  schedule?: string[];
  stations_data?: {
    station: {
      _id: string;
      loc: {
        type: string;
        coordinates: number[];
      };
    };
  }[];
  ride_category: {
    features: {
      name: typeof RideFeatureKeys[number];
      value: unknown;
    }[];
    ride_type: string;
    service_type: string;
  };
  origin_district: {
    _id: string;
    name: string;
  };
  destination_district: {
    _id: string;
    name: string;
  };
  bookings?: {
    count: number;
    maximum: number;
  };
  currency?: string;
};

export type ListRideResponse = {
  total: number;
  page: number;
  limit: number;
  hits: Ride[];
};

export type ListRideCategoriesResponse = {
  _id: string;
  ride_type: string;
  service_type: string;
};

const transformResponse = (
  hits: ListRideCategoriesResponse[]
): { label: string; value: string }[] => {
  return hits.map((option) => ({
    label: startCase(`${option?.ride_type} ${option?.service_type}`.trim()),
    value: option._id,
  }));
};

// Resources
const getListRides = (params: ListRideData) => {
  return apiInstance.get<unknown, ListRideResponse>("/ride", {
    params,
  });
};

const getListRideCategories = async () => {
  const response = await apiInstance.get<unknown, ListRideCategoriesResponse[]>(
    "/ride/configs/ride-category"
  );

  return transformResponse(response);
};

const useListRides = (params: ListRideData, enabled = true) => {
  const { dynamicKeys } = useAuthContext();
  const events = trackEvents.getRideEvents();

  return useQuery(
    ["rides-list", params, dynamicKeys],
    () => getListRides(params),
    {
      enabled,
      onSuccess: (response: ListRideResponse) => {
        GA.event({
          ...events.statusSearchSuccessfully,
          label: JSON.stringify({ number_of_rides: response?.hits.length }),
        });
      },
    }
  );
};

const useListRideCategories = () => {
  return useQuery("ride_categories", getListRideCategories, {
    staleTime: Infinity,
  });
};

export { useListRides, useListRideCategories };
