/** @jsxImportSource theme-ui */
import { useState, useEffect } from "react";
import { useFormik, FormikProvider, Field } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import Input from "@swvl/input";
import { Button, IconButton } from "@swvl/button";
import {
  RideUpdateIcon,
  CloseIcon,
  WarningFilledIcon,
  AlertTriangleFilledIcon,
} from "@swvl/icons";
import GA from "react-ga";

import FieldAutocompleteWrapper from "components/FieldAutoCompleteWrapper";
import { useQueryCaptain } from "resources/common/useQueryCaptain";
import { useQueryBus } from "resources/rides-list/useQueryBus";
import { AssignCaptainRequest } from "resources/rides-list/useAssignCaptain";
import useSelectedVendor from "hooks/useSelectedVendor";
import trackEvents from "constants/trackEvents";

import { Bus, AutoCompleteOption, Captain } from "utils/types";
import { UpdateRideProps } from "./types";

type UpdateRideDrawerProps = {
  setUpdateRideDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  updateRideData: UpdateRideProps | undefined;
  onUpdateRideSubmit: (params: AssignCaptainRequest) => void;
  isUpdateRideLoading: boolean;
};

const UpdateRideDrawer = ({
  setUpdateRideDrawerOpen: setAssignCaptainDrawerOpen,
  updateRideData: assignReassignData,
  onUpdateRideSubmit: onAssignCaptainSubmit,
  isUpdateRideLoading: isAssignCaptainLoading,
}: UpdateRideDrawerProps) => {
  const [busSuggestions, setBusSuggestions] = useState<AutoCompleteOption[]>(
    []
  );
  const { t } = useTranslation("rides");
  const selectedVendor = useSelectedVendor();
  const {
    mutate: queryCaptain,
    data: captainSuggestions = [],
    isLoading: isCaptainQueryLoading,
  } = useQueryCaptain();
  const { mutateAsync: queryBus, isLoading: isBusQueryLoading } = useQueryBus();
  const events = trackEvents.getRideEvents();
  const [busTypePristine, setBusTypePristine] = useState(true);

  const validationSchema = Yup.object({
    captain_id: Yup.string().required(t("assign-captain.validation.captain")),
    bus_id: Yup.object()
      .shape({
        _id: Yup.string(),
        type: Yup.string(),
      })
      .test("is-valid-bus", t("assign-captain.validation.bus"), (value) => {
        if (!value?._id) return false;
        return true;
      }),
  });

  const formik = useFormik({
    initialValues: {
      captain_id: assignReassignData ? assignReassignData.captain?._id : "",
      bus_id: assignReassignData ? assignReassignData.bus : null,
    },
    validationSchema,
    onSubmit: ({ captain_id, bus_id }) => {
      if (!assignReassignData) return;

      onAssignCaptainSubmit({
        ride_id: assignReassignData.rideId,
        bus_id: bus_id?._id || "",
        captain_id,
      });
    },
  });

  const handleQueryBus = (q = "", captain_id = "") => {
    if (!assignReassignData) return;

    queryBus({
      q,
      captain_id:
        selectedVendor.type === "contractor"
          ? formik.values["captain_id"] || captain_id
          : undefined,
    }).then((response) => setBusSuggestions(response));
  };

  const { bus_id } = formik.values;
  const validBusTypeSelected =
    !assignReassignData?.busType?._id ||
    !bus_id?._id ||
    bus_id?.type === assignReassignData?.busType?._id;

  useEffect(() => {
    handleQueryBus();
    GA.event({
      ...events.screenRideReassignCaptain,
      label: JSON.stringify({ ride_id: assignReassignData?.rideId }),
    });
  }, []);

  const AlertMessageWarning = (
    <div
      sx={{
        p: "spacing-s",
        borderRadius: 6,
        backgroundColor: "warning-light",
        display: "flex",
      }}
    >
      <AlertTriangleFilledIcon width={34} height={34} viewBox="0 4 24 24" />
      <p
        sx={{
          variant: "text.p-small",
          my: 0,
          marginInlineStart: "spacing-s",
        }}
      >
        {t("update-ride.warningMessage")}
      </p>
    </div>
  );
  const AlertMessageError = (
    <div
      sx={{
        p: "spacing-s",
        borderRadius: 6,
        backgroundColor: "negative-light",
        display: "flex",
      }}
    >
      <WarningFilledIcon width={34} height={34} viewBox="0 4 24 24" />
      <p
        sx={{
          variant: "text.p-small",
          my: 0,
          marginInlineStart: "spacing-s",
        }}
      >
        {t("update-ride.errorMessage")}
      </p>
    </div>
  );

  const renderErrorMessage = () => {
    if (busTypePristine || validBusTypeSelected) return null;

    if (assignReassignData?.allow_bus_type_update) return AlertMessageWarning;
    else return AlertMessageError;
  };
  const getSelectedCaptainId = ({ _id: id }: Captain) => id;
  const getSelectedBusId = (bus: Bus) => bus;

  if (!assignReassignData) return null;

  return (
    <div
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        ".szh-menu-container": { width: "536px" },
        ".szh-menu-container > ul": { width: "100%" },
      }}
    >
      <div sx={{ display: "flex", p: "spacing-m" }}>
        <div sx={{ marginInlineEnd: "spacing-s" }}>
          <RideUpdateIcon />
        </div>
        <div sx={{ flexGrow: 1 }}>
          <h5 sx={{ mt: 0, mb: "spacing-xs", variant: "text.h5" }}>
            {t("update-ride.title")}
          </h5>
          <p sx={{ m: 0, variant: "text.p-small" }}>
            {assignReassignData.origin_district} -{" "}
            {assignReassignData.destination_district}
          </p>
        </div>
        <IconButton
          icon={<CloseIcon />}
          onClick={() => setAssignCaptainDrawerOpen(false)}
          sx={{ mt: -8, marginInlineEnd: -8 }}
        />
      </div>

      <div sx={{ flexGrow: 1, px: "spacing-m", py: 32, overflow: "auto" }}>
        <p sx={{ mt: 0, mb: 32, variant: "text.p-large-bold" }}>
          {t("update-ride.subTitle")}
        </p>
        <div sx={{ mb: "spacing-m" }}>
          <Input
            label={t("vehicle-type")}
            value={assignReassignData.busType?.name}
            disabled
            required
          />
        </div>

        <FormikProvider value={formik}>
          <div sx={{ mb: "spacing-m" }}>
            <Field
              label={t("captain-name")}
              name="captain_id"
              component={FieldAutocompleteWrapper}
              suggestions={captainSuggestions}
              onChangeQuery={queryCaptain}
              isLoading={isCaptainQueryLoading}
              initialValue={assignReassignData?.captain?.name}
              onOptionSelect={(captainId: string) =>
                handleQueryBus("", captainId)
              }
              getSelectedOptionValue={getSelectedCaptainId}
              placeholder={t("search-captain")}
              autoFocus={true}
              required
            />
          </div>
          <div sx={{ mb: "spacing-m" }}>
            <Field
              label={t("bus-plate")}
              name="bus_id"
              component={FieldAutocompleteWrapper}
              suggestions={busSuggestions}
              onChangeQuery={handleQueryBus}
              isLoading={isBusQueryLoading}
              initialValue={`${
                assignReassignData?.bus?.plates
                  ? `${assignReassignData?.bus?.plates}`
                  : ""
              }`}
              onOptionSelect={() => setBusTypePristine(false)}
              minCharacters={1}
              disabled={!formik.values["captain_id"]}
              placeholder={t("enter-bus-name")}
              getSelectedOptionValue={getSelectedBusId}
              required
            />
          </div>
          {renderErrorMessage()}
        </FormikProvider>
      </div>
      <div
        sx={{
          px: "spacing-m",
          py: "spacing-s",
          borderTop: "1px solid",
          borderColor: "border",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          disabled={
            !formik.dirty ||
            isAssignCaptainLoading ||
            (!validBusTypeSelected && !assignReassignData.allow_bus_type_update)
          }
          onClick={formik.submitForm}
        >
          {t("update-ride.title")}
        </Button>
      </div>
    </div>
  );
};

export default UpdateRideDrawer;
