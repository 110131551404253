import { useQuery } from "react-query";

import apiInstance from "api";

import { useAuthContext } from "contexts/authContext";
import { Bus, BusType, Captain, RideBookings } from "utils/types";
import { RideFeatureKeys } from "./useListRides";

export interface UserReview {
  user: {
    name: string;
    picture: string;
    rating: number;
    createdAt: string;
  };
  feedback: {
    category: string;
    topic: string;
  }[];
  comments: string;
}
export interface RideRatingSummary {
  summary: {
    average: number;
    count: number;
    breakdown: {
      [key: string]: {
        percentage: number;
        count: number;
      };
    };
  };
  listing?: UserReview[];
}
export interface RideDetails {
  _id: string;
  estimated_distance: number;
  actual_distance: number;
  route_distance: number;
  status: string;
  price: number;
  currency: string;
  date: string | Date;
  predicted_end_date: string;
  corporates: {
    _id: string;
    name: string;
  }[];
  city: BusType;
  route: Route;
  origin_district: {
    _id: string;
    name: string;
  };
  destination_district: {
    _id: string;
    name: string;
  };
  captain: Captain;
  bookings: RideBookings;
  captain_price: number;
  bus?: Bus;
  bus_type: BusType;
  ride_category: RideCategory;
  stations_data: StationsDatum[];
  rating?: RideRatingSummary;
}

interface RideCategory {
  category: string;
  service_type: string;
  ride_type: string;
  features: {
    name: typeof RideFeatureKeys[number];
    value: unknown;
  }[];
}

interface Route {
  _id: string;
  name: string;
  polyline: string;
}

export interface StationsDatum {
  distance: number;
  station: {
    _id: string;
    name: string;
    loc: LOC;
    district: BusType;
  };
}

export interface LOC {
  type: string;
  coordinates: number[];
}

const transformResponse = ({
  estimated_distance,
  actual_distance,
  route_distance,
  captain_price,
  date,
  ...restDetails
}: RideDetails): RideDetails => {
  const estimatedDistanceInKm = estimated_distance
    ? estimated_distance / 1000
    : 0;
  const actualDistanceInKm = actual_distance ? actual_distance / 1000 : 0;
  const routeDistanceInKm = route_distance ? route_distance / 1000 : 0;
  const priceInDollar = (captain_price || 0) / 100;

  return {
    estimated_distance: estimatedDistanceInKm,
    actual_distance: actualDistanceInKm,
    route_distance: routeDistanceInKm,
    captain_price: priceInDollar,
    date: new Date(date),
    ...restDetails,
  };
};

const getRideDetails = async (rideId: string) => {
  const response = await apiInstance.get<unknown, RideDetails>(
    `ride/${rideId}`
  );
  return transformResponse(response);
};

const useRideDetails = (rideId = "") => {
  const { dynamicKeys } = useAuthContext();

  return useQuery(["ride-details", rideId, dynamicKeys], () =>
    getRideDetails(rideId)
  );
};

export { useRideDetails };
