import { useQuery } from "react-query";
import { AxiosResponse } from "axios";

import apiInstance from "api";

import { useAuthContext } from "contexts/authContext";
import { Captain } from "utils/types";

const getCaptain = (id: string) => {
  return apiInstance.get<unknown, Captain>(`captain/${id}`);
};

const useCaptainDetails = (captainId = "") => {
  const { dynamicKeys } = useAuthContext();

  return useQuery<Captain, AxiosResponse>(
    ["captain-detail", captainId, dynamicKeys],
    () => getCaptain(captainId),
    {
      enabled: Boolean(captainId),
    }
  );
};

export { useCaptainDetails };
