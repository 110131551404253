/** @jsxImportSource theme-ui */
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Column, TableState, ActionType } from "react-table";
import GA from "react-ga";
import { Flex } from "theme-ui";
import { useNavigate } from "react-router";

import Avatar from "@swvl/avatar";
import { DOCUMENT_STATUS_COLOR, STATUS_COLOR, STATUS_LABEL } from "./constants";
import Tag from "@swvl/tag";
import { startCase } from "lodash";
import { Popover } from "@swvl/popover";
import { IconButton } from "@swvl/button";
import Table from "@swvl/table";
import { EyeIcon, MoreVerticalIcon } from "@swvl/icons";
import { popoverContainerStyles } from "pages/rides-management/constants";
import ClickableCell from "shared/components/ClickableCell";
import { FilterAction } from "hooks/useFilters";
import { tablePageSizeOptions as pageSizeOptions } from "utils";
import trackEvents from "constants/trackEvents";
import useLang from "hooks/useLang";

import {
  Vehicle,
  ListVehicleResponse,
  ListVehicleData,
} from "resources/vehicles-list/useListVehicle";
import CaptainIcon from "shared/icons/CaptainIcon";
import NoData from "components/NoData";

type ListVehicleProps = {
  isLoading?: boolean;
  vehicles: ListVehicleResponse | undefined;
  filters: ListVehicleData;
  dispatchFilters: React.Dispatch<FilterAction<ListVehicleData>>;
  onRowClick?: (vehicle: Vehicle | undefined) => void;
};
let lastTableActionType: string;

const VehicleListing = ({
  vehicles,
  isLoading,
  filters,
  dispatchFilters,
}: ListVehicleProps) => {
  const [lang] = useLang();
  const { t } = useTranslation("vehicles");
  const events = trackEvents.getVehicleEvents();
  const navigate = useNavigate();

  const getRowProps = useCallback((props) => {
    return {
      ...props,
      sx: {
        cursor: "pointer",
      },
    };
  }, []);

  const tableStateReducer = (
    state: TableState<Vehicle>,
    { type }: ActionType
  ) => {
    let newState;

    switch (type) {
      case "resetHiddenColumns":
        if (
          lastTableActionType !== "gotoPage" &&
          lastTableActionType !== "setPageSize"
        ) {
          newState = {
            ...state,
            pageIndex: filters.page - 1,
            pageSize: filters.limit,
          };
        }
        break;
    }
    if (!newState) newState = state;

    lastTableActionType = type;
    return newState;
  };

  const columns: Column<Vehicle>[] = [
    {
      Header: `${t("plate-number")}`,
      accessor: "plates",
      id: "plates",
      Cell: ({
        value,
        row: {
          original: { _id },
        },
      }) => ClickableCell(_id, () => value),
    },
    {
      Header: `${t("owner")}`,
      accessor: "owner_type",
      id: "captain",
      Cell: ({ value, row: { original } }) => {
        const accessor = value === "company" ? "company" : "captain";
        return ClickableCell(original._id, () => (
          <div
            sx={{
              display: "flex",
              gap: "12px",
              alignItems: "center",
            }}
          >
            <Avatar
              name={original[accessor]?.name[0]}
              src={original[accessor]?.picture}
              size="32px"
              bg="rgba(35, 86, 249, 0.30);"
            ></Avatar>
            <span>
              {lang.code === "ar"
                ? original[accessor]?.name_ar || original[accessor]?.name
                : original[accessor]?.name || "-"}
            </span>
          </div>
        ));
      },
    },
    {
      Header: `${t("document-status")}`,
      accessor: "document_verification_status",
      id: "document_status",
      Cell: ({
        value,
        row: {
          original: { _id },
        },
      }) => {
        return ClickableCell(_id, () => (
          <Tag variant={DOCUMENT_STATUS_COLOR[value]}>
            {value ? t(`document-verification-statuses.${value}`) : "-"}
          </Tag>
        ));
      },
    },
    {
      Header: `${t("status")}`,
      accessor: "active",
      id: "status",
      Cell: ({
        value,
        row: {
          original: { _id },
        },
      }) => {
        return ClickableCell(_id, () => (
          <Tag
            variant={value ? STATUS_COLOR["active"] : STATUS_COLOR["inactive"]}
          >
            {startCase(
              t(value ? STATUS_LABEL["active"] : STATUS_LABEL["inactive"])
            )}
          </Tag>
        ));
      },
    },
    {
      Header: `${t("actions")}`,
      id: "actions",
      accessor: "owner_type",
      Cell: ({ value, row }) => {
        return (
          <Flex sx={{ width: "65px" }}>
            <Popover
              placement={"bottom-start"}
              containerStyles={popoverContainerStyles}
              style={{ zIndex: 3 }}
            >
              <Popover.Trigger>
                <span>
                  <IconButton
                    type="button"
                    data-test-name="popover-trigger"
                    icon={<MoreVerticalIcon />}
                  />
                </span>
              </Popover.Trigger>
              <Popover.Content>
                <Flex
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(row.original._id);
                  }}
                >
                  <IconButton type="button" icon={<EyeIcon />} />
                  <span>{t("view-details")}</span>
                </Flex>
                {value === "captain" && (
                  <Flex
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(`/fleet/captains/${row.original.captain?._id}`, {
                        replace: true,
                      });
                    }}
                  >
                    <IconButton type="button" icon={<CaptainIcon />} />
                    <span>{t("view-captain")}</span>
                  </Flex>
                )}
              </Popover.Content>
            </Popover>
          </Flex>
        );
      },
    },
  ];

  return (
    <Table
      data={vehicles?.hits || []}
      columns={columns}
      noDataComponent={<NoData title={t("no-data-title", { ns: "common" })} />}
      fullWidth={true}
      sameSizeCells={false}
      locale={t("table-locales", { ns: "common", returnObjects: true })}
      direction={lang.direction}
      enablePagination={true}
      isLoading={isLoading}
      getRowProps={getRowProps}
      stateReducer={tableStateReducer}
      paginationConfig={{
        onPageChange: (page) => {
          dispatchFilters({ type: "UPDATE", filters: { page: page + 1 } });

          GA.event({
            ...events.actionChangePage,
            label: JSON.stringify({ page_number: page + 1 }),
          });
        },
        onPageSizeChange: (limit) => {
          if (limit !== filters.limit) {
            dispatchFilters({ type: "UPDATE", filters: { limit, page: 1 } });

            GA.event({
              ...events.actionChangeRowsPerPage,
              label: JSON.stringify({ page_limit: limit }),
            });
          }
        },
        initialPageSize: filters.limit,
        initialPageIndex: filters.page - 1,
        manualPagination: true,
        totalCount: vehicles?.total || 0,
        pageSizeOptions,
      }}
    />
  );
};

export default VehicleListing;
